<template>
  <component :is="tag" :class="{ active }" class="accordion">
    <div class="accordion-title">
      <slot name="title"></slot>
      <span class="open-accordion" @click="toggleAccordion">
        <slot name="open"><i class="icon-carret"></i></slot>
      </span>
    </div>
    <div ref="content" class="accordion-content">
      <slot name="content"></slot>
    </div>
  </component>
</template>

<script setup>
import { defineProps, nextTick, ref, watch } from 'vue';

const props = defineProps({
  disabled: {
    type: Boolean,
    default: false
  },
  forceOpen: {
    type: Boolean,
    default: false
  },
  forceClose: {
    type: Boolean,
    default: false
  },
  tag: {
    type: String,
    default: 'div'
  }
});

const active = ref(false);
const content = ref(null);

const toggleAccordion = () => {
  if (!props.disabled) {
    if (active.value) {
      closeAccordion();
    } else {
      openAccordion();
    }
  }
};

const openAccordion = () => {
  active.value = true;
  if (content.value) {
    updateMaxHeight();
  }
};

const updateMaxHeight = () => {
  nextTick(() => {
    if (active.value === true) {
      content.value.style.maxHeight = content.value.scrollHeight + 'px';
    }
  });
};

defineExpose({
  updateMaxHeight,
  toggleAccordion
});

const closeAccordion = () => {
  active.value = false;
  if (content.value) {
    content.value.style.maxHeight = null;
  }
};

watch(
  () => props.disabled,
  (isDisabled) => {
    if (isDisabled) {
      closeAccordion();
    }
  }
);

watch(
  () => props.forceOpen,
  (isForceOpen) => {
    if (isForceOpen && !props.disabled) {
      openAccordion();
    }
  },
  {
    immediate: true
  }
);

watch(
  () => props.forceClose,
  (isForceClose) => {
    if (isForceClose) {
      closeAccordion();
    }
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_accordion.scss';

.open-accordion i.icon-carret:before {
  color: $blue-2;
}

.accordion-content {
  padding: 0;
}
</style>
