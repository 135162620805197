import L from "leaflet";

export const createLeafletBoundsFromBoundaries = (boundaries) => {
  const southWest = L.latLng(
    getBoundariesParam("miny", boundaries),
    getBoundariesParam("minx", boundaries)
  );
  const northEast = L.latLng(
    getBoundariesParam("maxy", boundaries),
    getBoundariesParam("maxx", boundaries)
  );
  return L.latLngBounds(southWest, northEast);
};

const getBoundariesParam = (param, boundaries) => {
  return boundaries.find((boundary) => boundary.param === param)?.values;
};
