<template>
  <div class="new-project-step5 grid grid-1of2">
    <div class="grey-block grid-cell">
      <div class="form-container-grey-bg">
        <label for="project-name">Nom du projet</label>
        <input
          id="project-name"
          placeholder="Nom"
          v-model="projectName"
          @input="saveProjectInformations('name')"
        />
        <i class="icon-agrandir" v-if="projectNameSaved"></i>
      </div>
      <div class="form-container-grey-bg">
        <label for="project-description">Description du projet (facultatif)</label>
        <textarea
          id="project-description"
          placeholder="Description"
          v-model="projectDescription"
          @input="saveProjectInformations('description')"
        >
        </textarea>
        <i class="icon-agrandir" v-if="projectDescriptionSaved"></i>
      </div>
      <div class="grid grid-line">
        <p class="label">Périmètres</p>
        <button class="btn green-2" @click="$emit('go-to-step', $event, 2)">Modifier</button>
      </div>
      <p v-if="projectData.zonesCustomFile !== null">
        Fichier personnalisé : {{ projectData.zonesCustomFileOriginalName }} (xx zones)
      </p>
      <ul v-else class="carret-list perimeter-list">
        <li v-for="(zone, index) in projectData.zones" :key="'zone_' + index">
          {{ getZoneTypeLabel(zone.type) }} - {{ zone.name }} ({{ zone.identifier }})
        </li>
      </ul>
      <div class="grid grid-line">
        <div v-if="projectData.siren?.customFile !== null">
          <p class="label">
            Fichier SIRENE personnalisé :
            <strong>Année {{ projectData.siren.year }}</strong>
          </p>
          <p>{{ projectData.siren?.customFileOriginalName }} (XXX lignes)</p>
        </div>
        <p v-else class="label">
          Fichier SIRENE : année <strong>{{ projectData.siren.year }}</strong>
        </p>
        <button class="btn green-2" @click="$emit('go-to-step', $event, 3)">Modifier</button>
      </div>
      <div class="grid grid-line">
        <p class="label">Adaptations au fichier SIRENE demandées</p>
        <button class="btn green-2" @click="$emit('go-to-step', $event, 4)">Modifier</button>
        <p class="label">Suppression d'établissements</p>
        <ul class="carret-list perimeter-list">
          <li v-for="(filter, index) in getSireneFilters('remove')" :key="'zone_' + index">
            {{ filter.name }}
          </li>
        </ul>
        <p class="label">Reclassement d'établissements</p>
        <ul class="carret-list perimeter-list">
          <li v-for="(filter, index) in getSireneFilters('reclassify')" :key="'zone_' + index">
            {{ filter.name }}
          </li>
        </ul>
      </div>
    </div>
    <div class="grid-cell">
      <project-map-block :project="projectData"></project-map-block>
    </div>
  </div>
  <create-project-actions
    @next="submit"
    @previous="previous"
    has-next
    has-previous
    :is-valid="isValid"
    :submitting="submitting"
    :error-message="errorMessage"
    next-button-text="Créer le projet"
  >
  </create-project-actions>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue';
import { errorMessage, submitting } from '@/composables/createProject';
import CreateProjectActions from './CreateProjectActions.vue';
import { getZoneTypeLabel } from '@/helper/zoneHelper';
import { createSimulation, putProject } from '@/api/project';
import { useRouter } from 'vue-router';
import { refreshNav } from '@/composables/navigation';
import ProjectMapBlock from '../ProjectMapBlock.vue';
import { debounce } from 'lodash';
import { mapMutations } from '@/store/mappers';

const router = useRouter();

const isValid = computed(() => {
  return true;
});

const emit = defineEmits(['next', 'previous', 'go-to-step', 'substep']);

const props = defineProps({
  projectData: {
    type: Object,
    default: null
  }
});

// Project information
// -----------------------------------------------------------------------------------------------------------------
const projectName = ref(null);
const projectNameSaved = ref(false);
const projectDescription = ref(null);
const projectDescriptionSaved = ref(false);
const { updateCurrentProject } = mapMutations('project');
const saveProjectInformations = debounce(function (fieldName) {
  putProject(props.projectData.id, {
    name: projectName.value,
    description: projectDescription.value
  }).then((response) => {
    updateCurrentProject(response.data);
    if (fieldName === 'name') {
      projectNameSaved.value = true;
    } else if (fieldName === 'description') {
      projectDescriptionSaved.value = true;
    }
    setTimeout(function () {
      if (fieldName === 'name') {
        projectNameSaved.value = false;
      } else if (fieldName === 'description') {
        projectDescriptionSaved.value = false;
      }
    }, 2000);
  });
}, 1000);
onMounted(() => {
  projectName.value = props.projectData.name;
  projectDescription.value = props.projectData.description;
});

// Siren Filters
// -----------------------------------------------------------------------------------------------------------------
const getSireneFilters = (step) => {
  if (props.projectData?.siren.filters) {
    return props.projectData?.siren.filters.filter((filter) => filter.step === step);
  }
  return [];
};

// Submission
// -----------------------------------------------------------------------------------------------------------------
const submit = () => {
  submitting.value = true;
  createSimulation(props.projectData.id).then((response) => {
    const newSimulation = response.data;
    refreshNav();
    router.push({
      name: 'simulation',
      params: {
        projectIdentifier: props.projectData.identifier,
        simulationIdentifier: newSimulation.identifier
      }
    });
    submitting.value = false;
  });
};
const previous = () => {
  emit('previous');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_grid.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.loader {
  margin: 1rem 0;
  width: 100%;
  height: 20px;
  border: 2px solid $blue-1;
  border-radius: 20px;
  position: relative;

  span {
    display: block;
    height: 16px;
    border: 2px solid $white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $blue-1;
    position: absolute;
  }
}

ul.carret-list {
  padding-left: 1.5rem;
  margin: 0 0 1rem;
  li {
    color: $blue-1-disabled;
    font-size: 14px;
    font-weight: 500;
    font-style: italic;
    padding: 0.35rem 0 0.35rem 1rem;
    &:before {
      width: 0.35rem;
      top: 0.5rem;
    }
  }
}

.grid-line {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  p {
    color: $blue-2;
    margin: 0;
  }
}
.meta {
  color: $blue-1;
}
</style>
