import each from 'lodash/each';
import simulationApi from '../api/simulation';
import { has } from 'lodash';

export const getResultsCountForSection = (section) => {
  let count = 0;
  each(section.categories, (category) => {
    count += Object.keys(category.criterias).length;
  });
  return count;
};

export const updateSimulation = (simulationIdentifier, changes, callback) => {
  simulationApi.putSimulation(simulationIdentifier, changes).then(callback);
};

export const downloadSiren = (simulation) => {
  simulationApi
    .downloadSiren(simulation)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = URL.createObjectURL(blob);
      link.download = 'sirene_' + simulation.slug + '_' + simulation.siren.year + '.csv'; //Gives name to file but also forces a download
      link.click();
      return true;
    })
    .catch((error) => {
      this.throwError(
        'Une erreur est survenue lors du téléchargement des données. Veuillez nous contacter si cela se reproduit.',
        'Erreur téléchargement',
        has(error, 'response.data.message') ? error.response.data.message : error.message,
        error
      );
    });
};

export const downloadFile = (simulation, filename) => {
  const execute = function (resolve, reject) {
    // const theNumber = parityInfo.theNumber;
    // const seuil_B = SEUIL_A - 1;
    // if(theNumber >= seuil_B) {
    //   reject(`Toujours trop grand : ${theNumber}`);
    // } else {
    //   parityInfo.wordEvenOdd = parityInfo.isOdd ? 'impair' : 'pair';
    //   resolve(parityInfo);
    // }
    // return;
    simulationApi
      .downloadOutputFile(simulation, filename)
      .then((response) => {
        const blob = new Blob([response.data], {
          type: response.headers['content-type']
        });
        const link = document.createElement('a');
        link.setAttribute('target', '_blank');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        link.click();
        resolve(filename);
      })
      .catch((error) => {
        this.throwError(
          'Une erreur est survenue lors du téléchargement des données. Veuillez nous contacter si cela se reproduit.',
          'Erreur téléchargement',
          has(error, 'response.data.message') ? error.response.data.message : error.message,
          error
        );
        reject();
      });
  };
  return new Promise(execute);
};

export const downloadZones = (simulation, format) => {
  simulationApi
    .downloadZones(simulation, format)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = URL.createObjectURL(blob);
      link.download = 'zones_' + simulation.slug + '_' + simulation.siren.year + '.' + format; //Gives name to file but also forces a download
      link.click();
    })
    .catch((error) => {
      this.throwError(
        'Une erreur est survenue lors du téléchargement des données. Veuillez nous contacter si cela se reproduit.',
        'Erreur téléchargement',
        has(error, 'response.data.message') ? error.response.data.message : error.message,
        error
      );
    });
};

export const getSimulationVersion = (project, simulation) => {
  return project.releaseId + '-' + project.cacheVersion + '-' + simulation.cacheVersion;
};
