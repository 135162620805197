<template>
  <div v-if="simulation?.status === 'complete'" class="simulation">
    <div class="block">
      <editable-title
        :title="simulation ? simulation.name : null"
        @save="saveName"
        edit-role="ROLE_USER"
      ></editable-title>
    </div>
    <simulation-recap />
    <!--    <div class="split-two-third" v-if="isGranted('ROLE_ADMIN')">-->
    <!--      <div class="block">-->
    <!--        <simulation-categories />-->
    <!--      </div>-->
    <!--      <div class="block">-->
    <!--        <comments />-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="btn-row">
      <button
        class="btn orange delete with-icon"
        v-if="isGranted('ROLE_REFERENT') && isGranted('ROLE_ADMIN')"
      >
        Supprimer cette simulation<i class="icon-delete"></i>
      </button>
    </div>
  </div>
  <div v-else-if="simulation !== null" class="simulation">
    <simulation-creation-progress
      :project-data="project"
      :simulation-data="simulation"
      :key="project?.identifier + '_' + simulation?.identifier"
    />
  </div>
</template>

<script setup>
import SimulationRecap from '../components/simulation/SimulationRecap';
import { mapActions, mapMutations, mapState } from '@/store/mappers';
import { onMounted, watch } from 'vue';
import SimulationCreationProgress from '../components/simulation/SimulationCreationProgress.vue';
import { isGranted } from '@/composables/security';
import EditableTitle from '@/components/commons/EditableTitle.vue';
import { updateSimulation } from '@/composables/simulation';

const { updateProjectSimulation } = mapMutations('project');
const { updateCurrentSimulation } = mapMutations('simulation');

const props = defineProps({
  projectIdentifier: String,
  simulationIdentifier: String
});

const { project } = mapState('project');
const { simulation } = mapState('simulation');
const { fetchProject } = mapActions('project');
const { fetchSimulation } = mapActions('simulation');
const { setCurrentSection } = mapMutations('simulation');

//Load project and simulation
const load = () => {
  fetchProject({ identifier: props.projectIdentifier });
  fetchSimulation({ identifier: props.simulationIdentifier });
  setCurrentSection(null);
};
onMounted(load);

watch(
  () => props.simulationIdentifier,
  () => {
    load();
  }
);
watch(
  () => props.projectIdentifier,
  () => {
    load();
  }
);

const saveName = (newName, callback) => {
  updateSimulation(
    simulation.value.identifier,
    {
      name: newName
    },
    (response) => {
      updateProjectSimulation({
        projectIdentifier: project.value.identifier,
        simulation: response.data
      }); //To update navigation
      updateCurrentSimulation(response.data);
      callback();
    }
  );
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';

.btn-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
</style>
