import { computed } from 'vue';
import { mapGetters, mapMutations } from '@/store/mappers';
import { useWindowSize } from 'vue-window-size';
import navigationApi from '../api/navigation';

const { width: windowWidth } = useWindowSize();
const { sidenavOpen } = mapGetters('navigation', 'function');
const { setSidenavOpen } = mapMutations('navigation');
const { setProjects } = mapMutations('project');
const { setUnreadNotificationsCount } = mapMutations('notifications');

export const sidenavActive = computed(() => {
  return sidenavOpen(windowWidth.value);
});

export const toggleSidenav = (open = null) => {
  setSidenavOpen({
    open,
    windowWidth: windowWidth.value
  });
};

export const refreshNav = () => {
  navigationApi.getProjects().then((response) => {
    setProjects(response.data);
  });
};

export const refreshNotificationsCount = () => {
  navigationApi.countUnreadNotifications().then((response) => {
    setUnreadNotificationsCount(response.data);
  });
};
