import { authenticatedApi as api, buildRoute } from './api';

export const getChart = (filters) => {
  let { url, params } = buildRoute('/charts/:simulation/:category/:criteria', filters);
  params.cache = true;
  return api().get(url, { params });
};

export const downloadChartData = (filters) => {
  let { url, params } = buildRoute('/charts/download/:simulation/:category/:criteria', filters);
  return api().get(url, { params });
};

export default {
  getChart,
  downloadChartData
};
