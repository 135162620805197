import { authenticatedApi as api } from './api';

export const getTranslations = (slug, locale) => {
  return api().get('/translations/' + slug, {
    params: {
      locale
    }
  });
};
export default {
  getTranslations
};
