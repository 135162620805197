import { authenticatedApi, unauthenticatedApi } from './api';

export const login = (credentials) => {
  return unauthenticatedApi().post('/authentication_token', credentials);
};

export const refreshLogin = (login, refreshToken) => {
  return authenticatedApi().post('/authentication_token/refresh', {
    refreshToken
  });
};

export default {
  login,
  refreshLogin
};
