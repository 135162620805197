<template>
  <div :class="{ active: sidenavActive }" class="side-navigation">
    <div>
      <div class="top">
        <router-link :to="{ name: 'dashboard' }"
          ><img height="65" src="@/assets/img/layout/silogues-beta-logo.svg" width="175"
        /></router-link>
        <button class="toggle-side-navigation hover" @click="toggleSidenav()">
          <svg
            fill="none"
            height="16"
            viewBox="0 0 16 16"
            width="16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              clip-rule="evenodd"
              d="M10.3154 4.63147L9.68396 4L5.68456 8.00059L9.68396 12L10.3154 11.3685L6.9558 7.98092L7.39423 7.55412L10.3154 4.63147Z"
              fill="white"
              fill-rule="evenodd"
            />
            <path
              clip-rule="evenodd"
              d="M7.31543 4.63147L6.68396 4L2.68456 8.00059L6.68396 12L7.31543 11.3685L3.9558 7.98092L4.39423 7.55412L7.31543 4.63147Z"
              fill="white"
              fill-rule="evenodd"
            />
          </svg>
        </button>
      </div>
      <div class="dashboard-menu">
        <router-link :to="{ name: 'dashboard' }">
          <i class="icon-dashboard rounded"></i>
          <span class="remove-on-collapse">Dashboard</span>
        </router-link>
        <notifications :user="user" v-if="isGranted('ROLE_ADMIN')" />
        <div class="search-form deactivated" v-if="isGranted('ROLE_ADMIN')">
          <input placeholder="Recherche..." type="text" />
          <button><i class="icon-search rounded"></i></button>
        </div>
        <router-link
          :to="{ name: 'newProject' }"
          class="btn primary add-project"
          v-if="isGranted('ROLE_REFERENT') && isGranted('ROLE_ADMIN')"
          >Nouveau projet
        </router-link>
      </div>
      <projects />
    </div>
    <account :user="user" />
  </div>
</template>

<script setup>
import Projects from './Projects';
import Account from './Account';
import Notifications from './Notifications';
import { sidenavActive, toggleSidenav } from '@/composables/navigation';
import { mapMutations, mapState } from '@/store/mappers';
import userApi from '@/api/user';
import { onMounted } from 'vue';
import { isGranted } from '@/composables/security';

const { user } = mapState('security');
const { setUser } = mapMutations('security');
const load = () => {
  userApi.getLoggedUser().then((response) => {
    setUser(response.data);
  });
};

onMounted(() => {
  load();
});

// watch(windowWidth, () => {
//   if (windowWidth.value < FORCE_CLOSE_BREAKPOINT && !sidenavForceClose.value) {
//     setSidenavForceClose(true);
//   } else if (windowWidth.value >= FORCE_CLOSE_BREAKPOINT && sidenavForceClose.value) {
//     setSidenavForceClose(false);
//   }
// }, {
//   immediate: true
// });
</script>

<style lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';

body {
  margin: 0;
}

.side-navigation {
  width: 3.125rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: $white;
  @include box-shadow(4px 0px 8px 3px rgba(0, 0, 0, 0.1));
  @include transition(width 0.6s ease-out);
  z-index: 1;
  position: fixed;
  height: 100vh;
  left: 0;
  overflow: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none;
  }
  .top {
    display: flex;
    margin-bottom: 4rem;
    justify-content: flex-end;

    a {
      display: none;
    }

    .toggle-side-navigation {
      height: 4.76rem;
      width: 3.125rem;
      min-width: 2.25rem;
      background-color: $blue-2;
      border: none;
      @include transition(all 0.2s ease-out);
      cursor: pointer;

      svg {
        @include rotate(180);
      }

      &:hover {
        background-color: $turquoise;
      }
    }
  }

  .dashboard-menu {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-bottom: 3rem;
    overflow: hidden;

    a,
    div {
      margin: 0.5rem 0;
    }

    a {
      color: $blue-1;
      line-height: 1.18rem;
      text-align: center;
      text-decoration: none;
    }

    .search-form {
      display: flex;

      input {
        color: $blue-1;
        font-size: 0.875rem;
        line-height: 1.2rem;
        width: 0;
        border: none;
        visibility: hidden;
        opacity: 0;
        @include transition(all 0.5s ease-out);
      }

      input::placeholder {
        color: $blue-1;
      }

      button {
        color: $blue-1;
        background-color: transparent;
        border: none;
        cursor: pointer;
      }
    }

    .add-project {
      display: none;
    }

    .remove-on-collapse {
      display: block;
      width: 0;
      height: 0;
      visibility: hidden;
      opacity: 0;
      transition-delay: 0s;
    }
  }

  &.active {
    width: 15rem;

    .top {
      a {
        display: block;
        margin-right: 10px;
      }

      .toggle-side-navigation {
        width: 2.25rem;

        svg {
          @include rotate(0);
        }
      }
    }

    .dashboard-menu {
      padding: 0 1rem;

      a {
        text-align: left;

        i {
          margin-right: 0.5rem;
        }
      }

      a .remove-on-collapse {
        display: inline-block;
        height: auto;
        width: auto;
        visibility: visible;
        opacity: 1;
        @include transition(all 0.2s ease-out);
        transition-delay: 0.5s;
      }
    }

    .search-form {
      input {
        width: 100%;
        padding: 0.5rem;
        visibility: visible;
        opacity: 1;
        @include transition(all 0.5s ease-out);
      }
    }

    .add-project {
      display: block;
    }

    button {
      width: auto;

      &:hover {
        cursor: pointer;
      }

      i {
        font-size: 0.8rem;
      }
    }
  }
}
</style>
