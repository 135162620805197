import { authenticatedApi as api } from './api';

export const getUsers = (id) => {
  return api().get(
    '/organizations/' + id + '/users?serialization_groups[]=get&serialization_groups[]=projects',
    {
      params: {
        serialization_groups: ['get', 'medias']
      }
    }
  );
};

export const getProjects = (id) => {
  return api().get(
    '/organizations/' + id + '/projects?serialization_groups[]=get&serialization_groups[]=projects',
    {
      params: {
        serialization_groups: ['get', 'medias']
      }
    }
  );
};

export const getTypes = (id) => {
  return api().get('/organization-types?serialization_groups[]=get', {
    params: {
      serialization_groups: ['get']
    }
  });
};

export const updateOrganization = (organizationId, data) => {
  return api().put('/organizations/' + organizationId, data);
};

export const uploadAvatar = (id, data) => {
  return api().post('/organizations/' + id + '/avatar', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const removeAvatar = (organizationId) => {
  return api().delete('/organizations/' + organizationId + '/avatar', {});
};

export const toggleOrganizationUserActivation = (organizationId, userId) => {
  return api().put('/organizations/' + organizationId + '/users/toggle/' + userId);
};

export const inviteOrganizationUser = (organizationId, userId) => {
  return api().put('/organizations/' + organizationId + '/users/invite/' + userId);
};

export const deleteOrganizationUser = (organizationId, userId) => {
  return api().delete('/organizations/' + organizationId + '/users/' + userId);
};

export const createOrganizationUser = (organizationId, data) => {
  return api().post('/organizations/' + organizationId + '/users', data);
};

export const updateOrganizationUser = (organizationId, userId, data) => {
  return api().put('/organizations/' + organizationId + '/users/' + userId, data);
};

export default {
  getUsers,
  getProjects,
  getTypes,
  updateOrganization,
  uploadAvatar,
  removeAvatar,
  toggleOrganizationUserActivation,
  inviteOrganizationUser,
  deleteOrganizationUser,
  createOrganizationUser,
  updateOrganizationUser
};
