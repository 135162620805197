<template>
  <input
    type="file"
    :name="'upload-file-' + uid"
    :id="'upload-file-' + uid"
    class="upload-file"
    @change="handleFileChange($event)"
  />
  <button
    v-if="status === 'idle'"
    class="btn action"
    @click="openFileUpload()"
    :disabled="disabled"
  >
    <i class="icon-edit-page"></i>
  </button>
  <div v-if="errors.length > 0 && displayError === true">
    <div class="file-upload__error" v-for="(error, index) in errors" :key="index">
      <span>{{ error }}</span>
    </div>
  </div>
  <div v-if="serverError !== null && displayError === true">
    <div class="file-upload__error">
      <span>{{ serverError }}</span>
    </div>
  </div>
</template>

<script setup>
import { computed, defineProps, getCurrentInstance, reactive, ref } from 'vue';

// GET UID for the component, to have unique "id" for the input
const instance = getCurrentInstance();
const uid = computed(() => {
  return instance.uid;
});

const emit = defineEmits([
  'upload-file-selected',
  'upload-file-error',
  'upload-file-submitted',
  'upload-file-complete',
  'remove-uploaded-file'
]);
const props = defineProps({
  label: {
    type: String,
    default: 'Importer mon fichier'
  },
  maxSize: {
    type: Number,
    default: 50
  },
  accept: {
    type: String,
    default: 'geojson,json'
  },
  status: {
    type: String,
    default: 'idle'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  displayError: {
    type: Boolean,
    default: true
  },
  serverError: {
    type: String,
    default: null
  }
});
const file = reactive([]);
const fileName = ref(null);
const fileSize = ref(null);

const formatFileSize = (size) => {
  return Math.round((size / 1024 / 1024) * 100) / 100;
};

const openFileUpload = () => {
  document.getElementById('upload-file-' + uid.value).click();
};
const removeUploadedFile = () => {
  emit('remove-uploaded-file');
};

const isFileSizeValid = computed(() => {
  return formatFileSize(file.value.size) <= props.maxSize;
});

const isFileTypeValid = computed(() => {
  return props.accept.split(',').includes(file.value.name.split('.').pop().toLowerCase());
});

const errors = computed(() => {
  let errorsArray = [];
  if (file.value !== undefined) {
    if (!isFileTypeValid.value) {
      errorsArray.push(
        `Le type de fichier n'est pas autorisé. Vous pouvez télécharger les fichiers suivants : ${props.accept}`
      );
    }
    if (!isFileSizeValid.value) {
      errorsArray.push(`La taille maximale autorisée pour un fichier est de ${props.maxSize} Mo`);
    }
  }
  return errorsArray;
});

const isFileValid = computed(() => {
  return isFileTypeValid.value && isFileSizeValid.value;
});

const handleFileChange = (e) => {
  errors.value = [];
  if (e.target.files && e.target.files[0]) {
    emit('upload-file-selected', e.target.files[0]);
    file.value = e.target.files[0];
    if (isFileValid.value) {
      file.value = e.target.files[0];
      fileSize.value = formatFileSize(file.value.size);
      fileName.value = file.value.name.split('.').shift() + '.' + file.value.name.split('.').pop();

      let formData = new FormData();
      formData.append('file', file.value);
      emit('upload-file-submitted', formData);
    } else {
      emit('upload-file-error', 'invalid');
      console.log('Invalid file');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
</style>
