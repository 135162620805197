<template>
  <accordion ref="filtersAccordion" class="filters-block">
    <template v-slot:title>
      <div @click="toggleFilters">
        <i></i>
        <p>
          <b>Filtrer l'affichage des résultats</b>
          <em>({{ filtersCount }} {{ filtersCount > 1 ? 'actifs' : 'actif' }})</em>
        </p>
      </div>
    </template>
    <template v-slot:open>
      <i class="icon-carret"></i>
    </template>
    <template v-if="multiple" v-slot:content>
      <results-tags :available="available" :labels="tagLabels" :representation="representation" />
    </template>
    <template v-else v-slot:content>
      <result-filters
        :available="available"
        :representation="representation"
        @updateAccordion="updateAccordion"
      />
    </template>
  </accordion>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref } from 'vue';
import Accordion from '../commons/Accordion';
import ResultFilters from './ResultFilters';
import ResultsTags from './ResultsTags';
import { getResultRepresentationHash } from '../../composables/result';
import { mapGetters } from '../../store/mappers';
import { getTags } from '../../api/configuration';

const { countFiltersForResult } = mapGetters('filters', 'function');
const { countTagsForSection } = mapGetters('tags', 'function');
const filtersAccordion = ref();
const tagLabels = ref(null);

onMounted(() => {
  if (props.multiple) {
    getTags()
      .then((response) => {
        tagLabels.value = response.data;
      })
      .catch((error) => {
        console.error('Error fetching tag labels', error);
      });
  }
});

const props = defineProps({
  multiple: {
    type: Boolean,
    default: false
  },
  available: {
    default: null
  },
  representation: {
    type: Object,
    default: null
  }
});

const representationHash = computed(() => {
  return getResultRepresentationHash(props.representation);
});

const filtersCount = computed(() => {
  return (
    (props.multiple
      ? countTagsForSection(representationHash.value)
      : countFiltersForResult(representationHash.value)) || 0
  );
});

const toggleFilters = () => {
  filtersAccordion.value.toggleAccordion();
};

//Handle auto-resizing of filters container (accordion)
const updateAccordion = () => {
  filtersAccordion.value.updateMaxHeight();
};
defineExpose({ updateAccordion });
onMounted(() => window.addEventListener('resize', updateAccordion));
onUnmounted(() => window.removeEventListener('resize', updateAccordion));
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.filters-block {
  background-color: $grey-3;
  padding: 0.5rem 1.5rem;
  margin-bottom: 2rem;

  .accordion-title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      display: flex;
      align-items: center;

      p {
        color: $blue-1;
        margin: 0;
        cursor: pointer;
      }

      i {
        display: inline-block;
        width: 1.5rem;
        height: 1.5rem;
        margin-right: 0.5rem;
        background-image: url('~@/assets/img/icons/filters.svg');
      }

      em {
        margin-left: 0.3rem;
      }
    }

    i.icon-carret {
      @include rotate(90);
    }
  }

  &.active .accordion-title {
    i.icon-carret {
      @include rotate(-90);
    }
  }

  ul {
    list-style-type: none;
    padding: 0;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    justify-content: flex-start;

    li.filter {
      div {
        display: flex;
        align-items: center;
        padding: 0.5rem 1rem;
        border: 1px solid $blue-1-disabled;
        border-radius: 1.5rem;
        margin: 0 0.5rem 0.5rem 0;
        cursor: pointer;
      }

      i {
        width: 1rem;
        height: 1rem;
        display: block;
        border: 1px solid $blue-1-disabled;
        border-radius: 0.313rem;
        margin-right: 0.5rem;
      }

      p {
        font-size: 0.875rem;
        font-weight: 700;
        margin: 0;
      }

      &:hover div {
        background-color: rgba($grey-3, 0.5);

        i {
          background-color: white;
        }
      }

      &.active {
        div {
          background-color: $blue-1-disabled;

          i {
            border-color: $white;
            position: relative;

            &:after {
              content: '';
              color: white;
              display: block;
              width: 10px;
              height: 4px;
              border-bottom: 2px solid $white;
              border-left: 2px solid $white;
              position: absolute;
              left: 19%;
              top: 11%;
              transform: rotate(-46deg);
            }
          }

          p {
            color: $white;
          }
        }

        &:hover div {
          i {
            background-color: transparent;
          }
        }
      }
    }
  }
}
</style>
