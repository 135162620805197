<template>
  <accordion
    :class="{ highlight: isCurrentProject }"
    :disabled="!sidenavActive || !project.enabled"
    :force-close="!isCurrentProject"
    :force-open="isCurrentProject"
    class="project"
    tag="li"
  >
    <template v-slot:title>
      <button
        type="button"
        v-if="project.enabled"
        class="nav-item"
        @click="openProjectLink(project)"
      >
        <i
          class="short-name-icon"
          :style="{
            'background-color': getColor(
              getProjectIndexByIdentifier(project.identifier),
              isCurrentProject ? 1 : 0.6
            )
          }"
          >{{ getShortName(project.name) }}</i
        >
        <span class="remove-on-collapse">{{ project.name }}</span>
      </button>
      <button type="button" v-else class="nav-item">
        <i
          class="short-name-icon"
          :style="{
            'background-color': getColor(index, isCurrentProject ? 1 : 0.6)
          }"
          >{{ getShortName(project.name) }}</i
        >
        <span class="remove-on-collapse">{{ project.name }}</span>
      </button>
    </template>
    <template v-slot:open>
      <i v-if="project.enabled" class="icon-carret remove-on-collapse"></i>
      <i v-else class="icon-edit-page remove-on-collapse"></i>
    </template>
    <template v-slot:content>
      <simulations v-if="project.enabled" :project="project" class="simulations" />
    </template>
  </accordion>
</template>

<script setup>
import Simulations from './Simulations';
import Accordion from '../commons/Accordion';
import { getColor } from '@/helper/colorHelper';
import { getShortName } from '@/composables/project';
import { mapGetters, mapState } from '@/store/mappers';
import { ref, watch } from 'vue';
import { sidenavActive, toggleSidenav } from '@/composables/navigation';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps({
  project: Object,
  index: Number
});

const { project: currentProject } = mapState('project');
const { getProjectIndexByIdentifier } = mapGetters('project');
const isCurrentProject = ref(false);

const openProjectLink = (project) => {
  if (!sidenavActive.value) {
    toggleSidenav();
  }
  if (project.enabled) {
    router.push({
      name: 'project',
      params: { projectIdentifier: project.identifier }
    });
  }

  router.push({
    name: 'newProject',
    params: { projectIdentifier: project.identifier }
  });
};

watch(
  currentProject,
  (currentProject) => {
    if (
      currentProject !== undefined &&
      currentProject !== null &&
      props.project !== undefined &&
      props.project !== null
    ) {
      isCurrentProject.value = props.project.identifier === currentProject.identifier;
    } else {
      return false;
    }
  },
  {
    immediate: true,
    deep: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_accordion.scss';

.project {
  padding: 0.75rem 0;

  .accordion-title {
    button {
      color: $blue-1;
      width: 100%;
      font-size: 1rem;
      text-decoration: none;
      padding: 0.5rem 0;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      border: 0;
      background-color: transparent;

      i.short-name-icon {
        color: $white;
        font-size: 1rem;
        line-height: 2rem;
        text-align: center;
        font-style: normal;
        width: 2rem;
        min-width: 2rem;
        height: 2rem;
        display: inline-block;
        border-radius: 50%;
        @include transition(all 0.2s ease-out);
        margin-left: 0.5rem;
      }
    }

    .simulations {
      margin-left: 1rem;
    }
  }

  &.active button i,
  &.highlight button i {
    @include box-shadow(4px 0px 8px 3px rgba(0, 0, 0, 0.1));
  }
}

.remove-on-collapse,
.side-navigation .accordion.active .accordion-content {
  display: block;
  width: 0;
  height: 0;
  visibility: hidden;
  opacity: 0;
  transition-delay: 0s;
}

.side-navigation.active .remove-on-collapse,
.side-navigation.active .accordion.active .accordion-content {
  display: inline-block;
  height: auto;
  width: auto;
  visibility: visible;
  opacity: 1;
  @include transition(all 0.2s ease-out);
  transition-delay: 0.5s;
}

.side-navigation.active {
  .project {
    border-bottom: 1px solid $grey-3;
    background-color: transparent !important;

    .accordion-title {
      button {
        justify-content: flex-start;

        i {
          margin: 0 0.625rem 0 0;
        }
      }
    }

    i.icon-edit {
      position: relative;
      left: 6px;
    }
  }
}
</style>
