<template>
  <title-with-button title="Mes coordonnées" @action="openUserInfosForm" />
  <div class="personal-infos-bloc">
    <user-avatar :initials="initials"></user-avatar>
    <div class="info-list">
      <ul class="chevron-list">
        <li class="info-item">
          <p>Poste</p>
          <div class="flex">
            <p>{{ user.personalInfos.job !== null ? user.personalInfos.job : 'N.C' }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Mail</p>
          <div class="flex">
            <p>{{ user.email }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Tel</p>
          <div class="flex">
            <p>{{ user.personalInfos.phone !== null ? user.personalInfos.phone : 'N.C' }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <ModalEditUserInfo :open="userInfoModalOpened" @close="userInfoModalOpened = false" />
</template>

<script setup>
import UserAvatar from '@/components/dashboard/user/UserAvatar.vue';
import ModalEditUserInfo from '@/components/dashboard/user/ModalEditUserInfo.vue';
import TitleWithButton from '@/components/commons/TitleWithButton.vue';
import { computed, ref } from 'vue';

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const initials = computed(() => {
  return props.user.personalInfos.firstName.substring(0, 1).toUpperCase();
});

const userInfoModalOpened = ref(false);
const openUserInfosForm = () => {
  userInfoModalOpened.value = true;
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
//@import "@/assets/scss/commons/_mediaqueries.scss";

.personal-infos-bloc {
  display: flex;
  gap: 3rem;
}

.info-user {
  display: flex;
  gap: 1rem;
  padding-bottom: 2rem;
  border-bottom: 1px solid $grey-3;

  > div {
    border-left: 1px solid $grey-3;
    padding-left: 1rem;
  }

  h3 {
    margin: 0;
    color: $blue-3;
  }

  p {
    margin: 0;
    font-size: 0.75rem;
  }
}

.info-item {
  display: flex;

  p {
    margin-bottom: 0;
  }

  > p {
    font-weight: bold;
    width: 100px;
  }
}
</style>
