<template>
  <Modal :open="open" @close="emit('close')">
    <template #content>
      <div class="center">
        <i class="icon-warning-inverted"></i>
        <h2>Êtes vous certain de vouloir supprimer votre compte ?</h2>
        <p>
          La suppression de votre compte est une action irréversible et définitive qui entrainera la
          suppression de toutes vos données personnelles.
        </p>
      </div>
    </template>
    <template #actions>
      <div class="btn-group">
        <button class="btn nav previous" @click="emit('close')">Annuler</button>
        <button class="btn primary" @click="confirm" v-if="submissionInProgress === false">
          Confirmer la suppression
        </button>
        <button class="btn primary" disabled v-else-if="submissionInProgress === true">
          Suppression en cours...
        </button>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/commons/Modal.vue';
import { deleteLoggedUser } from '@/composables/user';
import { ref } from 'vue';
import { mapActions } from '@/store/mappers';
import { useToast } from 'vue-toastification';
import { extractErrorMessage } from '@/helper/formHelper';

const submissionInProgress = ref(false);
const emit = defineEmits(['close']);
const { logout } = mapActions('security');
const toast = useToast();

const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  user: {
    type: Object,
    required: true
  }
});

const confirm = () => {
  console.log('confirmDelete');
  submissionInProgress.value = true;
  deleteLoggedUser(
    props.user.id,
    () => {
      logout();
      window.location.href = '/';
    },
    (error) => {
      toast.error(extractErrorMessage(error.response), {
        timeout: 5000,
        position: 'bottom-center'
      });
    }
  );
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';

.center {
  .icon-warning-inverted {
    font-size: 3rem;
    color: $text;
  }
  p {
    font-size: 0.875rem;
  }
}
.btn-group {
  display: flex;
  justify-content: space-between;
}
</style>
