import { forEach, forIn } from 'lodash';
import isObject from 'lodash/isObject';

export const submitForm = (errors, formErrors, callback) => {
  formErrors.value = checkFormErrors(errors);
  // console.log(formErrors);
  if ('' === formErrors.value) {
    callback();
  }
};

export const checkFormErrors = (errors) => {
  let formErrors = '';
  forIn(errors, function (value) {
    if (isObject(value)) {
      forIn(value, function (subValue) {
        if (subValue === 'required') {
          formErrors = 'Certains champs requis du formulaire ne sont pas remplis.';
        }
      });
    } else if (value === 'required') {
      formErrors = 'Certains champs requis du formulaire ne sont pas remplis.';
    }
  });
  return formErrors;
};

export const updateFormFieldValue = (form, property, value) => {
  let explodedProperty = property.split('.');
  if (explodedProperty.length === 1) {
    form[property] = value;
  } else if (explodedProperty.length === 2) {
    form[explodedProperty[0]][explodedProperty[1]] = value;
  }
};

export const updateFormFieldError = (errors, property, message) => {
  let explodedProperty = property.split('.');
  if (explodedProperty.length === 1) {
    errors[property] = message;
  } else if (explodedProperty.length === 2) {
    errors[explodedProperty[0]][explodedProperty[1]] = message;
  }
};

export const extractErrorMessage = (response) => {
  let formErrors = '';
  if (response.data.message !== undefined) {
    formErrors = response.data.message;
  } else if (response.data.error !== undefined) {
    formErrors = response.data.error;
  } else if (response.data.violations !== undefined) {
    forEach(response.data.violations, (message, field) => {
      formErrors += message + '\r\n';
    });
  } else {
    formErrors = 'Une erreur est survenue.';
  }
  return formErrors;
};
