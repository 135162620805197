import { authenticatedApi as api } from './api';

export const getProjects = () => {
  return api().get('/projects', {
    params: {
      serialization_groups: ['get', 'simulations'],
      'order[createdAt]': 'desc',
      enabled: 1,
      pagination: false
    }
  });
};

export const countUnreadNotifications = () => {
  return api().get('/notifications/unread/count', {});
};

export default {
  getProjects,
  countUnreadNotifications
};
