import axios from 'axios';
import { authenticatedApi as api } from './api';

export const downloadPdf = (filename, label) => {
  axios
    .get('/' + filename, { responseType: 'blob' })
    .then((response) => {
      const blob = new Blob([response.data], { type: 'application/pdf' });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(console.error);
};

export const downloadApiFile = (identifier, label, mimeType = 'application/pdf') => {
  return api()
    .get('/websites/media/' + identifier, {
      responseType: 'blob'
    })
    .then((response) => {
      const blob = new Blob([response.data], { type: mimeType });
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = label;
      link.click();
      URL.revokeObjectURL(link.href);
    })
    .catch(console.error);
  // axios
  //   .get("/" + filename, { responseType: "blob" })
  //   .then((response) => {
  //     const blob = new Blob([response.data], { type: "application/pdf" });
  //     const link = document.createElement("a");
  //     link.href = URL.createObjectURL(blob);
  //     link.download = label;
  //     link.click();
  //     URL.revokeObjectURL(link.href);
  //   })
  //   .catch(console.error);
};

export default {
  downloadPdf
};
