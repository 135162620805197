import { authenticatedApi as api, buildRoute, mercureURL } from './api';
import { useEventSource } from '@vueuse/core';
import { getVersionKey } from '@/composables/result';

export const getSimulation = (id) => {
  return api().get('/simulations/' + id, {
    params: {
      serialization_groups: [
        'get',
        'project',
        'author',
        'siren',
        'zone',
        'reference',
        'indicators',
        'boundaries'
      ]
    }
  });
};

export const putSimulation = (id, data) => {
  return api().put('/simulations/' + id, data);
};

export const getProgressES = (id) => {
  return useEventSource(mercureURL + '?topic=/simulation/' + id);
};

export const getProgress = (id) => {
  return api().get(`/simulations/${id}/progress`);
};

export const getZonesJson = (project, simulation, name = 'zones', format = 'json') => {
  return api().get(
    `/simulations/${simulation.identifier}/${name}.${format}?version=${getVersionKey(
      project,
      simulation
    )}`
  );
};


export const getCustomZonesJson = (project, simulation, filename) => {
  return api().get(
    `/simulations/${
      simulation.identifier
    }/${filename}?version=${getVersionKey(project, simulation)}`
  );
};


export const hasOutputFile = (simulation, filename) => {
  let { url, params } = buildRoute('/simulations/:identifier/has-output-file/:filename', {
    identifier: simulation.identifier,
    filename: filename
  });
  return api().get(url, { params });
};

export const downloadOutputFile = (simulation, filename) => {
  let { url, params } = buildRoute('/simulations/:identifier/download-output-file/:filename', {
    identifier: simulation.identifier,
    filename: filename
  });
  return api().get(url, { params, responseType: 'arraybuffer' });
};

export const downloadSiren = (project) => {
  let { url, params } = buildRoute('/simulations/:identifier/download-siren', {
    identifier: project.identifier
  });
  return api().get(url, { params });
};

export const downloadZones = (simulation, format) => {
  let { url, params } = buildRoute('/simulations/:identifier/download-zones/:format', {
    identifier: simulation.identifier,
    format: format
  });
  return api().get(url, { params });
};

export default {
  getSimulation,
  putSimulation,
  getProgressES,
  getProgress,
  downloadSiren,
  downloadZones,
  getZonesJson,
  getCustomZonesJson,
  hasOutputFile,
  downloadOutputFile
};
