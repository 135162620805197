<template>
  <button :class="{ save: editing }" class="btn action edit with-icon" @click="editAndSave">
    <span>Enregistrer</span> <i class="icon-edit-page"></i>
  </button>
</template>

<script setup>
import { defineExpose, ref } from 'vue';

const emit = defineEmits(['edit', 'save']);

const editing = ref(false);
const editAndSave = () => {
  if (editing.value) {
    editing.value = false;
    emit('save');
  } else {
    editing.value = true;
    emit('edit');
  }
};
defineExpose({
  editAndSave
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_layout.scss';
@import '@/assets/scss/commons/_buttons.scss';

button i {
  margin: 0.3rem;
}
</style>
