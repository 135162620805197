<template>
  <div v-if="section">
    <filters
      ref="resultsFilters"
      :available="availableTags"
      :representation="sectionRepresentation"
      multiple
    />
    <div v-for="(category, categoryId) in section.categories" :key="categoryId">
      <div v-if="!isFilteredOut(categoryId)" class="line-title">
        <p class="title">
          <span>{{ category.label }}</span>
        </p>
        <p>
          {{ category.description }}
        </p>
      </div>
      <div class="split">
        <div
          v-for="(criteria, criteriaId, criteriaIndex) in category.criterias"
          v-show="!isFilteredOut(categoryId, criteriaId)"
          :key="category.id + '_' + criteria.id"
          class="block result-block graph"
        >
          <div class="result-block-title">
            <p class="block-title">{{ criteria.label }}</p>
            <div>
              <div
                :class="{
                  active: isActiveCriteriaButtons(category.id + '_' + criteria.id)
                }"
                class="show-btns"
              >
                <button
                  class="toggle-btn"
                  @click="toggleCriteriaButtons(category.id + '_' + criteria.id)"
                >
                  <i class="icon-carret"></i>
                </button>
                <div class="show-btns-content">
                  <tooltip
                    @click="
                      exportChartData({
                        simulation: simulationIdentifier,
                        category: category.id,
                        criteria: criteria.id
                      })
                    "
                  >
                    <i class="icon-export"></i>
                    <template #tooltip>
                      <p class="title">Exporter les données</p>
                      <p>Exporte les données du graphique au format csv.</p>
                    </template>
                  </tooltip>
                  <!--                  <tooltip-->
                  <!--                    icon="icon-percentage"-->
                  <!--                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sapien at netus urna. Lectus nunc a amet, tincidunt eget. Varius et venenatis augue orci convallis sed sem eu suspendisse. Tortor consequat, convallis enim, id. Euismod arcu, diam cursus enim, quis magna."-->
                  <!--                    title="Lorem ipsum"-->
                  <!--                  />-->
                  <!--                  <tooltip-->
                  <!--                    icon="icon-binoculars"-->
                  <!--                    text="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla sapien at netus urna. Lectus nunc a amet, tincidunt eget. Varius et venenatis augue orci convallis sed sem eu suspendisse. Tortor consequat, convallis enim, id. Euismod arcu, diam cursus enim, quis magna."-->
                  <!--                    title="Lorem ipsum"-->
                  <!--                  />-->
                </div>
              </div>
              <router-link
                :to="{
                  name: 'result',
                  params: {
                    projectIdentifier,
                    simulationIdentifier,
                    sectionId: section.id,
                    categoryId: category.id,
                    criteriaId: criteria.id
                  }
                }"
                class="btn green"
              >
                Détails
              </router-link>
            </div>
          </div>
          <div class="graph">
            <result-loader
              :category="category"
              :criteria="criteria"
              :project="project"
              :result-index="criteriaIndex"
              :section="section"
              :simulation="simulation"
              :zones="zones"
              multiple
              @loaded="onLoaded"
            ></result-loader>
          </div>
          <!--          <div class="comments">-->
          <!--            <p>2 commentaires</p>-->
          <!--            <comment/>-->
          <!--            <comment/>-->
          <!--            <div class="add-comment">-->
          <!--              <input placeholder="Ajouter un commentaire" type="text"/>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
      </div>
    </div>
    <!--    <div class="line-title">-->
    <!--      <p class="title"><span>Activité</span></p>-->
    <!--    </div>-->
    <!--    <div cass="split">-->
    <!--      <div class="block graph"></div>-->
    <!--    </div>-->
  </div>
</template>

<script setup>
import Tooltip from '../components/commons/Tooltip';
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from '@/store/mappers';
import find from 'lodash/find';
import { transformObjectWithKeyAsProperty } from '../helper/objectHelper';
import ResultLoader from '../components/result/ResultLoader';
import Filters from '../components/filters/Filters';
import { exportData, getResultRepresentationHash, getVersionKey } from '../composables/result';
import { clone, forEach, some } from 'lodash';

const props = defineProps({
  projectIdentifier: String,
  simulationIdentifier: String,
  sectionId: String
});

const sectionRepresentation = computed(() => {
  return {
    project: props.projectIdentifier,
    simulation: props.simulationIdentifier,
    section: props.sectionId
  };
});

const representationHash = computed(() => {
  return getResultRepresentationHash(sectionRepresentation.value);
});

const exportChartData = (filters) => {
  filters.version = getVersionKey(project.value, simulation.value);
  exportData(filters);
};

const { fetchProject } = mapActions('project');
const { fetchSimulation } = mapActions('simulation');
const { setCurrentSection } = mapMutations('simulation');
const { project } = mapState('project');
const { sections } = mapState('navigation');
const { simulation, section } = mapState('simulation');
const { getTagsForSection } = mapGetters('tags', 'function');
const { getZonesBySimulation } = mapGetters('simulation', 'function');
const { fetchZonesBySimulation } = mapActions('simulation');

const availableTags = ref([]);
const resultTags = ref({});
const resultsFilters = ref(); //DOM
const zones = ref(null);

const activeTags = computed(() => {
  return getTagsForSection(representationHash.value);
});
const projectAndSimulationLoaded = computed(() => {
  return (
    simulation.value !== undefined &&
    simulation.value !== null &&
    project.value !== undefined &&
    project.value !== null
  );
});

const isFilteredOut = (categoryId, criteriaId = null) => {
  if (activeTags.value.length === 0) {
    return false;
  }

  let resultRepresentation = clone(sectionRepresentation.value);
  resultRepresentation.category = categoryId;

  if (criteriaId) {
    resultRepresentation.criteria = criteriaId;
    const resultRepresentationHash = getResultRepresentationHash(resultRepresentation);
    const tags = resultTags.value[resultRepresentationHash];

    return !some(tags, (tag) => activeTags.value.includes(tag));
  } else {
    //for all criterias in category
    let found = false;
    let criterias = section.value?.categories[categoryId]?.criterias;

    if (criterias) {
      forEach(criterias, (criteria, criteriaId) => {
        resultRepresentation.criteria = criteriaId;
        const resultRepresentationHash = getResultRepresentationHash(resultRepresentation);
        const tags = resultTags.value[resultRepresentationHash];

        if (some(tags, (tag) => activeTags.value.includes(tag))) {
          found = true;
        }
      });
      return !found;
    } else {
      return false;
    }
  }
};

onMounted(() => {
  load();
});


//General load (project, simulation, section)
const load = () => {
  resetTags();
  fetchProject({ identifier: props.projectIdentifier });
  fetchSimulation({ identifier: props.simulationIdentifier });

  if (props.sectionId) {
    let currentSection = find(sections.value, (section, sectionId) => {
      return sectionId === props.sectionId;
    });
    if (currentSection) {
      setCurrentSection(
        transformObjectWithKeyAsProperty(currentSection, 'id', props.sectionId, true)
      );
    }
  }
};

//Load zones only once for a given simulation
const loadZones = () => {
  const zoneDefinition = {
    project: project.value,
    simulation: simulation.value
  };
  const existingZones = getZonesBySimulation(zoneDefinition);
  if (null === existingZones) {
    fetchZonesBySimulation({
      project: project.value,
      simulation: simulation.value
    }).then(() => {
      zones.value = getZonesBySimulation(zoneDefinition).data;
      
    });
  } else {
    zones.value = existingZones.data;
  }

};

let activeCriteriaButtons = ref([]);

const toggleCriteriaButtons = (criteriaId) => {
  if (isActiveCriteriaButtons(criteriaId)) {
    activeCriteriaButtons.value = activeCriteriaButtons.value.filter(
      (value) => value !== criteriaId
    );
  } else {
    activeCriteriaButtons.value.push(criteriaId);
  }
};

const isActiveCriteriaButtons = (criteriaId) => activeCriteriaButtons.value.includes(criteriaId);

const onLoaded = ({ representation, data }) => {
  const representationHash = getResultRepresentationHash(representation.value);
  if (resultTags.value[representationHash] === undefined) {
    resultTags.value[representationHash] = [];
  }
  data.tags.forEach((resultTag) => {
    if (!availableTags.value.includes(resultTag)) {
      availableTags.value.push(resultTag);
      resultsFilters.value.updateAccordion();
    }
    resultTags.value[representationHash].push(resultTag);
  });
};

const resetTags = () => {
  resultTags.value = {};
  availableTags.value = [];
};

watch(
  () => props.simulationIdentifier,
  () => {
    load();
  }
);
watch(
  () => props.projectIdentifier,
  () => {
    load();
  }
);
watch(
  () => props.sectionId,
  () => {
    load();
  }
);
watch(sections, load);

//Load zones as soon as project and simulation are both loaded
watch(
  projectAndSimulationLoaded,
  (isLoaded) => {
    if (isLoaded) {
      loadZones();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';
@import '@/assets/scss/commons/_result.scss';

.display {
  p {
    span {
      color: $blue-1;
      font-weight: 700;
    }
  }

  .btn {
    margin-right: 0.5rem;
  }
}

.result-block {
  .result-block-title {
    display: flex;
    //flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
    }

    .show-btns {
      display: flex;
      background-color: $grey-3;
      border-radius: 0.25rem;
      border: 0;
      padding: 0.4rem;
      margin-right: 0.5rem;
      height: 2.188rem;
      width: 2.063rem;
      @include transition(all 0.2s ease-out);

      .toggle-btn {
        border: 0;
        background-color: transparent;

        i {
          display: block;
          @include rotate(180);
        }

        &:hover {
          cursor: pointer;
        }
      }

      .show-btns-content {
        display: none;

        ::v-deep .tooltip i {
          display: inline-block;
          //padding: 0.15rem 0.5rem;
          position: relative;
          z-index: 2;

          &:hover {
            border-radius: 0.25rem;
            cursor: pointer;
          }
        }
      }

      &.active {
        //width: 8.875rem;
        width: 3.875rem;

        .toggle-btn i {
          @include rotate(0);
        }

        .show-btns-content {
          display: flex;
        }
      }
    }
  }

  .add-comment {
    input {
      width: 100%;
    }
  }
}
</style>
