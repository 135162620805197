<template>
  <Teleport to="body">
    <Transition name="modal-fade">
      <div :class="[open && 'is-active', large && 'is-large', medium && 'is-medium']" class="modal">
        <div
          class="modal-overlay"
          tabindex="0"
          @keydown.space.prevent="emit('close')"
          @click="emit('close')"
        ></div>
        <div class="modal-content">
          <div class="modal-inner">
            <div class="modal-card-head">
              <h2><slot name="title"></slot></h2>
              <button
                class="v-modal-close"
                aria-label="close"
                tabindex="0"
                @keydown.space.prevent="emit('close')"
                @click="emit('close')"
              >
                <i aria-hidden="true" class="icon-close"></i>
              </button>
            </div>
            <div class="modal-card-body">
              <slot name="content"></slot>
            </div>
            <div class="modal-card-footer">
              <slot name="actions" :close="() => emit('close')"></slot>
            </div>
          </div>
        </div>
      </div>
    </Transition>
  </Teleport>
</template>

<script setup>
import { watch } from 'vue';

const emit = defineEmits(['close']);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  large: {
    type: Boolean,
    default: false
  },
  medium: {
    type: Boolean,
    default: false
  }
});

watch(
  () => props.open,
  (isOpen) => {
    if (isOpen) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';

.modal-fade-enter-from,
.modal-fade-leave-to {
  opacity: 0;
}

.modal-fade-enter-active,
.modal-fade-leave-active {
  opacity: 1;
  transition: 0.25s ease all;
}

.modal {
  display: none;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  overflow-y: auto;

  .v-modal-close {
    cursor: pointer;
    background: none;
    border-color: transparent;
    padding: 0;
    i {
      font-size: 1.5rem;
      &::before {
        color: $text;
      }
    }
  }

  &.is-active {
    display: block;
  }

  &.is-medium {
    .modal-content {
      max-width: 37.5rem;
    }
  }

  &.is-large {
    .modal-content {
      max-width: 60rem;
    }
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(27, 70, 94, 0.8);
  }

  .modal-content {
    width: 100%;
    max-width: 31rem;
    min-height: 0;
    z-index: 1006;
    padding: 2rem;
    position: relative;
    top: 50px;
    margin-right: auto;
    margin-left: auto;
    overflow-y: auto;

    .modal-inner {
      background-color: white;
      padding: 2rem;
      border-radius: 8px;
    }

    .modal-card-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 2rem;
      background: white;
      h2 {
        margin: 0;
      }
    }

    .modal-card-body {
      //padding: 20px 0;
      //text-align: center;
    }

    .modal-card-footer {
      background-color: white;
      padding: 15px 0 0;
      border-top: 1px solid var(--dark);
    }
  }
}
</style>
