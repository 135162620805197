<template>
  <div class="new-project">
    <span class="step-progress">{{ step }}/{{ steps.length }}</span>
    <span class="step-name">{{ stepName }}</span>
    <span class="substep-name" v-if="substep && substep.step === step">{{ substep.label }}</span>
    <component
      :is="stepComponent"
      @next="handleNext"
      @previous="handlePrevious"
      @substep="setSubstep"
      @go-to-step="goToStep"
      :project-data="projectData"
    ></component>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import CreateProjectStep1 from '../components/project/create/CreateProjectStep1';
import CreateProjectStep2 from '../components/project/create/CreateProjectStep2.vue';
import CreateProjectStep3 from '../components/project/create/CreateProjectStep3.vue';
import CreateProjectStep4 from '../components/project/create/CreateProjectStep4.vue';
import CreateProjectStep5 from '../components/project/create/CreateProjectStep5.vue';
import projectApi from '../api/project';
import { mapMutations } from '@/store/mappers';
import { useRouter } from 'vue-router';

//Base data
const router = useRouter();
const props = defineProps({
  projectIdentifier: {
    type: String,
    default: null
  }
});
const projectData = ref(null);
const step = ref(1);
const substep = ref(null);

//Continue creating project after initial creation
onMounted(() => {
  load();
});
const load = () => {
  if (props.projectIdentifier) {
    projectApi.getProject(props.projectIdentifier).then((response) => {
      projectData.value = response.data;
      setCurrentProject(response.data);
      updateStepFromData();
    });
  } else {
    step.value = 1;
    projectData.value = null;
    setCurrentProject(null);
  }
};
const { setCurrentProject } = mapMutations('project');
watch(
  () => props.projectIdentifier,
  () => {
    load();
  },
  {
    immediate: true
  }
);

//Handle create project steps
const steps = [
  {
    name: 'Nommer votre projet',
    component: CreateProjectStep1
  },
  {
    name: 'Choisir votre périmètre de travail',
    component: CreateProjectStep2
  },
  {
    name: 'Sélection du fichier SIRENE',
    component: CreateProjectStep3
  },
  {
    name: 'Nettoyage du fichier SIRENE',
    component: CreateProjectStep4
  },
  {
    name: 'Récapitulatif',
    component: CreateProjectStep5
  }
];
const currentStep = computed(() => {
  return steps[step.value - 1];
});
const stepName = computed(() => {
  return currentStep.value.name;
});
const stepComponent = computed(() => {
  return currentStep.value.component;
});
const updateStepFromData = () => {
  if (projectData.value === null) {
    step.value = 1;
  } else {
    let data = projectData.value;
    if (data.enabled) {
      router.push({
        name: 'project',
        params: {
          projectIdentifier: data.id
        }
      });
    } else if (
      (data.zones === undefined || data.zones === null || data.zones.length === 0) &&
      (data.zonesCustomFile === undefined ||
        data.zonesCustomFile === null ||
        data.zonesCustomFile === false)
    ) {
      step.value = 2;
      // } else if (
      //   data.siren === null ||
      //   (data.siren.customFile === null && data.siren.year === null)
      // ) {
      //   step.value = 3;
      // } else if (data.siren.filters === null || !hasSirenFilters()) {
      //   step.value = 4;
    } else {
      step.value = 3;
      //step.value = 5;
    }
  }
};

const hasSirenFilters = () => {
  let removeFilters = projectData.value?.siren?.filters.filter((filter) => {
    return filter.step === 'remove';
  });
  let reclassifyFilters = projectData.value?.siren?.filters.filter((filter) => {
    return filter.step === 'reclassify';
  });
  return removeFilters.length > 0 && reclassifyFilters.length > 0;
};

//Handle substep (case step 4)
const setSubstep = (substepInfo) => {
  substep.value = substepInfo;
};

//Handle next/previous
const handleNext = (data) => {
  projectData.value = data;
  step.value++;
  // updateStepFromData();
};
const handlePrevious = () => {
  step.value--;
};

//Handle goto step number
const goToStep = (event, stepNumber) => {
  step.value = stepNumber;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';

.new-project {
  background-color: $white;
  border-radius: 10px;
  padding: 30px;
  box-shadow: 4px 0 8px 3px rgb(0 0 0 / 10%);

  .step-progress {
    color: $blue-2;
  }

  .step-name {
    margin-left: 1rem;
    font-weight: bold;
    color: $blue-2;
  }

  .substep-name {
    margin-left: 1rem;
    font-size: 15px;
  }
}
</style>
