import merge from 'lodash/merge';
import { getObjectIndexByObjectProperty } from '@/helper/objectHelper';

const state = {
  notifications: [],
  unreadNotificationsCount: null
};

const getters = {
  unreadNotificationsCount: (state) => {
    return state.unreadNotificationsCount;
  },
  hasUnreadNotifications: (state) => {
    return state.unreadNotificationsCount !== null && state.unreadNotificationsCount > 0;
  }
};

const mutations = {
  setUnreadNotificationsCount(state, payload) {
    state.unreadNotificationsCount = payload;
  },
  setNotifications(state, payload) {
    state.notifications = payload;
  },
  updateNotification(state, payload) {
    let notificationIndex = getObjectIndexByObjectProperty(state.notifications, payload.id, 'id');
    let notification = state.notifications[notificationIndex];
    state.notifications[notificationIndex] = merge(notification, payload);
  },
  setNotificationRead(state, payload) {
    let notificationIndex = getObjectIndexByObjectProperty(state.notifications, payload.id, 'id');
    let notification = state.notifications[notificationIndex];
    state.notifications[notificationIndex] = merge(notification, payload);
    state.unreadNotificationsCount--;
  },
  addNotifications(state, payload) {
    state.notifications.push(payload);
    state.unreadNotificationsCount++;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
