<template>
  <!-- @remi, could you add .active to .navigation-item when active and display childrens in div-->
  <li :class="{ active: section === null }" class="navigation-item">
    <router-link
      :to="{
        name: 'simulation',
        params: {
          simulationIdentifier: simulation.identifier,
          projectIdentifier: simulation.project.identifier
        }
      }"
    >
      Synthèse
    </router-link>
  </li>
  <li
    v-for="(section, sectionId) in sections"
    :key="sectionId"
    :class="{ active: sectionId === activeSection }"
    class="navigation-item accordion"
  >
    <div class="accordion-title">
      <!--      <span class="open-accordion">-->
      <router-link
        :to="{
          name: 'results',
          params: {
            projectIdentifier: simulation.project.identifier,
            simulationIdentifier: simulation.identifier,
            sectionId
          }
        }"
      >
        <b>{{ section.label }}</b>
      </router-link>
      <!--        <i class="icon-carret"></i>-->
      <!--      </span>-->
    </div>
    <!--    <navigation-sublevel-->
    <!--      :active="sectionId === activeSection"-->
    <!--      :categories="section.categories"-->
    <!--    />-->
  </li>
  <!--  <accordion-->
  <!--    v-for="(section, sectionId) in sections"-->
  <!--    :key="sectionId"-->
  <!--    :class="{active: sectionId === activeSection}"-->
  <!--    class="navigation-item"-->
  <!--    tag="li"-->
  <!--  >-->
  <!--    <template v-slot:title>-->
  <!--      <router-link-->
  <!--        :to="{-->
  <!--          name: 'results',-->
  <!--          params: {-->
  <!--            projectIdentifier: simulation.project.identifier,-->
  <!--            simulationIdentifier: simulation.identifier,-->
  <!--            section: sectionId,-->
  <!--          },-->
  <!--        }"-->
  <!--        class="nav-item"-->
  <!--      >-->
  <!--        <b>{{ section.label }}</b>-->
  <!--      </router-link>-->
  <!--    </template>-->
  <!--    <template v-slot:content>-->
  <!--      <navigation-item-children :categories="section.categories"/>-->
  <!--    </template>-->
  <!--  </accordion>-->
</template>

<script setup>
import { mapMutations, mapState } from '@/store/mappers';
import configurationApi from '../../api/configuration';
import { onMounted, ref, watch } from 'vue';

const { simulation, section } = mapState('simulation');
const { sections } = mapState('navigation');
const { setSections } = mapMutations('navigation');

const activeSection = ref(null);

onMounted(() => {
  configurationApi.getSections().then((response) => {
    setSections(response.data);
  });
});

watch(section, (newSection) => {
  activeSection.value = newSection ? newSection.id : null;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_accordion.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';

@include device(small) {
  .accordion {
    display: flex;
  }
  .top-nav {
    .navigation-item .accordion-title span,
    .navigation-item a {
      display: flex;
      font-size: 0.875rem;
      padding: 0.5rem 0.8rem;

      i:before {
        display: none;
      }
    }
  }
}

@include device(large) {
  .top-nav {
    .navigation-item .accordion-title span,
    .navigation-item a {
      font-size: 1rem;
      padding: 1rem 1.785rem;

      i:before {
        display: block;
      }
    }
  }
}

.top-nav {
  .navigation-item {
    &:hover,
    &.active {
      background-color: $blue-2;
      transition: all 0.2s ease-out;
    }

    a,
    span {
      display: block;
      color: $white;
      font-weight: 700;
      line-height: 1.375rem;
      text-decoration: none;
      padding: 1rem 1.785rem;

      b {
        font-weight: 700;
        margin-right: 0.5rem;
      }
    }

    .accordion-title {
      display: flex;

      .open-accordion i {
        &:before {
          color: $white;
        }
      }
    }
  }
}
</style>
