<template>
  <div>
    <div v-if="uploadError !== null" class="error callout">
      <p>{{ uploadError }}</p>
    </div>
    <avatar
      :initials="initials"
      :avatar="avatar"
      :upload-status="uploadStatus"
      @upload-avatar="upload"
      @remove-avatar="removeUserAvatar"
      @upload-error="onUploadError"
    ></avatar>
  </div>
</template>
<script setup>
import { computed, ref } from 'vue';
import { mapMutations, mapState } from '@/store/mappers';
import Avatar from '@/components/commons/Avatar.vue';
import { removeAvatar, uploadAvatar } from '@/api/user';
import { findMediaByIdentifier } from '@/helper/mediaHelper';

const { setUser } = mapMutations('security');
const { user } = mapState('security');

const props = defineProps({
  initials: {
    type: String,
    default: null
  }
});
const uploadStatus = ref('idle');
const uploadError = ref(null);

const avatar = computed(() => {
  if (null === user || undefined === user.value) {
    return null;
  }
  return findMediaByIdentifier(user.value.medias, 'avatar');
});

const upload = (formData) => {
  uploadStatus.value = 'progress';
  uploadError.value = null;
  uploadAvatar(formData)
    .then((response) => {
      setUser(response.data);
      uploadStatus.value = 'idle';
    })
    .catch((error) => {
      if (error.response.data.message !== undefined) {
        uploadError.value = error.response.data.message;
      } else {
        uploadError.value = 'Une erreur est survenue durant le téléchargement.';
      }
      uploadStatus.value = 'idle';
    });
};

const onUploadError = (message) => {
  uploadStatus.value = 'idle';
  uploadError.value = message;
};

const removeUserAvatar = () => {
  uploadStatus.value = 'progress';
  removeAvatar({})
    .then((response) => {
      setUser(response.data);
      uploadStatus.value = 'idle';
    })
    .catch((error) => {
      if (error !== undefined) {
        uploadError.value = error.response.data.message;
      } else {
        uploadError.value = 'Une erreur est survenue durant le téléchargement.';
      }
      uploadStatus.value = 'idle';
    });
};
</script>
