const state = {
  tags: {}
};

const getters = {
  getTagsForSection: (state) => (representation) => {
    return state.tags[representation] || [];
  },
  countTagsForSection: (state, getters) => (representation) => {
    return getters.getTagsForSection(representation).length;
  }
};

const mutations = {
  toggleTag: (state, { representation, value }) => {
    if (state.tags[representation] === undefined) {
      state.tags[representation] = [];
    }
    if (state.tags[representation].includes(value)) {
      state.tags[representation].splice(state.tags[representation].indexOf(value), 1);
    } else {
      state.tags[representation].push(value);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
