<template>
  <div class="activity deactivated">
    <h2 class="block-title">Les dernières activités</h2>
    <ul>
      <li>
        <img src="https://via.placeholder.com/32x32.png?" class="rounded" />
        <div>
          <p><b>Célia Elidrissi Von Kertrauser a édité</b> le projet Scénario de référence 2022</p>
          <span class="date">Le 01/01/2022</span>
        </div>
      </li>
      <li>
        <img src="https://via.placeholder.com/32x32.png?" class="rounded" />
        <div>
          <p><b>Célia Elidrissi Von Kertrauser a édité</b> le projet Scénario de référence 2022</p>
          <span class="date">Le 01/01/2022</span>
        </div>
      </li>
      <li>
        <img src="https://via.placeholder.com/32x32.png?" class="rounded" />
        <div>
          <p><b>Célia Elidrissi Von Kertrauser a édité</b> le projet Scénario de référence 2022</p>
          <span class="date">Le 01/01/2022</span>
        </div>
      </li>
      <li>
        <img src="https://via.placeholder.com/32x32.png?" class="rounded" />
        <div>
          <p><b>Célia Elidrissi Von Kertrauser a édité</b> le projet Scénario de référence 2022</p>
          <span class="date">Le 01/01/2022</span>
        </div>
      </li>
      <li>
        <img src="https://via.placeholder.com/32x32.png?" class="rounded" />
        <div>
          <p><b>Célia Elidrissi Von Kertrauser a édité</b> le projet Scénario de référence 2022</p>
          <span class="date">Le 01/01/2022</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script></script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_layout.scss';
.activity {
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: flex-start;
      padding: 1rem 0;
      border-bottom: 1px solid $grey-3;
      img {
        margin-right: 1rem;
      }
      p {
        color: $blue-1;
        font-size: 1rem;
        margin: 0;
      }
      &:last-child {
        border-bottom: 0;
      }
    }
  }
}
</style>
