import store from '../store/index';
import { ref } from 'vue';
import { isGranted } from '@/composables/security';

//Called in router to handle authentication for routes that require it
export const handleAuthentication = (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters['security/isAuthenticated'] === false) {
      next({
        name: 'login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
};

export const handleRoles = (to, from, next) => {
  if (!to.matched.some((record) => record.meta.requiresAuth) || isGranted(to.meta.role)) {
    next();
  } else {
    next({
      name: 'error',
      params: { code: '403' }
    });
  }
};

//Login using the api
export const apiLogin = async (credentials) => {
  return store.dispatch('security/login', credentials);
};

//Handle redirects after login based on a potential GET parameter sent to login
export const redirectAfterLogin = (route) => {
  return route.query.redirect ?? '/';
};

export const handleLoginSuccess = (response, router) => {
  let jwtToken = response.data.token;
  let refreshToken = response.data.refreshToken;
  store.dispatch('security/setJwtToken', jwtToken);
  store.dispatch('security/setRefreshToken', refreshToken);

  let redirect = redirectAfterLogin(router.currentRoute.value);
  router.push({ path: redirect });
};

export const loginError = ref();
export const loginErrorDetails = ref();
export const handleLoginError = (error) => {
  let response = error.response;
  switch (response.status) {
    case 401:
      loginError.value = 'Connexion refusée';
      loginErrorDetails.value = response.data.message;
      break;
    default:
      loginError.value = 'Erreur inconnue (' + response.data.code + ')';
      loginErrorDetails.value = response.data.message;
      break;
  }
};

export default {
  handleAuthentication,
  apiLogin,
  redirectAfterLogin,
  handleLoginSuccess,
  handleLoginError,
  loginError
};
