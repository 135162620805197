<template>
  <div class="user-info">
    <div class="user-avatar" :style="{ backgroundImage: 'url(' + avatarSrc + ')' }">
      <span v-if="!avatarSrc">{{ initials }}</span>
    </div>

    <div class="user-name">
      <p class="h1 uppercase">
        {{ user.personalInfos.firstName }}
        {{ user.personalInfos.lastName }}
      </p>
      <p class="user-group">
        <i class="icon" :class="'icon-user-' + getGroupIcon"></i>
        <strong>{{ user.groupNames }}</strong>
      </p>
    </div>
  </div>
</template>

<script setup>
import { mapState } from '@/store/mappers';
import { computed } from 'vue';
import { findMediaByIdentifier } from '@/helper/mediaHelper';
import { apiURL } from '@/api/api';
import { isGranted } from '@/composables/security';
const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const avatar = computed(() => {
  return findMediaByIdentifier(props.user.medias, 'avatar');
});

const cleanApiURL = computed(() => {
  if (null !== apiURL && apiURL.charAt(apiURL.length - 1) === '/') {
    return apiURL.substr(0, apiURL.length - 1);
  }
  return apiURL;
});

const avatarSrc = computed(() => {
  if (apiURL && avatar.value) {
    return (
      cleanApiURL.value +
      '/medias/users/' +
      avatar.value.resourceIdentifier +
      '/avatar/' +
      avatar.value.originalName
    );
  }
  return null;
});

const initials = computed(() => {
  return props.user.personalInfos.firstName.substring(0, 1).toUpperCase();
});

const getGroupIcon = computed(() => {
  if (isGranted('ROLE_REFERENT', props.user)) {
    return 'admin';
  }
  // if (isGranted('ROLE_REFERENT', props.user)) {
  //   return 'referent';
  // }
  return 'user';
  // Choices are : collaborator | admin | referent
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';

.user-info {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  height: 100%;
  .user-name {
    text-align: center;
    p {
      margin: 0;
    }
  }
}

@include device(medium) {
  .user-info {
    display: flex;
    flex-direction: row;
    align-items: center;

    .user-avatar {
      width: 80px;
      height: 80px;
      border-radius: 100%;
      overflow: hidden;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      //cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      background-color: $turquoise;
      color: white;
      font-size: 3rem;
      font-weight: 700;
      line-height: 1;
    }

    .user-name {
      text-align: left;

      .h1 {
        margin-bottom: 0.5rem;
      }
      strong {
        font-size: 0.875rem;
      }
    }

    .user-group {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
</style>
