<template>
  <div v-if="notification" :class="{ notification: true, unread: isUnread(notification) }">
    <i class="icon-calendrier"></i> {{ notification.message }}<br />
    le {{ formatDate(notification.createdAt) }}
  </div>
</template>

<script setup>
import { format } from 'date-fns';
import { defineProps, onMounted } from 'vue';
import { putNotification } from '@/api/notification';
import { mapMutations } from '@/store/mappers';

const { setNotificationRead } = mapMutations('notifications');

const props = defineProps({
  notification: Object
});
const currentTime = new Date();

const formatDate = (date, dateFormat = 'dd/MM/yyyy à HH:ii') => {
  return format(new Date(date), dateFormat);
};

const isUnread = (notification) => {
  return notification.readAt === null;
};

onMounted(() => {
  // console.log(props.notification);
  putNotification(props.notification.id, {
    id: props.notification.id,
    readAt: currentTime
  }).then((response) => {
    setNotificationRead(response.data);
  });
});
</script>

<style lang="scss" scoped>
.unread {
  font-weight: bold;
}
</style>
