<template>
  <button v-if="downloadInProgress === true" class="btn green disabled">Téléchargement...</button>
  <button v-else-if="downloadAvailable" class="btn green" @click="download()">Télécharger</button>
  <button v-else-if="downloadAvailable === null" class="btn green disabled">Vérification...</button>
  <button v-else class="btn green disabled">Indisponible</button>
</template>

<script setup>
import { downloadFile } from '@/composables/simulation';
import { defineProps, onMounted, ref, watch } from 'vue';
import { hasOutputFile } from '@/api/simulation';

const downloadAvailable = ref(null);
const downloadAlternative = ref(false);
const downloadInProgress = ref(false);
const props = defineProps({
  simulation: Object,
  filename: String,
  altFilename: String
});

//Handle download button availability by querying API
const checkDownloadAvailability = () => {
  downloadAvailable.value = null;
  hasOutputFile(props.simulation, props.filename).then((response) => {
    if (response.data === false && props.altFilename !== undefined) {
      hasOutputFile(props.simulation, props.altFilename).then((response) => {
        downloadAvailable.value = response.data;
        downloadAlternative.value = true;
      });
    } else {
      downloadAvailable.value = response.data;
    }
  });
};
//Do this on mount and simulation change
onMounted(checkDownloadAvailability);
watch(
  () => props.simulation,
  (newSimulation, oldSimulation) => {
    if (oldSimulation.identifier !== newSimulation.identifier) {
      checkDownloadAvailability();
    }
  }
);

//Handle the download of the file
const download = () => {
  downloadInProgress.value = true;
  downloadFile(
    props.simulation,
    downloadAlternative.value === true ? props.altFilename : props.filename
  ).then(() => {
    downloadInProgress.value = false;
  });
};
</script>

<style lang="scss" scoped></style>
