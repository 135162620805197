import { isAfter } from 'date-fns';
import projectApi from '../api/project';

export const getShortName = (name) => {
  return name.substring(0, 1).toUpperCase() + name.substring(1, 2).toLowerCase();
};

export const getLastSimulationDate = (simulations) => {
  return simulations
    ? simulations.reduce((lastDate, simulation) => {
        if (lastDate === null || isAfter(new Date(simulation.createdAt), lastDate)) {
          lastDate = new Date(simulation.createdAt);
        }
        return lastDate;
      }, null)
    : null;
};

export const updateProject = (projectIdentifier, changes, callback) => {
  projectApi.putProject(projectIdentifier, changes).then(callback);
};
