import { has } from 'lodash';

const NEW_FILTER = {
  identifier: null,
  exclude: false,
  value: null
};

const state = {
  filters: {}
};

const getters = {
  getFiltersForResult: (state) => (representation) => {
    return state.filters[representation];
  },
  getActiveFiltersForResult: (state) => (representation) => {
    return state.filters[representation]?.filter(
      (filter) => filter.identifier !== null && filter.value !== null
    );
  },
  countFiltersForResult: (state, getters) => (representation) => {
    return getters.getActiveFiltersForResult(representation)?.length;
  }
};

const mutations = {
  createFiltersIfNew: (state, representation) => {
    if (!has(state.filters, representation)) {
      state.filters[representation] = [];
    }
  },
  addNewFilter: (state, representation) => {
    state.filters[representation].push(NEW_FILTER);
  },
  removeFilter: (state, { representation, index }) => {
    state.filters[representation].splice(index, 1);
  },
  updateFilter: (state, { representation, index, value }) => {
    state.filters[representation][index] = value;
  },
  updateAllFiltersForIdentifier: (state, { representation, identifier, property, value }) => {
    state.filters[representation]?.forEach((filter) => {
      if (filter.identifier === identifier) {
        filter[property] = value;
      }
    });
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
