<template>
  <select
    v-if="type === 'select'"
    :class="{ error: hasError && submitted }"
    :id="id"
    v-model="value"
    @change="onChange"
  >
    <option v-if="required === false" value="">{{ placeholder }}</option>
    <option
      v-for="(option, index) in options"
      :value="option.value"
      v-bind:key="'select-' + id + '-' + index"
    >
      {{ option.label }}
    </option>
  </select>
  <textarea
    v-else-if="type === 'textarea'"
    :class="{ error: hasError && submitted }"
    :id="id"
    v-model="value"
    @change="onChange"
    :placeholder="placeholder"
  >
  </textarea>
  <input
    v-else
    :type="type"
    :class="{ error: hasError && submitted }"
    :id="id"
    v-model="value"
    @change="onChange"
    :placeholder="placeholder"
  />
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue';

const value = ref(null);

const props = defineProps({
  id: String,
  initialValue: String,
  model: String,
  placeholder: String,
  submitted: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'text'
  },
  required: {
    type: Boolean,
    default: false
  },
  options: {
    type: Array
  }
});
const emit = defineEmits(['change', 'error']);

const hasError = computed(() => {
  if (
    props.required === true &&
    (value.value === '' || value.value === null || value.value === undefined)
  ) {
    emit('error', props.model, 'required');
    return true;
  }
  emit('error', props.model, null);
  return false;
});

const onChange = (event) => {
  let newValue = event.target.value;
  emit('change', props.model, newValue);
};

onMounted(() => {
  value.value = props.initialValue;
  emit('change', props.model, props.initialValue);
});

watch(
  () => props.initialValue,
  () => {
    value.value = props.initialValue;
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';

@include device(medium) {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.with-border {
      border-bottom: 1px solid $grey-3;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }
  }
}

@include device(large) {
  .title-block .title {
    width: 65%;
    max-width: 600px;
  }
}
</style>
