import merge from 'lodash/merge';
import simulationApi from '@/api/simulation';
import { getSimulationVersion } from '@/composables/simulation';

const state = {
  isSimulationLoading: false,
  simulation: null,
  section: null,
  category: null,
  criteria: null,
  zonesJson: []
};

const getters = {
  getZonesBySimulation: (state) => (args) => {
    const { project, simulation } = args;
    let zone = state.zonesJson.find((zone) => {
      return zone.version === getSimulationVersion(project, simulation);
    });
    return zone !== undefined ? zone : null;
  }
};

const actions = {
  fetchSimulation({ state, commit }, payload) {
    if (state.simulation?.identifier !== payload.identifier) {
      commit('setIsSimulationLoading', true);
      return simulationApi.getSimulation(payload.identifier).then((response) => {
        commit('setCurrentSimulation', response.data);
        commit('setIsSimulationLoading', false);
      });
    } else {
      return Promise.resolve();
    }
  },
  fetchZonesBySimulation: ({ commit }, { project, simulation }) => {
    return simulationApi.getZonesJson(project, simulation).then((zoneResponse) => {
      commit('addZonesJsonToSimulation', {
        data: zoneResponse.data,
        version: getSimulationVersion(project, simulation)
      });
    });
  }
};

const mutations = {
  setIsSimulationLoading(state, status) {
    state.isSimulationLoading = status;
  },
  setCurrentSimulation(state, payload) {
    state.simulation = payload;
  },
  updateCurrentSimulation(state, payload) {
    state.simulation = merge(state.simulation, payload);
  },
  setCurrentSection(state, payload) {
    state.section = payload;
  },
  setCurrentCategory(state, payload) {
    state.category = payload;
  },
  setCurrentCriteria(state, payload) {
    state.criteria = payload;
  },
  addZonesJsonToSimulation(state, payload) {
    state.zonesJson.push(payload);
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
