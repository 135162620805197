<template>
  <div class="new-project-step4" v-if="substep === 'remove'">
    <div class="text-icon">
      <i class="icon icon-bin"></i>
      <p class="">
        SILOGUES vous propose d’évincer de la simulation les établissements jugés non producteurs de
        flux. Si vous souhaitez néanmoins conserver certaines catégories, veillez à les décocher.
      </p>
    </div>
    <div class="grey-block">
      <label class="custom-checkbox checkbox-title">
        <span v-if="toggleAllChecked">
          Tout décocher (conserver tous les établissements du fichier SIRENE)
        </span>
        <span v-else>Tout cocher</span>
        <input type="checkbox" v-model="toggleAllChecked" @click="toggleAllRemoveFilters" />
        <span class="checkmark"></span>
      </label>
      <div class="grid grid-1of3">
        <div
          class="grid-cell"
          v-for="(colSirenFilters, colNb) in sirenFiltersRemove"
          :key="'remove_' + colNb"
        >
          <label
            class="custom-checkbox meta"
            v-for="(sirenFilter, rowNb) in colSirenFilters"
            :key="'reclassify_' + colNb + '_' + rowNb"
          >
            {{ sirenFilter.name }}
            <input
              type="checkbox"
              :checked="sirenFilter.checked"
              @click="selectFilter(sirenFilter)"
            />
            <span class="checkmark"></span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="new-project-step4" v-if="substep === 'reclassify'">
    <div class="text-icon">
      <i class="icon icon-warning"></i>
      <p>
        Lorsque SILOGUES détecte des incohérences dans le fichier SIRENE, il les corrige de la façon
        suivante:
      </p>
    </div>
    <div class="grey-block">
      <div class="grid">
        <div class="grid-cell info">
          <p><b>Artisans dont l’effectif > 20 salariés</b> sont reclassés en industrie</p>
          <p><b>Industries dont l’effectif &lt; 20 salariés</b> sont reclassées en artisanat</p>
          <p>
            <b>Sièges d’entreprises industrielles</b> comportant plus de 5 établissements sont
            reclassés en bureaux
          </p>
          <p><b>Hypermarchés et supermarchés &lt; 50 salariés</b> sont reclassés en supérettes</p>
        </div>
        <div class="grid-cell info">
          <p>
            <b>Les grands magasins spécialisés &lt; 50 salariés</b> sont reclassés en autres
            commerces de détail
          </p>
          <p><b>Supérettes > 50 salariés</b> sont reclassées en supermarchés</p>
          <p><b>Supérettes > 100 salariés</b> sont reclassées en hypermarchés</p>
          <p><b>Petits commerces > 50 salariés</b> sont reclassés en grands magasins spécialisés</p>
        </div>
      </div>
      <!--      <label-->
      <!--        v-if="sirenFiltersReclassifyDefaultItem !== null"-->
      <!--        class="custom-checkbox checkbox-title"-->
      <!--      >-->
      <!--        {{ sirenFiltersReclassifyDefaultItem.name }}-->
      <!--        <input-->
      <!--          type="checkbox"-->
      <!--          :checked="noSirenFiltersReclassifyChecked"-->
      <!--          @click="unselectAllReclassifyFilters"-->
      <!--        />-->
      <!--        <span class="checkmark"></span>-->
      <!--      </label>-->
      <!--      <div class="grid grid-1of2">-->
      <!--        <div-->
      <!--          class="grid-cell"-->
      <!--          v-for="(colSirenFilters, colNb) in sirenFiltersReclassify"-->
      <!--          :key="'reclassify_' + colNb"-->
      <!--        >-->
      <!--          <label-->
      <!--            class="custom-checkbox meta"-->
      <!--            v-for="(sirenFilter, rowNb) in colSirenFilters"-->
      <!--            :key="'reclassify_' + colNb + '_' + rowNb"-->
      <!--          >-->
      <!--            {{ sirenFilter.name }}-->
      <!--            <input-->
      <!--              type="checkbox"-->
      <!--              :checked="sirenFilter.checked"-->
      <!--              @click="selectFilter(sirenFilter)"-->
      <!--            />-->
      <!--            <span class="checkmark"></span>-->
      <!--          </label>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>
  </div>
  <create-project-actions
    @next="submit"
    @previous="previous"
    has-previous
    has-next
    :is-valid="isValid"
    :submitting="submitting"
    :error-message="errorMessage"
  >
  </create-project-actions>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref, watch } from 'vue';
import { errorMessage, submitting } from '@/composables/createProject';
import CreateProjectActions from './CreateProjectActions.vue';
import { getSirenFilters, putProject } from '@/api/project';
import { some } from 'lodash';

//Props
const props = defineProps({
  projectData: {
    type: Object,
    default: null
  }
});

// Substep
const substep = ref('remove');

watch(
  () => props.projectData,
  () => {
    if (hasSireneFiltersConfigured()) {
      substep.value = 'reclassify';
    }
  }
);

const hasSireneFiltersConfigured = () => {
  let removeFilters = props.projectData?.siren?.filters.filter((filter) => {
    return filter.step === 'remove';
  });
  return removeFilters.length > 0;
};

//Handle filter listing
const toggleAllChecked = ref(true);
const sirenFilters = ref(null);
const sirenFiltersRemoveDefaultItem = computed(() => {
  if (sirenFilters.value === null) {
    return null;
  }
  let item = sirenFilters.value.filter((sirenFilter) => {
    return sirenFilter.step === 'remove';
  });
  return item[0];
});
const sirenFiltersRemove = computed(() => {
  return organizeByStepAndCols(sirenFilters.value, 'remove', 3);
});
const sirenFiltersReclassifyDefaultItem = computed(() => {
  if (sirenFilters.value === null) {
    return null;
  }
  let item = sirenFilters.value.filter((sirenFilter) => {
    return sirenFilter.step === 'reclassify';
  });
  return item[0];
});

const loadSirenFilters = () => {
  getSirenFilters().then((response) => {
    // response.data.forEach((filter) => {
    //   console.log("plap");
    //   filter.checked = true;
    // });
    sirenFilters.value = response.data;
    // console.log("plop");
    if (
      props.projectData?.siren?.filters !== null &&
      props.projectData?.siren?.filters.length > 0
    ) {
      props.projectData?.siren?.filters.forEach((filter) => {
        selectFilter(filter);
      });
    } else {
      sirenFilters.value.forEach((filter) => {
        let selectedFilterIndex = sirenFilters.value.findIndex((sirenFilter) => {
          return sirenFilter.id === filter.id;
        });
        sirenFilters.value[selectedFilterIndex].checked = true;
      });
    }
  });
};
onMounted(loadSirenFilters);
const organizeByStepAndCols = (dataArray, step, nbOfCols) => {
  return dataArray !== null
    ? organizeByCols(
        dataArray.filter((sirenFilter) => {
          return sirenFilter.step === step;
        }),
        nbOfCols
      )
    : null;
};
const organizeByCols = (dataArray, nbOfCols) => {
  return dataArray.reduce((cols, sirenFilter, index) => {
    const col = index % nbOfCols;
    cols[col].push(sirenFilter);
    return cols;
  }, generateGridColsArray(nbOfCols));
};
const generateGridColsArray = (nbOfCols) => {
  const cols = [];
  while (--nbOfCols >= 0) {
    cols.push([]);
  }
  return cols;
};

//Handle filter selection
const selectFilter = (selectedFilter, forceCheck = false) => {
  if (sirenFilters.value !== null && selectedFilter !== undefined) {
    let selectedFilterIndex = sirenFilters.value.findIndex((sirenFilter) => {
      return sirenFilter.id === selectedFilter.id;
    });
    sirenFilters.value[selectedFilterIndex].checked =
      forceCheck === true ? true : !sirenFilters.value[selectedFilterIndex].checked;
    if (sirenFilters.value[selectedFilterIndex].step === 'remove') {
      removeFilter(sirenFiltersRemoveDefaultItem.value);
    } else {
      removeFilter(sirenFiltersReclassifyDefaultItem.value);
    }
  }
};
const removeFilter = (selectedFilter) => {
  if (sirenFilters.value !== null) {
    let selectedFilterIndex = sirenFilters.value.findIndex((sirenFilter) => {
      return sirenFilter.id === selectedFilter.id;
    });
    sirenFilters.value[selectedFilterIndex].checked = false;
  }
};

const toggleAllRemoveFilters = (e) => {
  toggleAllFiltersForStep(e, 'remove');
  selectFilter(sirenFiltersRemoveDefaultItem.value);
};
const toggleAllFiltersForStep = (e, step) => {
  sirenFilters.value.forEach((sirenFilter) => {
    if (sirenFilter.step === step) {
      sirenFilter.checked = !toggleAllChecked.value;
    }
  });
};

//Submission / substeps
const isValid = computed(() => {
  return true;
});
const emit = defineEmits(['next', 'previous', 'substep', 'go-to-step']);
const selectedSirenFilters = computed(() => {
  return sirenFilters.value.filter((sirenFilter) => {
    return sirenFilter.checked === true;
  });
});
const submit = () => {
  submitting.value = true;
  if (substep.value === 'remove') {
    selectFilter(sirenFiltersRemoveDefaultItem.value, true);
  }
  if (substep.value === 'reclassify') {
    selectFilter(sirenFiltersReclassifyDefaultItem.value, true);
  }
  putProject(props.projectData.id, {
    siren: { filters: selectedSirenFilters.value }
  }).then((response) => {
    if (substep.value === 'remove') {
      substep.value = 'reclassify';
    } else {
      emit('next', response.data);
    }
    submitting.value = false;
  });
};
const previous = () => {
  if (substep.value === 'reclassify') {
    substep.value = 'remove';
  } else {
    emit('previous');
  }
};
const getSubstepLabel = (substep) => {
  switch (substep) {
    case 'remove':
      return "(étape 1 sur 2 - Suppression d'établissements)";
    case 'reclassify':
      return "(étape 2 sur 2 - Reclassement d'établissements)";
    default:
      return 'étape inconnue';
  }
};
watch(
  substep,
  (newSubstep) => {
    console.log('newSubstep', newSubstep);
    emit('substep', {
      step: 4,
      name: newSubstep,
      label: getSubstepLabel(newSubstep)
    });
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';
@import '@/assets/scss/commons/_grid.scss';
@import '@/assets/scss/commons/_icon-text.scss';

.meta {
  margin-top: 20px;
}

.checkbox-title {
  margin-bottom: 2rem;
  font-weight: 400;
  .checkmark {
    top: 0.25rem;
  }
}
.info p {
  max-width: 400px;
}
</style>
