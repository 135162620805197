<template>
  <div v-if="loggedUser && usersLoaded" class="block">
    <div class="title-block uppercase">
      <div class="title">
        <h1>Utilisateurs</h1>
        <p class="h3">Projets associés</p>
      </div>
      <div class="button-group">
        <popover>
          <s-button icon="icon-sort" size="sm">Filtrer par</s-button>
          <template #popover>
            <div class="filters-form">
              <h3>Filtrer par...</h3>
              <div class="form-container">
                <select v-model="filters.role">
                  <option value="">Rôle</option>
                  <option value="collaborator">Collaborateur</option>
                  <option value="manager">Modélisateur</option>
                  <option value="administrator">Administrateur</option>
                </select>
              </div>
              <div class="form-container">
                <select v-model="filters.project">
                  <option value="">Projet</option>
                  <option
                    v-for="project in projects"
                    :value="project.id"
                    :key="'project_' + project.id"
                  >
                    {{ project.name }}
                  </option>
                </select>
              </div>
              <div class="form-container">
                <input type="text" placeholder="Nom" v-model="filters.name" />
              </div>
              <div class="button-group flex-end">
                <s-button color="purple" @click="resetFilters">Effacer les filtres</s-button>
              </div>
            </div>
          </template>
        </popover>
        <s-button color="purple" size="sm" icon="icon-plus" @click="addUser('new')"
          ><b>Ajouter un utilisateur</b></s-button
        >
      </div>
      <ModalUser
        :open="addUserModalOpened"
        :type="userModalType"
        :users="users"
        :user="user"
        @close="onModalClosed"
      />
    </div>
    <UserListRow
      v-for="u in filteredUsers"
      :key="'user_' + u.id"
      :user="u"
      @edit-user="editUser"
      @edit-projects="editUserProjects"
    />
  </div>
  <div v-else class="block">
    <div class="loader">
      <spinner-loader color="#88BEDC"></spinner-loader>
    </div>
  </div>
</template>

<script setup>
import { mapActions, mapState } from '@/store/mappers';
import { computed, onMounted, reactive, ref } from 'vue';
import ModalUser from '@/components/dashboard/users/ModalUser.vue';
import UserListRow from '@/components/dashboard/users/UserListRow.vue';
import SButton from '@/components/commons/SButton.vue';
import SpinnerLoader from 'vue-spinner/src/ScaleLoader.vue';
import { findIndex, forEach } from 'lodash';
import { isGranted } from '@/composables/security';
import Popover from '@/components/commons/Popover.vue';
import { orderBy } from 'lodash/collection';

const { fetchUsers, fetchProjects } = mapActions('organization');
const { user: loggedUser } = mapState('security');
const { users, projects, usersLoaded } = mapState('organization');

onMounted(() => {
  load();
});

//General load (project, simulation, section)
const load = () => {
  fetchUsers(loggedUser.value.organization);
  fetchProjects(loggedUser.value.organization);
};

// Filters management
const emptyFilters = {
  role: '',
  project: '',
  name: ''
};
const filters = reactive({ ...emptyFilters });

const filteredUsers = computed(() => {
  let filtered = users.value.filter((user) => {
    if (filters.role !== null && filters.role !== '' && !userHasProjectRole(user, filters.role)) {
      return false;
    }
    if (
      filters.project !== null &&
      filters.project !== '' &&
      !userHasProject(user, filters.project)
    ) {
      return false;
    }
    return !(filters.name !== null && filters.name !== '' && !userHasName(user, filters.name));
  });
  return orderBy(filtered, ['personalInfos.firstName', 'personalInfos.lastName'], ['asc', 'asc']);
});

const onModalClosed = (userAdded = false) => {
  addUserModalOpened.value = false;
  if (userAdded === true) {
    resetFilters();
  }
};

const resetFilters = () => {
  Object.assign(filters, emptyFilters);
};

const userHasProject = (user, projectId) => {
  return (
    findIndex(user.projects, (userProject) => {
      return userProject.project.id === projectId;
    }) !== -1
  );
};

const userHasProjectRole = (user, role) => {
  if (role === 'administrator') {
    return isGranted('ROLE_REFERENT', user);
  }
  return (
    findIndex(user.projects, (userProject) => {
      return userProject.role === role;
    }) !== -1
  );
};

const userHasName = (user, name) => {
  let expr = new RegExp(`${name}`, 'i');
  return (
    user.username.search(expr) !== -1 ||
    user.email.search(expr) !== -1 ||
    user.personalInfos.lastName.search(expr) !== -1 ||
    user.personalInfos.firstName.search(expr) !== -1
  );
};

// Add User management
const addUserModalOpened = ref(false);
const userModalType = ref(null);
const user = ref(null);

const addUser = (type) => {
  user.value = null;
  addUserModalOpened.value = true;
  userModalType.value = 'full';
};

// Edit user management
const editUser = (u) => {
  user.value = u;
  addUserModalOpened.value = true;
  userModalType.value = 'full';
};

const editUserProjects = (u) => {
  user.value = u;
  addUserModalOpened.value = true;
  userModalType.value = 'projects';
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_layout.scss';
@import '@/assets/scss/commons/_form.scss';

.title {
  display: flex;
  align-items: center;
  gap: 1rem;
  h1 {
    width: calc(3rem + 200px);
  }
  h1,
  .h3 {
    color: $blue-1;
    margin: 0;
    text-transform: none;
  }
}
.title-block {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.button-group {
  display: flex;
  gap: 1rem;
  position: relative;
  &.flex-end {
    justify-content: flex-end;
  }
}

.filters-form {
  text-align: left;
  h3 {
    margin-bottom: 2rem;
  }
  .form-container {
    input {
      width: 100%;
    }
  }
}
</style>
