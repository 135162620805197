import { createStore } from 'vuex';
import VuexPersistence from 'vuex-persist';

import security from './modules/security';
import navigation from './modules/navigation';
import project from './modules/project';
import simulation from './modules/simulation';
import filters from './modules/filters';
import tags from './modules/tags';
import notifications from './modules/notifications';
import organization from './modules/organization';

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,
  modules: ['security']
});

export default createStore({
  modules: {
    security,
    navigation,
    project,
    simulation,
    notifications,
    filters,
    tags,
    organization
  },
  plugins: [vuexLocal.plugin]
});
