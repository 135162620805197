<template>
  <Modal :open="open" @close="emit('close')">
    <template #title>Modifier mes coordonnées</template>
    <template #content>
      <div class="form-container">
        <text-with-bar title>Identité</text-with-bar>
        <div class="form-row">
          <label for="firstName">Prénom<span class="required">*</span></label>
          <app-input
            id="firstName"
            placeholder="Prénom"
            model="personalInfos.firstName"
            :initialValue="user.personalInfos.firstName"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
        <div class="form-row">
          <label for="lastName">Nom<span class="required">*</span></label>
          <app-input
            id="lastName"
            placeholder="Nom"
            model="personalInfos.lastName"
            :initialValue="user.personalInfos.lastName"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
        <div class="form-row">
          <label for="lastName">Poste<span class="required">*</span></label>
          <app-input
            id="job"
            placeholder="Poste"
            model="personalInfos.job"
            :initialValue="user.personalInfos.job"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
      </div>
      <div class="form-container">
        <text-with-bar title>Contact</text-with-bar>
        <div class="form-row">
          <label for="email">Mail<span class="required">*</span></label>
          <app-input
            id="email"
            type="email"
            placeholder="jean@mon-site.fr"
            model="email"
            :initialValue="user.email"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
        <div class="form-row">
          <label for="phone">Téléphone</label>
          <app-input
            id="phone"
            placeholder="Téléphone"
            model="personalInfos.phone"
            :initialValue="user.personalInfos.phone"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <button
        class="btn primary"
        @click="submit"
        v-if="submissionInProgress === false && submissionDone === false"
      >
        Enregistrer les informations
      </button>
      <button
        class="btn primary"
        disabled
        v-else-if="submissionInProgress === true && submissionDone === false"
      >
        <i class="loader"></i>
        Enregistrement en cours...
      </button>
      <button v-else class="btn primary" disabled>
        <i class="icon icon-check"></i>
        Enregistré !
      </button>
      <div v-if="formErrors !== ''" class="error callout">
        <p>{{ formErrors }}</p>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/commons/Modal.vue';
import { reactive, ref } from 'vue';
import { mapMutations, mapState } from '@/store/mappers';
import { cloneDeep } from 'lodash';
import { submitForm, updateFormFieldError, updateFormFieldValue } from '@/helper/formHelper';
import { updateLoggedUser } from '@/composables/user';
import TextWithBar from '@/components/commons/TextWithBar.vue';
import { checkFormErrors } from '@/helper/formHelper';
import AppInput from '@/components/commons/AppInput.vue';

const { setUser } = mapMutations('security');
const { user } = mapState('security');

const emit = defineEmits(['close']);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
});
const submitted = ref(false);
const submissionInProgress = ref(false);
const submissionDone = ref(false);
const formErrors = ref('');
const schema = {
  email: null,
  personalInfos: {
    firstName: null,
    lastName: null,
    job: null,
    phone: null
  }
};
const form = reactive(schema);
const errors = reactive(cloneDeep(schema));

const submit = () => {
  submitted.value = true;
  let errorMessage = checkFormErrors(errors);
  if ('' !== errorMessage) {
    submissionInProgress.value = false;
    formErrors.value = errorMessage;
  } else {
    submitForm(errors, formErrors, () => {
      submissionInProgress.value = true;
      updateLoggedUser(
        form,
        (response) => {
          setUser(response.data);
          submissionInProgress.value = false;
          submissionDone.value = true;
          submitted.value = false;
          setTimeout(() => {
            submissionDone.value = false;
            emit('close');
          }, 1000);
        },
        (error) => {
          submissionInProgress.value = false;
          formErrors.value = error.response.data.message;
        }
      );
    });
  }
};

const updateFormValue = (property, value) => {
  updateFormFieldValue(form, property, value);
};
const updateFormError = (property, message) => {
  updateFormFieldError(errors, property, message);
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_form.scss';

.callout {
  margin-top: 1rem;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  .icon-check::before {
    color: white;
  }
}
.form-container {
  margin-top: 2rem;
}
.loader {
  width: 14px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid $white;
  border-right-color: $purple-2;
  animation: l2 1s infinite ease-in-out;
  margin: 0;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
</style>
