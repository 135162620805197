<template>
  <div v-if="hasMap" id="projectMap" class="block map"></div>
</template>

<script setup>
import L from 'leaflet';
import { computed, defineProps, onMounted, onUnmounted, ref, watch } from 'vue';
import { getZonesJson } from '@/api/simulation';
import { unobserve } from '@/composables/utils';
import { createLeafletBoundsFromBoundaries } from '@/helper/mapHelper';

const props = defineProps({
  project: {
    type: Object,
    default: null
  }
});
const mapInstance = ref(null);
const projectId = ref(null);
const mapId = 'projectMap';
const hasMap = computed(() => {
  return (
    (props.project?.originLat && props.project?.originLng) ||
    props.project?.zones.length > 0 ||
    props.project?.zonesCustomFile !== null
  );
});
const firstSimulation = computed(() => {
  return props.project?.simulations[props.project?.simulations.length - 1];
});
const perimeterGeoJson = ref(null);

//Map lifecycle
const fetchGeoJsonLayer = async () => {
  try {
    const response = await getZonesJson(props.project, firstSimulation.value, 'perim', 'geojson');
    perimeterGeoJson.value = response.data.features[0];
  } catch (e) {
    perimeterGeoJson.value = false;
  } finally {
    handleMap();
  }
};

const handleMap = () => {
  if (mapInstance.value === null) {
    removeMap();
    setMap();
  } else {
    if (projectId.value !== null && projectId.value !== props.project.id) {
      removeMap();
      setMap();
    }
  }
  projectId.value = props.project.id;
};

const setMap = () => {
  if (mapInstance.value === null && hasMap.value) {
    mapInstance.value = L.map(mapId, {
      scrollWheelZoom: false,
      fullscreenControlOptions: {
        position: 'topleft'
      }
    });

    //Create map background (Openstreetmap)
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }).addTo(mapInstance.value);

    //Add perimeter layer
    if (perimeterGeoJson.value) {
      L.vectorGrid
        .slicer(unobserve(perimeterGeoJson.value), {
          interactive: false,
          maxZoom: 18,
          tolerance: 15,
          vectorTileLayerStyles: {
            sliced: function (properties) {
              return {
                fillColor: '#88BEDC',
                fillOpacity: 0.6,
                color: '#888',
                weight: 3,
                fill: true
              };
            }
          }
        })
        .addTo(mapInstance.value);
    }

    // Center map by cases
    if (
      firstSimulation.value?.boundaries !== undefined &&
      firstSimulation.value?.boundaries.length > 0
    ) {
      centerMapFromBoundaries();
    } else if (props.project?.originLat && props.project?.originLng) {
      centerMapFromGPS();
    } else if (props.project?.zones.length > 0) {
      centerMapFromZones();
    } else if (props.project?.zonesCustomFile !== null) {
      centerMapFromZonesFile();
    }
  }
};

const removeMap = () => {
  if (mapInstance.value !== null) {
    mapInstance.value.remove();
    mapInstance.value = null;
  }
};

const centerMapFromBoundaries = () => {
  const bounds = createLeafletBoundsFromBoundaries(firstSimulation.value.boundaries);
  mapInstance.value.fitBounds(bounds);
};

const centerMapFromGPS = () => {
  mapInstance.value.setView(
    [props.project?.originLat, props.project?.originLng],
    props.project?.originZoom || 12
  );
};

const centerMapFromZones = () => {
  //Add projects to map
  let bounds = [];
  props.project.zones.forEach((zone) => {
    let myIcon = L.divIcon({
      className: 'zone-center-icon',
      iconSize: [60, 60]
    });
    L.marker([zone.centroideLng, zone.centroideLat], {
      icon: myIcon
    }).addTo(mapInstance.value);

    bounds.push([zone.centroideLng, zone.centroideLat]);
  });
  mapInstance.value.fitBounds(bounds, {
    maxZoom: props.project?.originZoom || 12
  });
};

const centerMapFromZonesFile = () => {};

onUnmounted(removeMap);
onMounted(fetchGeoJsonLayer);
watch(() => props.project, fetchGeoJsonLayer);
</script>

<style lang="scss" scoped>
.block.map {
  padding: 0;
  position: relative;
  height: 580px;
  overflow: hidden;
  z-index: 0;

  iframe {
    width: 100%;
  }
}
</style>
