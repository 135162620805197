<template>
  <component
    :is="component"
    :disabled="disabled"
    :to="to"
    class="s-button"
    :class="classBinding"
    :href="href"
  >
    <span v-if="icon && iconPosition === IconPosition.Left" class="s-button__icon s-button__left">
      <i class="icon" :class="icon"></i>
    </span>
    <span v-if="$slots.default" class="s-button__content">
      <slot />
    </span>
    <span v-if="icon && iconPosition === IconPosition.Right" class="s-button__icon s-button__right">
      <i class="icon" :class="icon"></i>
    </span>
  </component>
</template>

<script>
const ButtonColor = {
  Blue: 'blue',
  Grey: 'grey',
  Purple: 'purple',
  Turquoise: 'turquoise',
  Green: 'green',
  Orange: 'orange',
  Light: 'light',
  White: 'white'
};

const ButtonSize = {
  Small: 'sm'
};

const IconPosition = {
  Left: 'left',
  Right: 'right'
};

export default {};
</script>

<script setup>
import { computed } from 'vue';

const props = defineProps({
  circle: {
    type: Boolean,
    default: false
  },

  color: {
    type: String,
    default: ButtonColor.Light
  },

  disabled: {
    type: Boolean,
    default: false
  },

  fluid: {
    type: Boolean,
    default: false
  },

  href: {
    type: String,
    default: null
  },

  size: {
    type: String,
    default: null
  },
  to: {
    type: [Object, String],
    default: null
  },
  icon: {
    type: String,
    default: null
  },

  iconPosition: {
    type: String,
    default: IconPosition.Right
  }
});

const component = computed(() => {
  if (!props.disabled) {
    if (props.href) {
      return 'a';
    }

    if (props.to) {
      return 'router-link';
    }
  }

  return 'button';
});

const classBinding = computed(() => {
  return {
    's-button--color-purple': props.color === ButtonColor.Purple,
    's-button--color-turquoise': props.color === ButtonColor.Turquoise,
    's-button--color-green': props.color === ButtonColor.Green,
    's-button--color-grey': props.color === ButtonColor.Grey,
    's-button--color-blue': props.color === ButtonColor.Blue,
    's-button--color-orange': props.color === ButtonColor.Orange,
    's-button--color-light': props.color === ButtonColor.Light,
    's-button--color-white': props.color === ButtonColor.White,

    's-button--circle': props.circle,
    's-button--fluid': props.fluid,

    's-button--size-sm': props.size === ButtonSize.Small,
    's-button--icon-only': props.iconOnly
  };
});
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';

.s-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  box-shadow: none;
  cursor: pointer;
  outline: none;

  text-align: center;
  text-decoration: none;
  font-size: 0.875rem;
  font-weight: 700;

  border-radius: 0.25rem;
  border: 0;
  padding: 0.75rem 1rem;

  transition: background-color 0.2s ease-out, color 0.2s ease-out;

  :slotted(svg) {
    height: 16px;
    width: auto;
  }

  > * {
    display: flex;
  }

  &__content {
    flex-direction: column;
    line-height: 1;

    :first-child {
      display: flex;
    }
  }

  &--size-sm {
    font-weight: 500;
    padding: 0.75rem;
  }

  &__icon {
    font-size: 1rem;
  }

  &--fluid {
    width: 100%;
  }

  &--circle {
    height: 2.5rem;
    width: 2.5rem;
    padding: 0;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    outline: none;
  }

  &--color-light {
    background-color: $grey-3;
    color: $blue-2;

    &:hover {
      background-color: darken($grey-3, 10%);
    }
  }

  &--color-purple {
    background-color: $purple;
    color: $white;

    &:hover {
      background-color: $purple-2;
    }
  }

  &--color-green {
    background-color: $green;
    color: $white;

    &:hover {
      background-color: $green-2;
    }
  }

  &--color-turquoise {
    background-color: $turquoise;
    color: $white;

    &:hover {
      background-color: $turquoise-2;
    }
  }

  &--color-blue {
    background-color: $blue-3;
    color: $white;

    &:hover {
      background-color: $blue-2;
    }
  }

  &--color-grey {
    background-color: $grey-2;
    color: $white;

    &:hover {
      background-color: $grey-1;
    }
  }

  &--color-orange {
    background-color: $orange;
    color: $white;

    &:hover {
      background-color: darken($orange, 10%);
    }
  }

  &--color-white {
    background-color: $white;
    color: $text;

    &:hover {
      background-color: darken($white, 10%);
    }
  }

  &:disabled {
    background-color: $grey-3;
    color: $grey-2;
    cursor: not-allowed;
    pointer-events: none;
  }
}
</style>
