<template>
  <div class="new-project-actions">
    <div>
      <button v-if="hasPrevious" @click="previous" class="btn previous nav">
        <i></i>Précédent
      </button>
    </div>
    <div>
      <p v-if="errorMessage" class="error">{{ errorMessage }}</p>
    </div>
    <div>
      <button
        v-if="hasNext"
        @click="next"
        class="btn next nav"
        :class="{ disabled: submitting || !isValid }"
      >
        {{ nextButtonText }}
      </button>
    </div>
  </div>
</template>

<script setup>
import { defineEmits, defineProps } from 'vue';

const emit = defineEmits(['next', 'previous']);
const previous = () => {
  emit('previous');
};
const next = () => {
  emit('next');
};

defineProps({
  isValid: {
    type: Boolean,
    default: false
  },
  submitting: {
    type: Boolean,
    default: false
  },
  errorMessage: {
    type: String,
    default: null
  },
  hasPrevious: {
    type: Boolean,
    default: false
  },
  hasNext: {
    type: Boolean,
    default: false
  },
  nextButtonText: {
    type: String,
    default: 'Suivant'
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';

.new-project-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: space-between;
}

.btn {
  padding: 0.5rem 1rem;
}

.previous i {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-left: 2px solid white;
  border-bottom: 2px solid white;
  margin-right: 0.5rem;
  @include rotate(45);
}

.error {
  color: $alert;
}
</style>
