<template>
  <div v-if="projects === null">
    <div class="loader">
      <spinner-loader color="#88BEDC"></spinner-loader>
    </div>
  </div>
  <div v-else-if="projects.length === 0">
    <div class="no-projects">Vous n'avez pas encore de projets.</div>
  </div>
  <div v-else class="sidenav-projects">
    <ul>
      <projectItem
        v-for="(project, index) in orderedProjects"
        :key="'project_' + project.identifier"
        :index="projects.length - index"
        :project="project"
      />
    </ul>
  </div>
</template>

<script setup>
import ProjectItem from './ProjectItem';
import { computed, onMounted } from 'vue';
import { mapState } from '@/store/mappers';
import { refreshNav } from '@/composables/navigation';
import SpinnerLoader from 'vue-spinner/src/ScaleLoader.vue';
import { orderBy } from 'lodash/collection';

const { projects } = mapState('project');
onMounted(refreshNav);

const orderedProjects = computed(() => {
  return orderBy(projects.value, ['name'], ['asc']);
});
</script>

<style lang="scss" scoped>
.sidenav-projects {
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
}

.no-projects {
  margin: 0 1rem 1rem;
}

.side-navigation.active .sidenav-projects {
  padding: 0 1rem;
}
</style>
