<template>
  <div class="title-with-button">
    <h2 class="block-title">{{ title }}</h2>
    <button :class="[buttonClasses]" @click="execute" v-if="isGranted(role)">
      <span>{{ buttonLabel }}</span> <i :class="[buttonIconClass]"></i>
    </button>
  </div>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, ref } from 'vue';
import EditButton from './EditButton';
import { isGranted } from '@/composables/security';

defineProps({
  title: String,
  buttonLabel: {
    type: String,
    default: 'Modifier'
  },
  buttonClasses: {
    type: String,
    default: 'btn action edit with-icon'
  },
  buttonIconClass: {
    type: String,
    default: 'icon-edit-page'
  },
  role: {
    type: String,
    default: 'ROLE_USER'
  }
});

const emit = defineEmits(['action']);

const execute = (event) => {
  emit('action');
};
// const edit = (event) => {
//   emit("edit", event);
// };
//
// const save = (event) => {
//   emit("save", event);
// };

// const onEnter = () => {
//   submitButton.value.editAndSave();
// };
//
// defineExpose({
//   onEnter,
// });
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';

@include device(small) {
}

@include device(large) {
}
</style>
