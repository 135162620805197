import userApi from '@/api/user';

export const activateAccount = (userId, userToken, data, callback, errorCallback) => {
  userApi.activateAccount(userId, userToken, data).then(callback).catch(errorCallback);
};

export const updateUser = (userId, data, callback, errorCallback) => {
  userApi.updateUser(userId, data).then(callback).catch(errorCallback);
};

export const updateLoggedUser = (userId, callback, errorCallback) => {
  userApi.updateLoggedUser(userId).then(callback).catch(errorCallback);
};

export const deleteLoggedUser = (data, callback, errorCallback) => {
  userApi.deleteLoggedUser(data).then(callback).catch(errorCallback);
};
