<template>
  <div v-if="project" class="block">
    <editable-title
      :title="project ? project.name : null"
      edit-role="ROLE_REFERENT"
      @save="saveName"
    >
      <template v-slot:icon>
        <i
          :style="{
            'background-color': getColor(getProjectIndexByIdentifier(project.identifier))
          }"
        >
          {{ getShortName(project.name) }}
        </i>
      </template>
      <template v-slot:subtitle>
        <p v-if="project.simulations">
          Dernière simulation
          {{ format(getLastSimulationDate(project.simulations), 'dd/MM/yyy') }}
        </p>
      </template>
    </editable-title>
  </div>
</template>

<script setup>
import { mapGetters, mapMutations, mapState } from '@/store/mappers';
import { getLastSimulationDate, getShortName, updateProject } from '@/composables/project';
import { getColor } from '@/helper/colorHelper';
import EditableTitle from '../commons/EditableTitle';
import { format } from 'date-fns';

const { project } = mapState('project');
const { getProjectIndexByIdentifier } = mapGetters('project');
const { updateCurrentProject } = mapMutations('project');

const saveName = (newName, callback) => {
  updateProject(
    project.value.identifier,
    {
      name: newName
    },
    (response) => {
      updateCurrentProject(response.data);
      callback();
    }
  );
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_layout.scss';
@import '@/assets/scss/commons/_buttons.scss';

@include device(medium) {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input {
        border: 0;
      }

      i {
        color: $white;
        font-size: 1.8rem;
        line-height: 4rem;
        text-align: center;
        font-style: normal;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        margin-right: 1rem;
        display: inline-block;
        border-radius: 50%;
        @include box-shadow(4px 0px 8px 3px rgba(0, 0, 0, 0.1));
        @include transition(all 0.2s ease-out);
      }

      div {
        width: 100%;

        .h1,
        input {
          color: $blue-1;
          text-transform: uppercase;
          font-size: 1.125rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 0.5rem;
          padding: 0.5rem;
        }

        input {
          background-color: $grey-3;
          border: 0;
          border-radius: 0;
          width: 100%;
          min-width: 250px;
        }

        p {
          color: $blue-1;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

@include device(large) {
  .title-block .title {
    width: 65%;
    max-width: 600px;
  }
}
</style>
