import stringify from 'json-stringify-deterministic';
import sha512 from 'crypto-js/sha512';
import resultApi from '../api/result';
import { has } from 'lodash';

export const getResultRepresentationHash = (representation) => {
  let asString = representation ? stringify(representation) : null;
  return asString ? sha512(asString).toString() : null;
};

export const exportData = (filters) => {
  resultApi
    .downloadChartData(filters)
    .then((response) => {
      const blob = new Blob([response.data], { type: 'text/csv' });
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.href = URL.createObjectURL(blob);
      link.download =
        'data_' + filters.simulation + '_' + filters.category + '_' + filters.criteria + '.csv'; //Gives name to file but also forces a download
      link.click();
    })
    .catch((error) => {
      this.throwError(
        'Une erreur est survenue lors du téléchargement des données. Veuillez nous contacter si cela se reproduit.',
        'Erreur téléchargement',
        has(error, 'response.data.message') ? error.response.data.message : error.message,
        error
      );
    });
};

export const getVersionKey = (project, simulation) => {
  return project.releaseId + '-' + project.cacheVersion + '-' + simulation.cacheVersion;
};
