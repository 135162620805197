import loginApi from '../../api/login';

const state = {
  user: null,
  login: null,
  jwtToken: null,
  refreshToken: null
};

const getters = {
  isAuthenticated: (state) => !!state.login && !!state.jwtToken
};

// actions
const actions = {
  async login({ commit }, credentials) {
    commit('login', credentials.username);
    return loginApi.login(credentials);
  },
  async refreshLogin({ commit, state }) {
    return loginApi.refreshLogin(state.login, state.refreshToken).then((response) => {
      commit('refreshToken', response.data.refresh);
      commit('jwtToken', response.data.token);
    });
  },
  setJwtToken({ commit }, jwtToken) {
    commit('jwtToken', jwtToken);
  },
  setRefreshToken({ commit }, refreshToken) {
    commit('refreshToken', refreshToken);
  },
  resetJwtToken({ commit }) {
    commit('jwtToken', null);
    commit('refreshToken', null);
  },
  logout({ dispatch, commit }) {
    dispatch('resetJwtToken');
    commit('login', null);
  }
};

// mutations
const mutations = {
  login(state, payload) {
    state.login = payload;
  },
  jwtToken(state, payload) {
    state.jwtToken = payload;
  },
  refreshToken(state, payload) {
    state.refreshToken = payload;
  },
  setUser(state, payload) {
    state.user = payload;
  },
  setUserOrganization(state, payload) {
    state.user.organization = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
