import { authenticatedApi as api } from './api';

export const getProject = (id) => {
  return api().get('/projects/' + id, {
    params: {
      serialization_groups: ['get', 'simulations', 'zones', 'boundaries']
    }
  });
};

export const putProject = (id, data) => {
  return api().put('/projects/' + id, data);
};

export const postProject = (data) => {
  return api().post('/projects', data);
};

export const uploadProjectZonesFile = (id, data) => {
  return api().post('/projects/' + id + '/upload-zones', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const verifyProjectZonesFile = (id) => {
  return api().get('/projects/' + id + '/verify-zones-file');
};

export const removeUploadedProjectZonesFile = (id, data) => {
  return api().put('/projects/' + id + '/upload-zones/remove-file/' + data, data);
};

export const uploadProjectSirenFile = (id, data) => {
  return api().post('/projects/' + id + '/upload-siren', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const verifyProjectSirenFile = (id) => {
  return api().get('/projects/' + id + '/verify-siren-file');
};

export const removeUploadedProjectSirenFile = (id, data) => {
  return api().put('/projects/' + id + '/upload-siren/remove-file/' + data, data);
};

export const getZones = (query) => {
  return api().get('/zones?' + new URLSearchParams(query).toString());
};

export const getSirens = () => {
  return api().get('/sirens');
};

export const getSirenFilters = () => {
  return api().get('/siren-filters');
};

export const createSimulation = (id) => {
  return api().post(`/projects/${id}/create-simulation`);
};

export const validateZones = (zones) => {
  return api().put(`/zones/verify`, zones);
};

export default {
  getProject,
  putProject,
  postProject,
  getZones,
  getSirens,
  getSirenFilters,
  createSimulation
};
