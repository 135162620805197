import { authenticatedApi as api } from './api';

export const getSections = () => {
  return api().get('/configurations/sections');
};

export const getTags = () => {
  return api().get('/configurations/tags');
};

export default {
  getSections,
  getTags
};
