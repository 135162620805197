<template>
  <input
    type="file"
    :name="'upload-file-' + uid"
    :id="'upload-file-' + uid"
    @change="handleFileChange($event)"
    style="display: none"
  />
  <button
    v-if="status === 'idle'"
    class="btn turquoise m-t"
    @click="openFileUpload()"
    :disabled="disabled"
  >
    {{ label }}
  </button>
  <div v-else-if="status === 'progress'">
    Chargement en cours...{{ fileName }} ({{ fileSize }}Mo)
  </div>
  <div v-else-if="status === 'complete'">
    Chargement terminé : {{ fileName }} ({{ fileSize }}Mo)
  </div>
  <div v-else-if="status === 'removing'">Suppression...</div>
  <div v-else-if="status === 'verifying'">Vérification...</div>
  <button v-if="status === 'complete'" @click="removeUploadedFile">Supprimer ce fichier</button>
  <div v-if="errors.length > 0">
    <div class="file-upload__error" v-for="(error, index) in errors" :key="index">
      <span>{{ error }}</span>
    </div>
  </div>
  <div v-if="serverError !== null">
    <div class="file-upload__error">
      <span>{{ serverError }}</span>
    </div>
  </div>
</template>

<script setup>
//Props
import { computed, defineProps, getCurrentInstance, onMounted, reactive, ref } from 'vue';

const emit = defineEmits(['upload-file-selected', 'upload-file-changed', 'remove-uploaded-file']);
const props = defineProps({
  label: {
    type: String,
    default: 'Uploader votre fichier'
  },
  maxSize: {
    type: Number,
    default: 50
  },
  accept: {
    type: String,
    default: 'geojson,json'
  },
  status: {
    type: String,
    default: 'idle'
  },
  disabled: {
    type: Boolean,
    default: false
  },
  serverError: {
    type: String,
    default: null
  },
  existingFileName: {
    type: String,
    default: null
  },
  existingFileOriginalName: {
    type: String,
    default: null
  },
  existingFileSize: {
    type: Number,
    default: null
  }
});

// GET UID for the component, to have unique "id" for the input
const instance = getCurrentInstance();
const uid = computed(() => {
  return instance.uid;
});

const file = reactive([]);
const fileName = ref(null);
const fileSize = ref(null);

onMounted(() => {
  if (props.existingFileName !== null) {
    file.value = new File([], props.existingFileOriginalName);
    fileName.value = props.existingFileOriginalName;
    fileSize.value = formatFileSize(props.existingFileSize);
  }
});

const formatFileSize = (size) => {
  return Math.round((size / 1024 / 1024) * 100) / 100;
};

const openFileUpload = () => {
  document.getElementById('upload-file-' + uid.value).click();
};
const removeUploadedFile = () => {
  emit('remove-uploaded-file');
};

const isFileSizeValid = computed(() => {
  return formatFileSize(file.value.size) <= props.maxSize;
});

const isFileTypeValid = computed(() => {
  return props.accept.split(',').includes(file.value.name.split('.').pop());
});

const errors = computed(() => {
  let errorsArray = [];
  if (file.value !== undefined) {
    if (!isFileTypeValid.value) {
      errorsArray.push(
        `Le type de fichier n'est pas autorisé. Vous pouvez télécharger les fichiers suivants : ${props.accept}`
      );
    }
    if (!isFileSizeValid.value) {
      errorsArray.push(`La taille maximale autorisée pour un fichier est de ${props.maxSize} Mo`);
    }
  }
  return errorsArray;
});

const isFileValid = computed(() => {
  return isFileTypeValid.value && isFileSizeValid.value;
});

const handleFileChange = (e) => {
  errors.value = [];
  if (e.target.files && e.target.files[0]) {
    emit('upload-file-selected', e.target.files[0]);
    file.value = e.target.files[0];
    if (isFileValid.value) {
      file.value = e.target.files[0];
      fileSize.value = formatFileSize(file.value.size);
      fileName.value = file.value.name.split('.').shift() + '.' + file.value.name.split('.').pop();

      let formData = new FormData();
      formData.append('file', file.value);
      emit('upload-file-changed', formData);
    } else {
      console.log('Invalid file');
    }
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';
</style>
