<template>
  <dashboard-map-block :projects="projects" v-if="projects" />
</template>

<script setup>
import DashboardMapBlock from '../components/dashboard/DashboardMapBlock';
import { mapState } from '@/store/mappers';

const { projects } = mapState('project');
</script>

<style lang="scss"></style>
