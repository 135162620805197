<template>
  <div class="new-project">
    <notification
      v-for="(notification, index) in notifications"
      :key="'notification_' + notification.id"
      :index="notifications.length - index"
      :notification="notification"
    >
    </notification>
  </div>
</template>

<script setup>
import { mapMutations, mapState } from '@/store/mappers';
import notificationApi from '@/api/notification';
import { onMounted } from 'vue';
import Notification from '@/components/notification/Notification.vue';

const { notifications } = mapState('notifications');
const { setNotifications } = mapMutations('notifications');

//Lifecycle
const load = () => {
  notificationApi.getNotifications().then((response) => {
    setNotifications(response.data);
  });
};
onMounted(() => {
  load();
});
</script>

<style lang="scss"></style>
