<template>
  <div class="avatar-bloc">
    <div class="avatar" :style="{ backgroundImage: 'url(' + avatarSrc + ')' }">
      <i v-if="uploadStatus === 'progress'" class="loader"></i>
      <span v-else-if="!avatar && uploadStatus !== 'progress'">{{ initials }}</span>
      <div class="upload" v-if="isGranted(role)">
        <simple-upload
          label="Modifier"
          accept="jpeg,jpg,png"
          :display-error="false"
          :status="uploadStatus"
          :server-error="uploadError"
          @upload-file-selected="onFileSelection"
          @upload-file-error="onFileError"
          @upload-file-submitted="submitUploadedFile"
          @upload-file-complete="onUploadComplete"
        />
      </div>
    </div>
    <button v-if="avatar && isGranted(role)" class="btn action" @click="emit('remove-avatar')">
      <i class="icon-bin"></i>
    </button>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import SimpleUpload from '@/components/commons/SimpleUpload.vue';
import { isGranted } from '@/composables/security';

const emit = defineEmits(['upload-avatar', 'remove-avatar', 'upload-error']);

const apiURL = process.env.VUE_APP_API_URL;
// const emit = defineEmits(['close'])
const props = defineProps({
  role: {
    type: String,
    default: 'ROLE_USER'
  },
  uploadStatus: {
    type: String,
    default: 'idle'
  },
  uploadError: {
    type: String,
    default: null
  },
  initials: {
    type: String,
    default: null
  },
  avatar: {
    type: Object,
    default: null
  },
  onUpload: {
    type: Function,
    default: null
  }
});

const cleanApiURL = computed(() => {
  if (null !== apiURL && apiURL.charAt(apiURL.length - 1) === '/') {
    return apiURL.substr(0, apiURL.length - 1);
  }
  return apiURL;
});

const avatarSrc = computed(() => {
  if (null === apiURL || null === props.avatar) {
    return null;
  }
  if (props.avatar.identifier === 'logo') {
    return (
      cleanApiURL.value +
      '/medias/organizations/' +
      props.avatar.resourceIdentifier +
      '/avatar/' +
      props.avatar.originalName
    );
  }
  return (
    cleanApiURL.value +
    '/medias/users/' +
    props.avatar.resourceIdentifier +
    '/avatar/' +
    props.avatar.originalName
  );
});

const onFileSelection = () => {
  console.log('onFileSelection');
};

const onFileError = (error) => {
  if (error === 'invalid') {
    emit(
      'upload-error',
      "Le type de fichier n'est pas accepté. Les formats acceptés sont : jpeg,jpg,png"
    );
  } else {
    emit('upload-error', 'Une erreur est survenue');
  }
};

const submitUploadedFile = (formData) => {
  emit('upload-avatar', formData);
};

const onUploadComplete = () => {
  // console.log("upload complete");
  // uploadStatus.value = "removing";
  // removeUploadedProjectZonesFile(
  //   props.projectData.id,
  //   props.projectData.zonesCustomFile
  // ).then((response) => {
  //   uploadStatus.value = "idle";
  //   updateCurrentProject(response.data);
  // });
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';

.avatar-bloc {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.avatar {
  width: 112px;
  height: 112px;
  border-radius: 100%;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $turquoise;
  //cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  span {
    font-size: 3.75rem;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    color: white;
  }

  .upload {
    display: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &:hover {
    .upload {
      display: block;
    }

    &::before {
      background: rgba(0, 0, 0, 0.5);
    }
  }

  :deep(input[type='file']) {
    display: none;
  }
}

.loader {
  width: 24px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid $turquoise;
  border-right-color: $white;
  animation: l2 1s infinite ease-in-out;
  margin: 0;
}

@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
</style>
