<template>
  <div v-if="project" class="block consultations">
    <div class="consultations-title">
      <div>
        <h2 class="block-title">Consultations des simulations réalisées</h2>
        <p v-if="simulationCount">
          Il y a {{ simulationCount }}
          <span v-if="simulationCount > 1">simulations disponibles</span
          ><span v-else>simulation disponible</span> :
        </p>
      </div>
      <router-link
        :to="{
          name: 'newSimulation',
          params: {
            projectIdentifier: project.identifier
          }
        }"
        class="btn green-2"
        v-if="isGranted('ROLE_REFERENT')"
      >
        Créer une nouvelle simulation
      </router-link>
    </div>
    <ul class="carret-list">
      <li v-for="simulation in project.simulations" :key="simulation.identifier">
        <router-link
          :to="{
            name: 'simulation',
            params: {
              simulationIdentifier: simulation.identifier,
              projectIdentifier: project.identifier
            }
          }"
        >
          <p class="block-title">{{ simulation.name }}</p>
          <p class="simulation-description">{{ simulation.description }}</p>
          <p class="dates">Crée le {{ formatDate(simulation.createdAt) }} / modifiée le {{ formatDate(simulation.updatedAt) }}</p>
        </router-link>
        <div>
          <div class="simulation-icon-list" v-if="isGranted('ROLE_ADMIN')">
            <tooltip>
              <i class="icon-city"></i>
              <template #title>Mutualisation des flux</template>
              <template #text>Ceci est un icône mutualisation des flux</template>
            </tooltip>
            <tooltip>
              <i class="icon-phone-cart"></i>
              <template #title>Pratiques consommateurs</template>
              <template #text>Ceci est un icône pratiques consommateurs</template>
            </tooltip>
          </div>
          <div class="action-buttons">
            <router-link
              :to="{
                name: 'simulation',
                params: {
                  simulationIdentifier: simulation.identifier,
                  projectIdentifier: project.identifier
                }
              }"
              class="btn tertiary with-icon"
            >
              Voir la simulation<i class="icon-eye"></i>
            </router-link>
            <button
              :class="{
                confirm: confirmDeleteSimulation.includes(simulation.identifier)
              }"
              class="btn action delete"
              v-if="isGranted('ROLE_REFERENT') && isGranted('ROLE_ADMIN')"
              @click="deleteSimulation(simulation.identifier)"
            >
              <span>Supprimer la simulation</span> <i class="icon-delete"></i>
            </button>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import Tooltip from '../commons/Tooltip';
import { mapState } from '@/store/mappers';
import { computed, ref } from 'vue';
import remove from 'lodash/remove';
import { isGranted } from '@/composables/security';
import Notifications from '@/components/sideNavigation/Notifications.vue';
import {formatDate} from "../../helper/formatHelper";

const { project } = mapState('project');
const confirmDeleteSimulation = ref([]);
const deleteSimulation = (id) => {
  if (confirmDeleteSimulation.value.includes(id)) {
    //TODO: call delete
    remove(confirmDeleteSimulation.value, (confirm) => confirm === id);
  } else {
    confirmDeleteSimulation.value.push(id);
  }
};

const simulationCount = computed(() => {
  return project ? project.value.simulations.length : null;
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';

@include device(small) {
  p.dates {
    font-size: 11px;
  }
  p.simulation-description {
    margin-bottom: 4px;
  }
  .consultations {
    .consultations-title {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      p {
        color: $blue-1;
      }
    }

    ul.carret-list {
      li:before {
        top: 1.6rem;
      }

      a {
        text-decoration: none;

        p {
          color: $blue-1;
        }
      }

      .block-title {
        color: $blue-2;
        margin: 0;
      }

      .simulation-icon-list {
        font-size: 2.5rem;

        i {
          margin: 0 0.635rem;
        }
      }

      .action-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        position: relative;

        .with-icon {
          padding: 0.5rem;
          margin-right: 0.5rem;
          line-height: 1.5rem;
        }

        .btn.action {
          min-height: 2.5rem;
          max-height: 2.5rem;
          width: 2.5rem;
          @include transition(width 0.5s ease-out);
          position: relative;

          &.confirm {
            width: 14rem;
          }
        }
      }
    }
  }
}

@include device(large) {
  .consultations {
    ul.carret-list {
      li {
        display: flex;
        justify-content: space-between;
        align-content: center;

        > div {
          display: flex;
        }

        .simulation-icon-list {
          display: flex;
          align-items: center;
          margin-right: 2rem;
        }

        .action-buttons {
          min-width: 14rem;

          .with-icon {
            margin-right: 3rem;
          }

          .btn.action {
            position: absolute;
            right: 0;

            &.confirm {
              width: 14rem;
            }
          }
        }
      }
    }
  }
}
</style>
