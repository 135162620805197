<template>
  <div
    :class="{ active: showIdentifiers }"
    class="border-select large"
    @click="showIdentifiers = !showIdentifiers"
  >
    <div class="border-select-title">
      <p :class="{ unselected: filter.identifier === null }" class="label">
        {{ getAvailableLabel(filter.identifier) || 'Choisir un type de filtre...' }}
      </p>
      <i class="icon-small-carret"></i>
    </div>
    <div v-click-away="onClickAwayIdentifiers" class="border-select-content">
      <ul>
        <li
          v-for="filter in available"
          :key="filter.identifier"
          @click="updateIdentifier(filter.identifier)"
        >
          {{ filter.label }}
        </li>
      </ul>
    </div>
  </div>
  <div
    :class="{
      active: showOperators,
      disabled: filter.identifier === null || (forcedOperator !== null && !isFirstIdentifier)
    }"
    class="border-select select-exclude"
    @click="showOperators = !showOperators"
  >
    <div class="border-select-title">
      <p class="label">
        {{
          forcedOperator !== null
            ? forcedOperator
              ? "N'est pas"
              : 'Est'
            : filter.exclude
            ? "N'est pas"
            : 'Est'
        }}
      </p>
      <i class="icon-small-carret"></i>
    </div>
    <div v-click-away="onClickAwayOperators" class="border-select-content">
      <ul>
        <li @click="updateExclude(false)">Est</li>
        <li @click="updateExclude(true)">N'est pas</li>
      </ul>
    </div>
  </div>
  <div
    :class="{ active: showValues, disabled: filter.identifier === null }"
    class="border-select false large"
    @click="showValues = !showValues"
  >
    <div class="border-select-title">
      <p :class="{ unselected: filter.value === null }" class="label">
        {{ getValueLabel(filter.identifier, filter.value) || 'Choisir une valeur...' }}
      </p>
      <i class="icon-small-carret"></i>
    </div>
    <div v-click-away="onClickAwayValues" class="border-select-content">
      <ul>
        <li
          v-for="value in getAvailableValues(filter.identifier)"
          :key="value.value"
          :class="{
            disabled: alreadyUsedValueForIdentifier(value.value)
          }"
          @click="updateValue(value.value)"
        >
          {{ value.label }}
        </li>
      </ul>
    </div>
  </div>
  <button class="close" @click="remove"></button>
</template>

<script setup>
import { computed, defineEmits, defineProps, nextTick, ref } from 'vue';
import { clone, findIndex } from 'lodash';

const props = defineProps({
  available: {
    type: Object,
    default: null
  },
  usedValues: {
    type: Array,
    default: null
  },
  filter: {
    type: Object,
    default: null
  },
  others: {
    type: Array,
    default: null
  },
  index: {
    type: Number,
    required: true
  },
  forcedOperators: {
    type: Object,
    default: null
  }
});

const emit = defineEmits(['update', 'remove']);

const showIdentifiers = ref(false);
const showOperators = ref(false);
const showValues = ref(false);

const forcedOperator = computed(() => {
  return props.forcedOperators[props.filter.identifier] !== undefined
    ? props.forcedOperators[props.filter.identifier]
    : null;
});

const firstIdentifierIndex = computed(() => {
  const index = findIndex(props.others, (filter) => {
    return props.filter.identifier !== null && filter.identifier === props.filter.identifier;
  });
  return index !== undefined ? index : null;
});

const isFirstIdentifier = computed(() => {
  return firstIdentifierIndex.value === props.index;
});

const onClickAwayIdentifiers = (event) => {
  const target = event.target;
  if (!target.classList.contains('label')) {
    showIdentifiers.value = false;
  }
};
const onClickAwayValues = (event) => {
  const target = event.target;
  if (!target.classList.contains('label')) {
    showValues.value = false;
  }
};
const onClickAwayOperators = (event) => {
  const target = event.target;
  if (!target.classList.contains('label')) {
    showOperators.value = false;
  }
};

const updateIdentifier = (newIdentifier) => {
  let newFilter = clone(props.filter);
  newFilter.identifier = newIdentifier;
  newFilter.value = null;
  update(newFilter);
};
const updateValue = (newValue) => {
  let newFilter = clone(props.filter);
  newFilter.value = newValue;
  update(newFilter);
};
const updateExclude = (newExclude) => {
  let newFilter = clone(props.filter);
  newFilter.exclude = newExclude;
  update(newFilter);
};

const update = (newFilter) => {
  nextTick(() => {
    emit('update', {
      value: newFilter,
      index: props.index,
      isFirstIdentifier: isFirstIdentifier.value
    });
  });
};

const getAvailableFilter = (identifier) => {
  return props.available?.find((filter) => filter.identifier === identifier);
};
const getAvailableLabel = (identifier) => {
  let filter = getAvailableFilter(identifier);
  return filter ? filter.label : null;
};
const getAvailableValues = (identifier) => {
  let filter = getAvailableFilter(identifier);
  return filter ? filter.values : [];
};
const getValueLabel = (identifier, value) => {
  const filter = getAvailableFilter(identifier);
  if (filter) {
    const filterValue = filter.values.find((availableValue) => availableValue.value === value);
    return filterValue ? filterValue.label : null;
  }
  return null;
};
const alreadyUsedValueForIdentifier = (value) => {
  return props.usedValues?.includes(value);
};

const remove = () => {
  emit('remove', props.index);
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.close {
  width: 1.2rem;
  height: 1.2rem;
  display: block;
  cursor: pointer;
  margin-left: 0.5rem;
  border: 0;
  background-size: 100%;
  background-color: transparent;
  background-image: url('~@/assets/img/icons/close.svg');
}

.border-select {
  font-size: 0.875rem;
  padding: 0.5rem 2rem 0.5rem 1rem;
  margin: 0 0.5rem 0 0;
  border: 1px solid $blue-1-disabled;
  border-radius: 1.5rem;
  cursor: pointer;
  position: relative;

  &.large {
    min-width: 20rem;
  }

  &.select-exclude {
    min-width: 8rem;
  }

  &:hover {
    background-color: #ecece6;
    cursor: pointer;
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .border-select-title {
    p {
      font-weight: 700;
      margin: 0 0 0 0.5rem;

      &.unselected {
        color: #6c6c6c;
      }
    }

    i {
      background-size: 100%;
      position: absolute;
      right: 17px;
      top: 10px;
      @include rotate(90);
      &:before {
        font-size: 0.8rem;
      }
    }
  }

  .border-select-content {
    width: calc(100% + 2px);
    background-color: #ecece6;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.2s ease-out;
    position: absolute;
    top: 100%;
    left: -1px;
    z-index: 2;

    ul {
      list-style: none;
      margin: 0;
      padding: 0 0 1rem 0;

      li {
        font-size: 0.875rem;
        padding: 0.5rem;
        border-bottom: 1px solid $blue-3;

        &:last-child {
          border-bottom: 0;
        }

        &:hover {
          background-color: darken(#ecece6, 10%);
        }

        &.disabled {
          pointer-events: none;
          color: #a0a0a0;
        }
      }
    }
  }

  &.active {
    border-bottom: 0;
    border-radius: 1.5rem 1.5rem 0 0;
    background-color: #ecece6;

    .border-select-content {
      overflow: scroll;
      border: 1px solid $blue-1-disabled;
      border-top: 0;
      border-radius: 0 0 1.5rem 1.5rem;
      padding: 0.5rem 1rem;
      display: block;
      max-height: 20rem;
      @include box-shadow(0 0.25rem 0.25rem 0 rgba(0, 0, 0, 0.25));

      &::-webkit-scrollbar {
        display: none;
      }
    }

    i {
      @include rotate(-90);
    }
  }
}
</style>
