import { createApp } from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import VueClickAway from 'vue3-click-away';
import { i18n } from './i18n';
import VueTippy from 'vue-tippy';
import 'tippy.js/dist/tippy.css';
import './assets/scss/app.scss';
import Toast from 'vue-toastification';
import 'vue-toastification/dist/index.css';

createApp(App)
  .use(store)
  .use(router)
  .use(VueClickAway)
  .use(i18n)
  .use(VueTippy)
  .use(Toast)
  .mount('#app');
