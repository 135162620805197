<template>
  <div>
    <ul class="filters-list">
      <li
        v-for="tag in available"
        :key="tag"
        :class="{ active: isActiveTag(tag) }"
        class="filter"
        @click="toggle(tag)"
      >
        <div>
          <i></i>
          <p>{{ getTagLabel(tag) }}</p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, defineProps } from 'vue';
import { find } from 'lodash';
import { mapGetters, mapMutations } from '../../store/mappers';
import { getResultRepresentationHash } from '../../composables/result';

const { getTagsForSection } = mapGetters('tags', 'function');
const { toggleTag } = mapMutations('tags');

const props = defineProps({
  available: {
    type: Array,
    default: null
  },
  representation: {
    type: Object,
    default: null
  },
  labels: {
    type: Object,
    default: null
  }
});

const representationHash = computed(() => {
  return getResultRepresentationHash(props.representation);
});

const activeTags = computed(() => {
  return getTagsForSection(representationHash.value);
});

const isActiveTag = (value) => {
  return activeTags.value.includes(value);
};

const getTagLabel = (value) => {
  if (props.labels) {
    let tagInfo = find(props.labels, (info, tag) => {
      return tag === value;
    });
    return tagInfo.label ? tagInfo.label : value;
  }
  return value;
};

const toggle = (value) => {
  toggleTag({ representation: representationHash.value, value });
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

ul.filters-list {
  list-style-type: none;
  padding: 0;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  justify-content: flex-start;

  li.filter {
    div {
      display: flex;
      align-items: center;
      padding: 0.5rem 1rem;
      border: 1px solid $blue-1-disabled;
      border-radius: 1.5rem;
      margin: 0 0.5rem 0.5rem 0;
      cursor: pointer;
    }

    i {
      width: 1rem;
      height: 1rem;
      display: block;
      border: 1px solid $blue-1-disabled;
      border-radius: 0.313rem;
      margin-right: 0.5rem;
    }

    p {
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0;
    }

    &:hover div {
      background-color: rgba($grey-3, 0.5);

      i {
        background-color: white;
      }
    }

    &.active {
      div {
        background-color: $blue-1-disabled;

        i {
          border-color: $white;
          position: relative;

          &:after {
            content: '';
            color: white;
            display: block;
            width: 10px;
            height: 4px;
            border-bottom: 2px solid $white;
            border-left: 2px solid $white;
            position: absolute;
            left: 19%;
            top: 11%;
            transform: rotate(-46deg);
          }
        }

        p {
          color: $white;
        }
      }

      &:hover div {
        i {
          background-color: transparent;
        }
      }
    }
  }
}
</style>
