<template>
  <div class="title-with-button">
    <h2 class="block-title">{{ title }}</h2>
    <edit-button
      ref="submitButton"
      @edit="edit"
      @save="save"
      v-if="isGranted(editRole)"
    ></edit-button>
  </div>
</template>

<script setup>
import { defineEmits, defineExpose, defineProps, ref } from 'vue';
import EditButton from './EditButton';
import { isGranted } from '@/composables/security';

const submitButton = ref(null);

defineProps({
  title: String,
  editRole: {
    type: String,
    default: 'ROLE_USER'
  }
});

const emit = defineEmits(['edit', 'save']);

const edit = (event) => {
  emit('edit', event);
};

const save = (event) => {
  emit('save', event);
};

const onEnter = () => {
  submitButton.value.editAndSave();
};

defineExpose({
  onEnter
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';

@include device(small) {
}

@include device(large) {
}
</style>
