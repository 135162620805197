<template>
  <div v-if="!multiple" class="fullscreen">
    <button @click="fullscreen" class="hide-for-jpeg btn tertiary with-icon">
      <span>Plein écran</span> <i class="icon-expand"></i>
    </button>
    <button
      @click="downloadJpeg"
      class="hide-for-jpeg btn tertiary with-icon"
      style="margin-left: 1rem"
    >
      <span>Télécharger</span> <i class="icon-download"></i>
    </button>
    <button
      @click="toggleValuesDisplay"
      class="hide-for-jpeg btn tertiary with-icon"
      style="margin-left: 1rem"
      v-if="supportsToggleValuesDisplay"
    >
      <span v-if="displayRelativeValues === true">Valeurs absolues</span>
      <span v-else>Valeurs relatives</span>
      <i v-if="displayRelativeValues === true" class="icon-bars"></i>
      <i v-else class="icon-percentage"></i>
    </button>
  </div>
  <canvas :id="canvasId" :style="{ 'max-height': CHART_MAX_HEIGHT }"></canvas>
</template>

<script setup>
import { computed, nextTick, onBeforeUnmount, ref, shallowRef, watch, watchEffect } from 'vue';
import 'leaflet/dist/leaflet.css';
import { colors, getColor } from '@/helper/colorHelper';
import { floor, isNumber, merge } from 'lodash';
import Chart from 'chart.js/auto';
import domtoimage from 'dom-to-image-more';
import { unobserve } from '@/composables/utils';
import { round } from 'lodash/math';

const MIN_BUBBLE_RADIUS = 10;
const MAX_BUBBLE_RADIUS = 40;
const PIE_RADIUS = '95%';
const CHART_MAX_HEIGHT = '700px';

const props = defineProps({
  category: String,
  resultIndex: Number,
  data: {
    type: Object,
    default: null
  },
  multiple: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['legendDefined']);

// ---------------------------------------------------------------------------------------
// Reference values
// ---------------------------------------------------------------------------------------
const chartInstance = shallowRef(null); //holds the Chart.js instance
const chartConfig = ref(null);
const displayRelativeValues = ref(false);

// --------------------------------------------------------------------------------------
// Computed values
// --------------------------------------------------------------------------------------
const canvasId = computed(() => {
  return 'canvas_' + props.category + props.resultIndex;
});

// Calculate totals for single datasets charts
const datasetsTotals = computed(() => {
  let totals = [];
  props.data.datasets.forEach(function (dataset, index) {
    let totalValue = 0;
    dataset.data.forEach(function (data) {
      totalValue += data.y;
    });
    totals[index] = totalValue;
  });
  return totals;
});

// Calculate totals for multiple/stacked datasets charts
const datasetsStackedTotals = computed(() => {
  let totals = [];
  props.data.datasets.forEach(function (dataset, datasetIndex) {
    dataset.data.forEach(function (data, index) {
      if (totals[data.x] === undefined) {
        totals[data.x] = data.y;
      } else {
        totals[data.x] += data.y;
      }
    });
  });
  return totals;
});

const supportsToggleValuesDisplay = computed(() => {
  return props.data.chartType === 'bar' || props.data.chartType === 'pie';
});

const hasMultipleDatasets = computed(() => {
  return props.data.datasets.length > 1;
});

const stacked = computed(() => {
  return displayRelativeValues.value === true && hasMultipleDatasets.value === true;
});

// ---------------------------------------------------------------------------------------
// Main methods
// ---------------------------------------------------------------------------------------
const setupChart = () => {
  if (chartInstance.value !== null) {
    chartInstance.value.clear();
    chartInstance.value.type = chartConfig.value.type;
    chartInstance.value.data = chartConfig.value.data;
    chartInstance.value.options = chartConfig.value.options;
    chartInstance.value.update();
  } else {
    chartInstance.value = new Chart(
      document.getElementById(canvasId.value).getContext('2d'),
      chartConfig.value
    );

    // emit("legendDefined", createLegend());
  }
};

const createLegend = (colors, units) => {
  let legend = [];
  chartInstance.value.legend.legendItems.forEach(function (item, itemIndex) {
    const value = item.text;
    const color = item.fillStyle;
    const index = itemIndex;
    legend.push({ value, color, index });
    // legend.push({item.text, item.fillStyle });
  });
  //
  // for (let i = 0; i < jenksClasses.length - 1; i++) {
  //   const value = jenksClasses[i] + " - " + jenksClasses[i + 1] + " " + units;
  //   const color = colors[i];
  //   legend.push({ value, color });
  // }

  return legend;
};

//Adapt data to required config for Chart.js
const parseChartData = (apiData) => {
  if (apiData === null) {
    return null;
  }
  let chartData = {};
  if (true === displayRelativeValues.value) {
    chartData.type = hasMultipleDatasets.value === true ? apiData.chartType : 'pie';
  } else {
    chartData.type = apiData.chartType;
  }
  chartData.responsive = true;
  chartData.maintainAspectRatio = false;

  chartData.data = {
    datasets: apiData.datasets.map((dataset, index) => {
      let newDataset = unobserve(dataset); //Unobserve needed to not mutate original props
      newDataset.backgroundColor = getColor(index, 0.6, props.resultIndex);
      newDataset.maintainAspectRatio = false;
      newDataset.responsive = true;

      //Adapt values for certain charts
      switch (chartData.type) {
        case 'bar':
          if (stacked.value === true) {
            newDataset.data = adaptStackedBarChartData(newDataset.data);
          }
          break;
        case 'bubble':
          newDataset.data = adaptBubbleChartDataRadius(newDataset.data);
          break;
        case 'pie':
          newDataset.data = adaptPieChartData(newDataset.data);
          newDataset.hoverOffset = 15;
          newDataset.maintainAspectRatio = false;
          newDataset.responsive = true;
          newDataset.radius = PIE_RADIUS;
          newDataset.backgroundColor = colors;
          break;
        default:
          break;
      }
      return newDataset;
    })
  };

  let newOptions = unobserve(apiData.options); //Unobserve needed to not mutate original props
  switch (chartData.type) {
    case 'bubble':
      chartData.options = merge(newOptions, {
        plugins: {
          tooltip: {
            callbacks: {
              label: handleBubbleTooltipLabels
            }
          }
        },
        scales: handleScaleTicksFormatting(newOptions)
      });
      break;
    case 'bar':
      chartData.options = merge(newOptions, {
        plugins: {
          tooltip: {
            callbacks: {
              label: handleBarTooltipLabels,
              title: handleTooltipTitle
            }
          }
        },
        scales: handleScaleTicksFormatting(newOptions)
      });
      chartData.options.scales.x.display = true;
      chartData.options.scales.y.display = true;
      break;
    case 'line':
      chartData.options = merge(newOptions, {
        plugins: {
          tooltip: {
            callbacks: {
              label: handleLineTooltipLabels
            }
          }
        },
        scales: handleScaleTicksFormatting(newOptions)
      });
      break;
    case 'pie':
      chartData.data.labels = extractLabels(0);
      chartData.options = merge(newOptions, {
        plugins: {
          tooltip: {
            callbacks: {
              label: handlePieTooltipLabels,
              title: handlePieTooltipTitle
            }
          }
        }
      });
      chartData.options.scales.x.display = false;
      chartData.options.scales.y.display = false;
      break;
    default:
      chartData.options = newOptions;
      break;
  }

  return chartData;
};

// ---------------------------------------------------------------------------------------
// Tools methods
// ---------------------------------------------------------------------------------------
// Returns an array of labels from props data
const extractLabels = (datasetIndex) => {
  let labels = [];
  props.data.datasets[datasetIndex].data.forEach(function (data, index) {
    labels[index] = data.x;
  });
  return labels;
};

const toggleValuesDisplay = () => {
  displayRelativeValues.value = !displayRelativeValues.value;
};

const adaptPieChartData = (data) => {
  return data.map((dataPoint) => {
    return dataPoint.y;
  });
};

const adaptStackedBarChartData = (data) => {
  return data.map((dataPoint, index) => {
    let total = datasetsStackedTotals.value[dataPoint.x];
    dataPoint.y = floor((100 * dataPoint.y) / total, 2);
    return dataPoint;
  });
};

const adaptBubbleChartDataRadius = (data) => {
  const minR = data.reduce((min, dataPoint) => {
    if (min === null) {
      return dataPoint.r;
    } else {
      return dataPoint.r < min ? dataPoint.r : min;
    }
  }, null);
  const maxR = data.reduce((max, dataPoint) => {
    return parseFloat(dataPoint.r) > max ? parseFloat(dataPoint.r) : max;
  }, 0.0);
  return data.map((dataPoint) => {
    if (dataPoint.r_orig === undefined) {
      dataPoint.r_orig = dataPoint.r;
    }
    dataPoint.r = scaleBubbleRadius(data, dataPoint.r, minR, maxR);
    return dataPoint;
  });
};

const scaleBubbleRadius = (data, r, min, max) => {
  /**
   *        (b-a)(x - min)
   * f(x) = --------------  + a
   *           max - min
   */

  return ((MAX_BUBBLE_RADIUS - MIN_BUBBLE_RADIUS) * (r - min)) / (max - min) + MIN_BUBBLE_RADIUS;
};

const handleTooltipTitle = (tooltipItems) => {
  let title = props.data.datasets[tooltipItems[0].datasetIndex].data.filter(function (data, index) {
    return index === tooltipItems[0].dataIndex;
  });
  return title[0].x + ', ' + tooltipItems[0].dataset.label;
};

const handlePieTooltipTitle = (tooltipItems) => {
  let title = props.data.datasets[tooltipItems[0].datasetIndex].data.filter(function (data, index) {
    return index === tooltipItems[0].dataIndex;
  });
  return title[0].x;
};

const handleBarTooltipLabels = (context) => {
  if (true === stacked.value) {
    return getTooltipLabelForStackedScale(context, 'y', null);
  }
  return getTooltipLabelForScale(context, 'y', null);
};

const handlePieTooltipLabels = (context) => {
  let units = context.chart.config.options?.scales['y']?.units;
  let title = context.chart.config.options?.scales['y']?.title?.text;
  if (title === undefined || title === '') {
    title = context.dataset.label;
  }
  let value = context.raw;

  return (
    ' ' +
    title +
    ' : ' +
    getPercentageValue(context, value) +
    '% (' +
    parseFloat(value).toLocaleString('fr') +
    (units !== undefined && units !== null ? ' ' + units : '') +
    ')'
  );
};

const handleLineTooltipLabels = (context) => {
  return [getTooltipLabelForScale(context, 'y')];
};

const handleBubbleTooltipLabels = (context) => {
  return [
    context.raw.label,
    getTooltipLabelForScale(context, 'x'),
    getTooltipLabelForScale(context, 'y'),
    getTooltipLabelForScale(context, '_custom', 'r_orig')
  ];
};

const getTooltipLabelForStackedScale = (context, scaleIdentifier = 'y') => {
  let units = context.chart.config.options?.scales[scaleIdentifier]?.units;
  let title = context.chart.config.options?.scales[scaleIdentifier]?.title?.text;
  if (title === undefined || title === '') {
    title = context.dataset.label;
  }
  let value = context.raw[scaleIdentifier];
  return (
    title +
    ' : ' +
    parseFloat(value).toLocaleString('fr') +
    '%' +
    ' (' +
    getOriginalValue(context.datasetIndex, context.dataIndex) +
    (units !== undefined && units !== null ? ' ' + units : '') +
    ')'
  );
};

const getTooltipLabelForScale = (context, scaleIdentifier, valueIdentifier = null) => {
  let units = context.chart.config.options?.scales[scaleIdentifier]?.units;
  let title = context.chart.config.options?.scales[scaleIdentifier]?.title?.text;
  if (title === undefined || title === '') {
    title = context.dataset.label;
  }

  let value = valueIdentifier ? context.raw[valueIdentifier] : context.raw[scaleIdentifier];

  let percent = getPercentageValue(context, value);
  percent = percent !== '' ? '(' + percent + '%)' : '';

  return (
    title +
    ' : ' +
    parseFloat(value).toLocaleString('fr') +
    (units !== undefined && units !== null ? ' ' + units : '') +
    ' ' +
    percent
  );
};

const handleScaleTicksFormatting = (options) => {
  const scales =
    options !== null && options.scales !== undefined ? Object.keys(options.scales) : [];
  let scalesTicksConfig = {};
  scales.forEach((identifier) => {
    let units = options.scales[identifier]?.units;
    if (stacked.value === true && identifier === 'y') {
      units = '%';
    }

    const type = options.scales[identifier]?.type;
    if (units || type === 'linear') {
      scalesTicksConfig[identifier] = {
        ticks: {
          callback: (value) => {
            return (
              (isNumber(value) && type !== 'category' ? value.toLocaleString('fr') : value) +
              (units ? ' ' + units : '')
            );
          }
        }
      };
    }
  });

  return handleStackedScaleTicksConfig(scalesTicksConfig);
};

// Add or remove stacked options to scales configuration (for bar chart)
const handleStackedScaleTicksConfig = (scalesConfig) => {
  if (stacked.value === true) {
    if (scalesConfig.y === undefined) {
      scalesConfig.y = {
        stacked: true
      };
    } else {
      scalesConfig.y.stacked = true;
    }
    if (scalesConfig.x === undefined) {
      scalesConfig.x = {
        stacked: true
      };
    } else {
      scalesConfig.x.stacked = true;
    }
  } else {
    if (scalesConfig.y !== undefined) {
      delete scalesConfig.y.stacked;
    }
    if (scalesConfig.x !== undefined) {
      delete scalesConfig.x.stacked;
    }
  }

  return scalesConfig;
};

const getPercentageValue = (context, value) => {
  let percent = '';
  if (context.chart.config.type === 'bar' || context.chart.config.type === 'pie') {
    if (stacked.value === true || hasMultipleDatasets.value === true) {
      percent = round((100 * value) / datasetsStackedTotals.value[context.label], 2);
    } else {
      percent = round((100 * value) / datasetsTotals.value[context.datasetIndex], 2);
    }
  }
  return percent;
};

const getOriginalValue = (datasetIndex, dataIndex, scaleIdentifier = 'y') => {
  return props.data.datasets[datasetIndex].data[dataIndex][scaleIdentifier];
};

const fullscreen = () => {
  const canvas = document.getElementById(canvasId.value);
  canvas.requestFullscreen();
};

const downloadJpeg = () => {
  function filter(node) {
    if (typeof node.className !== 'undefined') {
      return !node.className.match('hide-for-jpeg');
    }
    return true;
  }

  let node = document.getElementById('jpeg-download-zone');
  domtoimage
    .toJpeg(node, {
      bgcolor: '#fff',
      filter: filter
    })
    .then(function (dataUrl) {
      let link = document.createElement('a');
      link.download = 'export.jpeg';
      link.href = dataUrl;
      link.click();
    })
    .catch(function (error) {
      console.error('oops, something went wrong!', error);
    });
};

// ---------------------------------------------------------------------------------------
// Watches
// ---------------------------------------------------------------------------------------
//Create map on data change
watchEffect(() => {
  if (props.data !== null) {
    nextTick(() => setupChart(props.data));
  }
});

watchEffect(() => {
  if (props.data !== null) {
    chartConfig.value = parseChartData(props.data);
  }
});

watch(displayRelativeValues, (newDisplayRelativeValues, oldDisplayRelativeValues) => {
  if (newDisplayRelativeValues === !oldDisplayRelativeValues) {
    chartInstance.value.destroy();
    chartInstance.value = null;
    setupChart(props.data);
  }
});

//Clear chart on unmount
onBeforeUnmount(() => {
  if (chartInstance.value !== null) {
    chartInstance.value.clear();
  }
});
</script>

<style lang="scss" scoped>
canvas:fullscreen {
  background-color: white;
}

canvas:-webkit-full-screen {
  background-color: white;
}

canvas:-moz-full-screen {
  background-color: white;
}

canvas:-ms-fullscreen {
  background-color: white;
}

.fullscreen {
  float: right;
  margin-right: 10px;

  button {
    i.fullscreen-icon {
      border: none;
      float: left;
      background-image: url('~leaflet.fullscreen/icon-fullscreen.svg');
      background-size: 18px 36px;
      width: 18px;
      height: 18px;
      display: block;
    }
  }
}
</style>
