export const getZoneTypeLabel = (type) => {
  switch (type) {
    case 'com':
      return 'Commune';
    case 'epci':
      return 'EPCI';
    case 'uu':
      return 'Unité urbaine';
    case 'aa':
      return "Aire d'attraction";
    default:
      console.error('Type ' + type + ' is not defined');
      return undefined;
  }
};
export const formatZoneValue = (zone) => {
  return zone.type + '_' + zone.id;
};
export const formatZoneLabel = (zone) => {
  switch (zone.type) {
    case 'com':
    case 'aa':
    case 'uu':
      return zone.name + ' (' + zone.identifier + ')';
    default:
      return zone.name;
  }
};
