<template>
  <div class="s-button-confirm">
    <s-button size="sm" @click="isVisible = true" :icon="icon"></s-button>
    <div class="overlap" v-show="isVisible">
      <span>{{ confirmLabel }}</span>
      <s-button size="sm" icon="icon-check" color="orange" @click="clickConfirm"></s-button>
      <s-button size="sm" icon="icon-close" @click="isVisible = false" color="orange"></s-button>
    </div>
  </div>
</template>

<script setup>
import SButton from '@/components/commons/SButton.vue';
import { ref } from 'vue';

const emit = defineEmits(['confirm']);

const props = defineProps({
  confirmLabel: {
    type: String,
    required: true
  },
  icon: {
    type: String,
    required: true
  }
});

const isVisible = ref(false);
const clickConfirm = () => {
  isVisible.value = false;
  emit('confirm');
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
.s-button-confirm {
  position: relative;

  .overlap {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    align-items: center;
    white-space: nowrap;
    background-color: $orange;
    color: $white;
    border-radius: 4px;
    span {
      padding: 0 0.75rem;
      font-size: 0.875rem;
      font-weight: 700;
    }
  }
}
</style>
