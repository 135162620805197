<template>
  <div class="login">
    <div class="logo" v-if="loading === false">
      <img
        height="65"
        src="@/assets/img/layout/silogues-beta-logo.svg"
        width="175"
        alt="Silogues"
      />
    </div>
    <div class="loader" v-if="loading === true || submitting === true">
      <spinner-loader color="#88BEDC"></spinner-loader>
      <span class="loader-text" v-if="loading === true">Vérification...</span>
      <span class="loader-text" v-else>Activation du compte...</span>
    </div>
    <div class="block" v-else>
      <h1 class="block-title">Activez votre compte</h1>
      <div v-if="error !== null">
        <div class="error callout">
          <p class="error-details">{{ error }}</p>
        </div>
        <div class="btn-row">
          <router-link
            :to="{
              name: 'login'
            }"
          >
            <button class="btn green">Retour à l'identification</button>
          </router-link>
        </div>
      </div>
      <div v-else>
        <p>
          <b>Bonjour {{ user.personalInfos.firstName }},</b><br />
          Afin d'activer votre compte sur Silogues vous devez saisir le mot de passe que vous
          souhaitez utiliser pour votre compte.
        </p>
        <form @submit.prevent>
          <div class="form-container">
            <app-input
              placeholder="Mot de passe"
              model="password"
              @change="updateFormValue"
              :required="true"
              type="password"
            />
          </div>
          <div class="form-container">
            <app-input
              placeholder="Confirmez le mot de passe"
              model="passwordConfirm"
              @change="updateFormValue"
              :required="true"
              type="password"
            />
          </div>
          <div v-if="loginError" class="error callout">
            <p>{{ loginError }}</p>
            <p class="error-details">{{ loginErrorDetails }}</p>
          </div>
          <div class="error callout" v-if="credentials.error">
            <p class="error-details">{{ credentials.error }}</p>
          </div>
          <div class="btn-row">
            <button
              :class="{ disabled: loggingIn }"
              :disabled="loggingIn"
              class="btn green"
              type="submit"
              @click="submit"
            >
              Activer votre compte
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  apiLogin,
  handleLoginError,
  handleLoginSuccess,
  loginError,
  loginErrorDetails
} from '@/router/authentication';
import { useRoute, useRouter } from 'vue-router';
import { ref, onBeforeMount, defineProps, reactive } from 'vue';
import SpinnerLoader from 'vue-spinner/src/ScaleLoader.vue';
import { getUserByConfirmationToken } from '@/api/user';
import AppInput from '@/components/commons/AppInput.vue';
import { activateAccount } from '@/composables/user';
const route = useRoute();

const props = defineProps({
  token: {
    type: String,
    default: null
  }
});

const credentials = reactive({
  password: null,
  passwordConfirm: null,
  error: null
});
const errorMessage = "L'url d'activation est invalide";
const user = ref(null);
const loggingIn = ref(false);
const loading = ref(true);
const submitting = ref(false);
const error = ref(null);
const router = useRouter();

const updateFormValue = (property, value) => {
  credentials[property] = value;
};

const submit = () => {
  if (
    credentials.password === null ||
    credentials.password === '' ||
    credentials.passwordConfirm === null ||
    credentials.passwordConfirm === ''
  ) {
    credentials.error = 'Vous devez remplir tous les champs';
  } else if (credentials.password !== credentials.passwordConfirm) {
    credentials.error = 'Les deux mot de passes doivent être identiques';
  } else {
    credentials.error = null;
  }
  if (null === credentials.error) {
    submitting.value = true;
    activateAccount(
      user.value.id,
      props.token,
      {
        password: credentials.password,
        passwordConfirm: credentials.passwordConfirm
      },
      () => {
        apiLogin({
          username: user.value.username,
          password: credentials.password
        })
          .catch((error) => {
            handleLoginError(error);
            submitting.value = false;
          })
          .then((response) => {
            handleLoginSuccess(response, router);
          });
      },
      () => {
        submitting.value = false;
        credentials.error = "Une erreur est survenue, impossible d'activer ce compte.";
      }
    );
  }
};

onBeforeMount(() => {
  if (null === props.token) {
    error.value = errorMessage;
  }
  getUserByConfirmationToken(props.token)
    .catch(() => {
      loading.value = false;
      error.value = errorMessage;
    })
    .then((response) => {
      user.value = response.data;
      loading.value = false;
    });
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.login {
  .logo img {
    margin: 10rem auto 3rem;
    display: block;
  }

  .block-title {
    text-align: center;
    color: $blue-2;
    margin-bottom: 3rem;
  }

  .loader {
    margin: 6rem auto;
    .loader-text {
      margin-top: 50px;
      display: block;
    }
  }

  .block {
    width: 100%;
    max-width: 25rem;
    margin: 3rem auto;

    form {
      padding-top: 1rem;
    }
    .form-container {
      input {
        width: 100%;
        box-sizing: border-box;
      }
    }

    .btn-row {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
