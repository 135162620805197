<template>
  <div>
    <div v-if="uploadError !== null" class="error callout">
      <p>{{ uploadError }}</p>
    </div>
    <avatar
      :initials="initials"
      :avatar="avatar"
      :upload-status="uploadStatus"
      @upload-avatar="upload"
      @remove-avatar="removeOrganizationAvatar"
      @upload-error="onUploadError"
      role="ROLE_REFERENT"
    ></avatar>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import Avatar from '@/components/commons/Avatar.vue';
import { findMediaByIdentifier } from '@/helper/mediaHelper';
import { mapMutations } from '@/store/mappers';
import { removeAvatar, uploadAvatar } from '@/composables/organization';

const { setUserOrganization } = mapMutations('security');

const props = defineProps({
  initials: {
    type: String,
    default: null
  },
  organization: {
    type: Object,
    required: true
  }
});

const uploadStatus = ref('idle');
const uploadError = ref(null);

const avatar = computed(() => {
  return findMediaByIdentifier(props.organization.medias, 'logo');
});

const upload = (formData) => {
  uploadStatus.value = 'progress';
  uploadError.value = null;
  uploadAvatar(
    props.organization.id,
    formData,
    (response) => {
      setUserOrganization(response.data);
      uploadStatus.value = 'idle';
    },
    (error) => {
      if (error.response.data.message !== undefined) {
        uploadError.value = error.response.data.message;
      } else {
        uploadError.value = 'Une erreur est survenue durant le téléchargement.';
      }
      uploadStatus.value = 'idle';
    }
  );
};

const onUploadError = (message) => {
  uploadStatus.value = 'idle';
  uploadError.value = message;
};

const removeOrganizationAvatar = () => {
  uploadStatus.value = 'progress';
  removeAvatar(
    props.organization.id,
    (response) => {
      setUserOrganization(response.data);
      uploadStatus.value = 'idle';
    },
    (error) => {
      if (error !== undefined) {
        uploadError.value = error.response.data.message;
      } else {
        uploadError.value = 'Une erreur est survenue durant le téléchargement.';
      }
      uploadStatus.value = 'idle';
    }
  );
};
</script>
