import { mapState } from '@/store/mappers';
import { indexOf } from 'lodash';

const { user } = mapState('security');

export const isGranted = (role, otherUser = null) => {
  let testedUser = otherUser !== null ? otherUser : user.value;
  if (testedUser === null) return false;
  return indexOf(testedUser.roles, role) !== -1;
};
