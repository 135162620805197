import { authenticatedApi as api } from './api';

export const getLoggedUser = () => {
  return api().get(
    '/users/logged?serialization_groups[]=organization&serialization_groups[]=get&serialization_groups[]=medias'
  );
};

export const updatePassword = (data) => {
  return api().put('/users/change-password', data);
};

export const updateUser = (userId, data) => {
  return api().put('/users/' + userId, data);
};

export const updateLoggedUser = (data) => {
  return api().put(
    '/users?serialization_groups[]=organization&serialization_groups[]=licences&serialization_groups[]=get&serialization_groups[]=medias',
    data
  );
};

export const deleteLoggedUser = (userId) => {
  return api().delete('/users/' + userId);
};

export const uploadAvatar = (data) => {
  return api().post('/users/avatar', data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};
export const removeAvatar = (data) => {
  return api().delete('/users/avatar', data);
};

export const activateAccount = (userId, userToken, data) => {
  return api().put('/users/activate/' + userToken + '/' + userId, data);
};

export const getUserByConfirmationToken = (token) => {
  return api().get('/users/by-confirmation-token/' + token);
};

export default {
  getLoggedUser,
  updatePassword,
  activateAccount,
  getUserByConfirmationToken,
  updateUser,
  updateLoggedUser,
  removeAvatar,
  deleteLoggedUser
};
