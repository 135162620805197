<template>
  <div>
    <h2 class="block-title">Mon mot de passe</h2>
    <div class="info-list">
      <div v-if="changePasswordForm.error" class="error callout">
        <p>{{ changePasswordForm.error }}</p>
      </div>
      <div class="form-container">
        <app-input
          placeholder="Mot de passe actuel"
          model="password"
          type="password"
          :submitted="changePasswordForm.submitted"
          :initialValue="changePasswordForm.password"
          @change="updateFormValue"
          required
        />
        <app-input
          placeholder="Nouveau mot de passe"
          model="newPassword"
          type="password"
          :submitted="changePasswordForm.submitted"
          @change="updateFormValue"
          required
          :initialValue="changePasswordForm.newPassword"
        />
        <app-input
          placeholder="Confirmer mot de passe"
          model="newPasswordConfirm"
          type="password"
          @change="updateFormValue"
          required
          :submitted="changePasswordForm.submitted"
          :initialValue="changePasswordForm.newPasswordConfirm"
        />
      </div>
      <button
        class="btn turquoise"
        @click="submitPasswordChange()"
        v-if="changePasswordForm.submitting === false && changePasswordForm.saved === false"
      >
        Changer mon mot de passe
      </button>
      <button
        class="btn turquoise"
        disabled
        v-else-if="changePasswordForm.submitting === true && changePasswordForm.saved === false"
      >
        <i class="loader"></i>
        Enregistrement en cours...
      </button>
      <button class="btn turquoise" disabled v-else>
        <i class="icon icon-check"></i>
        Enregistré !
      </button>
    </div>
  </div>
</template>

<script setup>
import AppInput from '@/components/commons/AppInput.vue';
import { reactive } from 'vue';
import { updatePassword } from '@/api/user';

const changePasswordForm = reactive({
  password: null,
  newPassword: null,
  newPasswordConfirm: null,
  error: null,
  submitted: false,
  submitting: false,
  saved: false
});
const updateFormValue = (property, value) => {
  changePasswordForm[property] = value;
};

const submitPasswordChange = () => {
  changePasswordForm.submitted = true;
  if (
    changePasswordForm.password === null ||
    changePasswordForm.password === '' ||
    changePasswordForm.newPassword === null ||
    changePasswordForm.newPassword === '' ||
    changePasswordForm.newPasswordConfirm === null ||
    changePasswordForm.newPasswordConfirm === ''
  ) {
    changePasswordForm.error = 'Vous devez remplir tous les champs';
  } else if (changePasswordForm.newPassword !== changePasswordForm.newPasswordConfirm) {
    changePasswordForm.error = 'Les deux mot de passes doivent être identiques';
  } else {
    changePasswordForm.error = null;
  }
  if (null === changePasswordForm.error) {
    changePasswordForm.submitting = true;
    changePasswordForm.error = null;
    updatePassword({
      currentPassword: changePasswordForm.password,
      newPassword: changePasswordForm.newPassword,
      newPasswordConfirm: changePasswordForm.newPasswordConfirm
    })
      .then(() => {
        changePasswordForm.password = null;
        changePasswordForm.newPassword = null;
        changePasswordForm.newPasswordConfirm = null;
        changePasswordForm.submitted = false;
        changePasswordForm.submitting = false;
        changePasswordForm.saved = true;
        setTimeout(() => {
          changePasswordForm.saved = false;
        }, 2000);
      })
      .catch((error) => {
        changePasswordForm.submitting = false;
        changePasswordForm.error = error.response.data.message;
      });
  }
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_form.scss';
@import '@/assets/scss/commons/_buttons.scss';

.block-title {
  margin-bottom: 1rem;
}
.form-container {
  input {
    width: 100%;
    margin-bottom: 1rem;
  }
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
}
.loader {
  width: 14px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid $white;
  border-right-color: $turquoise-2;
  animation: l2 1s infinite ease-in-out;
  margin: 0;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
</style>
