import organizationApi from '@/api/organization';
import { findIndex, merge, remove } from 'lodash';

const state = {
  users: [],
  projects: [],
  usersLoaded: false
};

const getters = {
  // getZonesBySimulation: (state) => (args) => {
  //   const { project, simulation } = args;
  //   let zone = state.zonesJson.find((zone) => {
  //     return zone.version === getSimulationVersion(project, simulation);
  //   });
  //   return zone !== undefined ? zone : null;
  // },
};

const actions = {
  fetchUsers: ({ commit }, organization) => {
    return organizationApi.getUsers(organization.id).then((response) => {
      commit('setUsers', response.data);
      commit('setUsersLoaded', true);
    });
  },
  fetchProjects: ({ commit }, organization) => {
    return organizationApi.getProjects(organization.id).then((response) => {
      commit('setProjects', response.data);
    });
  }
};

const mutations = {
  setUsers(state, users) {
    state.users = users;
  },
  setProjects(state, projects) {
    state.projects = projects;
  },
  addUser(state, user) {
    state.users = [...state.users, user];
  },
  updateUser(state, user) {
    let userIndex = findIndex(state.users, { id: user.id });
    state.users.splice(userIndex, 1, user);
  },
  removeUser(state, removedUser) {
    state.users = remove(state.users, (user) => removedUser.id !== user.id);
  },
  setUsersLoaded(state, loaded) {
    state.usersLoaded = loaded;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
