<template>
  <div class="new-project-step2 selection-row">
    <div class="grey-block half selection defined" :class="{ active: isSelected('defined') }">
      <i class="zoning"></i>
      <div class="text-content" @click="selectStrategy('defined')">
        <span></span>
        <p class="h4 text-blue">Choisir un ou plusieurs périmètre(s) prédéfini(s)</p>
      </div>
      <p class="text-grey">
        Recherchez des communes, EPCI, unités urbaines ou aires d'attraction (par leur nom ou leur
        numéro).
      </p>
      <div class="block-content form-container-grey-bg">
        <Multiselect
          mode="tags"
          placeholder="Lyon, Metropole de Lyon,..."
          :disabled="!isSelected('defined')"
          :options="availableZones"
          v-model="selectedZones"
          :object="true"
          :resolve-on-load="true"
          :min-chars="2"
          :delay="200"
          label="label"
          searchable
          :filter-results="false"
          groups
          :group-select="false"
          group-hide-empty
          :close-on-select="false"
          :clear-on-blur="false"
          no-options-text="Tapez des caractères pour rechercher un périmètre"
          no-results-text="Aucun résultat pour cette recherche"
        >
          <template v-slot:tag="{ option, handleTagRemove, disabled }">
            <div class="multiselect-tag" :class="option.type">
              {{ option.name }} ({{ getZoneTypeLabel(option.type) }})
              <span
                v-if="!disabled"
                class="multiselect-tag-remove"
                @click="handleTagRemove(option, $event)"
              >
                <span class="multiselect-tag-remove-icon"></span>
              </span>
            </div>
          </template>
          <template v-slot:grouplabel="{ group }">
            <div class="multiselect-group-label" :class="group.type">
              <span>{{ group.label }}</span>
            </div>
          </template>
        </Multiselect>
        <div class="white-hr"></div>
        <p class="h4 text-blue">ou renseigner manuellement</p>
        <p class="text-grey">
          Remplissez la liste des périmètres en saisissant les codes des communes ou des périmètres,
          séparés par des virgules
        </p>
        <div :class="{ error: cityCodesHasError }">
          <textarea
            v-model.trim="cityCodes"
            placeholder="Code commune"
            :disabled="!isSelected('defined')"
            @input="resetCityCodesValidationError"
          ></textarea>
          <p class="error-msg" v-if="cityCodesErrorMessage !== null">
            {{ cityCodesErrorMessage }}
          </p>
        </div>
        <button
          :class="{
            'btn secondary float-right': true,
            disabled: !cityCodesCanBeAdded
          }"
          @click="validateCityCodes()"
        >
          <span v-if="cityCodesValidationInProgress">Vérification</span
          ><span v-else>Vérifier et ajouter</span>
        </button>
      </div>
    </div>
    <div class="grey-block half selection file" :class="{ active: isSelected('file') }">
      <i class="pin"></i>
      <div class="text-content" @click="selectStrategy('file')">
        <span></span>
        <p class="h4 text-blue">Importer votre fichier de zonage</p>
      </div>
      <div class="block-content center">
        <uploadFile
          :disabled="!isSelected('file')"
          :label="'Importer fichier shapefile ou GeoJson'"
          :status="uploadStatus"
          :server-error="uploadError"
          :existing-file-name="props.projectData?.zonesCustomFile"
          :existing-file-original-name="props.projectData?.zonesCustomFileOriginalName"
          :existing-file-size="props.projectData?.zonesCustomFileSize"
          @upload-file-selected="onFileSelection"
          @upload-file-changed="submitUploadedFile"
          @remove-uploaded-file="removeUploadedFile"
        />
      </div>
    </div>
  </div>
  <create-project-actions
    @next="submit"
    @previous="previous"
    has-previous
    has-next
    :is-valid="isValid"
    :submitting="submitting"
    :error-message="errorMessage"
  >
  </create-project-actions>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue';
import { errorMessage, submitting } from '@/composables/createProject';
import CreateProjectActions from './CreateProjectActions.vue';
import {
  getZones,
  putProject,
  removeUploadedProjectZonesFile,
  uploadProjectZonesFile,
  validateZones,
  verifyProjectZonesFile
} from '@/api/project';
import { findIndex, reduce } from 'lodash';
import Multiselect from '@vueform/multiselect';
import UploadFile from '@/components/commons/UploadFile.vue';
import { formatZoneLabel, formatZoneValue, getZoneTypeLabel } from '@/helper/zoneHelper';
import { mapMutations } from '@/store/mappers';

//Props
const props = defineProps({
  projectData: {
    type: Object,
    default: null
  }
});

// General strategy selection
// -----------------------------------------------------------------------------------------------------------------
const selectedStrategy = ref(null);
const selectStrategy = (optionName) => {
  selectedStrategy.value = optionName;
};
const isSelected = (strategyName) => {
  return strategyName === selectedStrategy.value;
};

// Defined zones strategy
// -----------------------------------------------------------------------------------------------------------------
const availableZones = async function (searchQuery) {
  if (searchQuery !== null && searchQuery !== '') {
    const query = {
      q: searchQuery,
      'order[population]': 'desc',
      items: 50
    };
    const response = await getZones(query);
    return groupZonesByType(response.data);
  } else {
    return [];
  }
};
const selectedZones = ref(null);
const groupZonesByType = (zones) => {
  return reduce(
    zones,
    (grouped, zone) => {
      let groupIndex = findIndex(grouped, (group) => group.type === zone.type);

      zone.value = formatZoneValue(zone);
      zone.label = formatZoneLabel(zone);

      if (groupIndex !== -1) {
        grouped[groupIndex].options.push(zone);
      } else {
        grouped.push({
          type: zone.type,
          label: getZoneTypeLabel(zone.type),
          options: [zone]
        });
      }
      return grouped;
    },
    []
  );
};
//Populate on edit
onMounted(() => {
  if (props.projectData?.zones?.length > 0) {
    selectStrategy('defined');
    selectedZones.value = props.projectData.zones.map((zone) => {
      zone.value = formatZoneValue(zone);
      zone.label = formatZoneLabel(zone);
      return zone;
    });
  } else if (props.projectData?.zonesCustomFile !== null) {
    selectStrategy('file');
  }
});

// City codes strategy
// -----------------------------------------------------------------------------------------------------------------
const cityCodes = ref(null);
const cityCodesValidationInProgress = ref(false);
const cityCodesValidationErrorMessage = ref(null);
const cityCodesArray = computed(() => {
  return cityCodes.value === null ? [] : cityCodes.value.split(',');
});
const cityCodesCanBeAdded = computed(() => {
  return (
    cityCodesArray.value.length > 0 &&
    cityCodesArray.value[0] !== '' &&
    cityCodesValidationInProgress.value === false
  );
});
const cityCodesErrorMessage = computed(() => {
  if (!cityCodesFormatValid.value) {
    if (errorCityCodes.value.length !== 1) {
      return (
        'Les codes INSEE suivants ne sont pas valides : ' + errorCityCodes.value.join(', ') + '.'
      );
    } else if (errorCityCodes.value.length === 1) {
      return 'Le code INSEE ' + errorCityCodes.value[0] + " n'est pas valide.";
    }
  }
  if (cityCodesValidationErrorMessage.value !== null) {
    return cityCodesValidationErrorMessage.value;
  }
  return '';
});
const errorCityCodes = computed(() => {
  let errors = [];
  if (cityCodes.value && cityCodes.value.length >= 5) {
    cityCodesArray.value.forEach((cityCode) => {
      if (!RegExp(/^[0-9]{5}$/g).test(cityCode)) {
        if (cityCode.length >= 5) {
          errors.push(cityCode);
        }
      }
    });
  }
  return errors;
});
const cityCodesHasError = computed(() => {
  return errorCityCodes.value.length > 0 || cityCodesValidationErrorMessage.value !== null;
});
const cityCodesFormatValid = computed(() => {
  if (!cityCodesCanBeAdded.value) {
    return true;
  }
  return errorCityCodes.value.length === 0;
});
const validateCityCodes = () => {
  if (cityCodes.value.charAt(cityCodes.value.length - 1) === ',') {
    cityCodes.value = cityCodes.value.slice(0, -1);
  }
  cityCodesValidationInProgress.value = true;
  validateZones(cityCodesArray.value).then((response) => {
    cityCodesValidationInProgress.value = false;
    cityCodesValidationErrorMessage.value =
      response.data.errors === undefined ? null : response.data.errors;
    response.data.zones.forEach((zone) => {
      if (selectedZones.value === null) {
        selectedZones.value = [zone];
      } else {
        selectedZones.value.push(zone);
      }
      cityCodes.value = cityCodes.value
        .replace(zone.identifier + ',', '')
        .replace(zone.identifier, '');
    });
  });
};
const resetCityCodesValidationError = () => {
  cityCodesValidationErrorMessage.value = null;
  cityCodes.value = cityCodes.value.replace(' ', '');
};

// Zonage file upload strategy
// -----------------------------------------------------------------------------------------------------------------
const uploadStatus = ref('idle');
const uploadError = ref(null);
const { updateCurrentProject } = mapMutations('project');
onMounted(() => {
  if (
    props.projectData?.zonesCustomFile !== undefined &&
    props.projectData?.zonesCustomFile !== null
  ) {
    uploadStatus.value = 'complete';
  }
});
const onFileSelection = () => {
  uploadError.value = null;
};
const submitUploadedFile = (formData) => {
  uploadStatus.value = 'progress';
  uploadProjectZonesFile(props.projectData.id, formData)
    .then((response) => {
      uploadStatus.value = 'verifying';
      verifyProjectZonesFile(props.projectData.id).then((responseVerify) => {
        console.log(responseVerify.data);
        if (responseVerify.data === 'ok') {
          uploadStatus.value = 'complete';
          updateCurrentProject(response.data);
        } else {
          uploadError.value = responseVerify.data;
          uploadStatus.value = 'idle';
        }
      });
    })
    .catch((error) => {
      if (error !== undefined) {
        uploadError.value = error.response.data.message;
      } else {
        uploadError.value = 'Une erreur est survenue durant le téléchargement.';
      }
      uploadStatus.value = 'idle';
    });
};

const removeUploadedFile = () => {
  uploadStatus.value = 'removing';
  removeUploadedProjectZonesFile(props.projectData.id, props.projectData.zonesCustomFile).then(
    (response) => {
      uploadStatus.value = 'idle';
      updateCurrentProject(response.data);
    }
  );
};

// Submission
// -----------------------------------------------------------------------------------------------------------------
const isValid = computed(() => {
  switch (selectedStrategy.value) {
    case 'defined':
      return selectedZones.value !== null && selectedZones.value.length > 0;
    case 'file':
      return props.projectData.zonesCustomFile !== null;
    default:
      return false;
  }
});
const prepareDefinedZonesForSubmission = (zones) => {
  return zones.map((zone) => {
    return {
      id: zone.id,
      type: zone.type
    };
  });
};
const emit = defineEmits(['next', 'previous', 'substep', 'go-to-step']);
const submit = () => {
  submitting.value = true;

  switch (selectedStrategy.value) {
    case 'defined':
      // props.projectData.zonesCustomFile = null;
      // props.projectData.zonesCustomFileOriginalName = null;
      putProject(props.projectData.id, {
        zones: prepareDefinedZonesForSubmission(selectedZones.value)
      }).then((response) => {
        emit('next', response.data);
        submitting.value = false;
      });
      break;
    case 'file':
      emit('next', props.projectData);
      submitting.value = false;
      break;
    default:
      console.error('Selected strategy "' + selectedStrategy.value + '" not implemented yet');
      break;
  }
};
const previous = () => {
  emit('previous');
};
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';
@import '@/assets/scss/pages/_selection.scss';

.white-hr {
  margin: 3rem 0;
}

.form-container-grey-bg textarea {
  max-width: 100%;
  margin: 0 0 1rem;
}

.label {
  margin-top: 1rem;
}

.multiselect-tag {
  &.com {
    background-color: $blue-2;
  }

  &.epci {
    background-color: $blue-4;
  }

  &.uu {
    background-color: $purple-2;
  }

  &.aav {
    background-color: $purple;
  }
}

.multiselect-group-label {
  &.com {
    color: $blue-2;
  }

  &.epci {
    color: $blue-4;
  }

  &.uu {
    color: $purple-2;
  }

  &.aav {
    color: $purple;
  }
}
</style>
