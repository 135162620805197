<template>
  <div class="recap-number">
    <div class="number">
      <p>{{ formatNumber(indicator.value) }} {{ indicator.unit }}</p>
    </div>
    <div>
      <p class="title">{{ indicator.nom }}</p>
      <p>{{ indicator.description }}</p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  indicator: Object
});

const formatNumber = (value) => {
  return parseFloat(value).toLocaleString('fr');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_layout.scss';
@import '@/assets/scss/commons/_buttons.scss';

.btn-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}

.recap-number {
  display: flex;
  align-items: center;
  margin-bottom: 2rem;
  width: 20rem;
  min-width: 20rem;

  .number {
    color: $blue-1;
    font-weight: 700;
    text-transform: uppercase;
    padding: 0.625rem;
    margin: 0 0.5rem 0 0;
    display: inline-block;
    background-color: $grey-3;
    border-radius: 0.5rem;

    p {
      margin: 0 0.5rem;
    }
  }

  .number + div {
    p {
      color: $blue-1-disabled;
      font-size: 0.875rem;
      margin: 0;
    }

    .title {
      color: $grey-1;
      font-weight: 700;
      font-size: 1rem;
    }
  }
}
</style>
