<template>
  <div class="block">
    <div class="title-with-button">
      <h2 class="block-title">Données du graph</h2>
      <button class="btn green with-icon" @click="$emit('export')">
        Exporter les données en CSV <i class="icon-csv"></i>
      </button>
    </div>
    <div class="table-overflow">
      <table>
        <thead>
          <tr>
            <td>
              {{ tableHeaders.main }} <span><i class="icon-order"></i></span>
            </td>
            <td v-for="(label, colIndex) in tableHeaders.cols" :key="'header_' + colIndex">
              {{ label }} <span><i class="icon-order"></i></span>
            </td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(row, rowName) in tableData" :key="'row_' + rowName">
            <td class="row-name">{{ rowName }}</td>
            <td v-for="(cell, colName) in row" :key="'row_' + rowName + '_col_' + colName">
              {{ formatValue(cell) }}
            </td>
          </tr>
        </tbody>
      </table>
      <button v-if="hasMoreData" class="btn grey-3 with-icon" @click="incrementPage">
        Afficher plus <i>+</i>
      </button>
    </div>
  </div>
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue';
import { forEach } from 'lodash';

const props = defineProps({
  data: {
    type: Object,
    default: null
  }
});

defineEmits(['export']);

const TABLE_PAGINATION_INCREMENTS = 25;
const page = ref(1);

const tableData = computed(() => {
  //model =  {row: {column: value}}
  const units = getScaleUnits(props.data);
  switch (props.data.chartType) {
    case 'bubble':
      return props.data.datasets.reduce((table, dataset) => {
        dataset.data.slice(0, TABLE_PAGINATION_INCREMENTS * page.value).forEach((dataPoint) => {
          if (table[dataPoint.label] === undefined) {
            table[dataPoint.label] = {};
          }
          table[dataPoint.label]['x'] = {
            value: dataPoint.x,
            units: units.x
          };
          table[dataPoint.label]['y'] = {
            value: dataPoint.y,
            units: units.y
          };
          table[dataPoint.label]['r'] = {
            value: dataPoint.r_orig === undefined ? dataPoint.r : dataPoint.r_orig, //Bug graph 15 avec dataPoint.r_orig
            units: units.r
          };
        });
        return table;
      }, {});
    default: {
      let labels = [];
      const tableData = props.data.datasets.reduce((table, dataset) => {
        dataset.data.slice(0, TABLE_PAGINATION_INCREMENTS * page.value).forEach((dataPoint) => {
          if (table[dataPoint.x] === undefined) {
            table[dataPoint.x] = {};
          }
          table[dataPoint.x][dataset.label] = {
            value: dataPoint.y,
            units: units.y
          };
          if (!labels.includes(dataset.label)) {
            labels.push(dataset.label);
          }
        });
        return table;
      }, {});

      //fill columns that have missing data for some rows
      forEach(tableData, (row, key) => {
        forEach(labels, (label) => {
          if (tableData[key][label] === undefined)
            tableData[key][label] = {
              value: null,
              units: null
            };
        });
      });

      //fill columns that are completely empty
      let emptyCols = props.data.datasets.reduce((emptyColsLabels, dataset) => {
        if (dataset.data.length === 0) {
          emptyColsLabels.push(dataset.label);
        }
        return emptyColsLabels;
      }, []);
      emptyCols.forEach((label) => {
        forEach(tableData, (row, key) => {
          tableData[key][label] = {
            value: null,
            units: null
          };
        });
      });

      return tableData;
    }
  }
});

const countTableData = computed(() => {
  return props.data.datasets.reduce((table, dataset) => {
    dataset.data.forEach((dataPoint) => {
      if (!table.includes(dataPoint.x)) {
        table.push(dataPoint.x);
      }
    });
    return table;
  }, []).length;
});

const getScaleTitles = (data) => {
  let scaleTitles = {};
  const scales = data.options?.scales;
  if (scales) {
    forEach(scales, (config, identifier) => {
      scaleTitles[identifier] = config.title?.text;
    });
  }
  return scaleTitles;
};

const getScaleUnits = (data) => {
  let units = {};
  const scales = data.options?.scales;
  if (scales) {
    forEach(scales, (scale, identifier) => {
      if (scale.units !== undefined) {
        units[identifier] = scale.units;
      }
    });
  }
  return units;
};

const incrementPage = () => {
  page.value++;
};

const hasMoreData = computed(() => {
  return countTableData.value > TABLE_PAGINATION_INCREMENTS * page.value;
});

const tableHeaders = computed(() => {
  let scaleTitles = getScaleTitles(props.data);
  switch (props.data.chartType) {
    case 'bubble':
      return {
        main: props.data.datasets[0].label,
        cols: scaleTitles
      };
    default:
      return {
        cols: Object.keys(Object.values(tableData.value)[0]),
        main: scaleTitles.x
      };
  }
});

const formatValue = ({ value, units }) => {
  if (value === null) {
    return '-';
  }

  let addUnits = units ? ' ' + units : '';
  return parseFloat(value) % 1 === 0
    ? parseInt(value).toLocaleString('fr') + addUnits
    : parseFloat(value).toLocaleString('fr') + addUnits;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.filters-row {
  display: flex;
  justify-content: space-between;

  div {
    i {
      font-size: 1.5rem;
      vertical-align: middle;
      margin-right: 1rem;
    }
  }
}

.table-overflow {
  overflow-x: scroll;

  table {
    min-width: 100%;
  }

  .btn {
    margin: 0 auto;
    display: block;
  }
}

table {
  border-collapse: collapse;
  margin: 1.25rem 0 2.5rem;
  white-space: nowrap;

  tr {
    border: 0;

    td {
      text-align: center;
      border: 0;
      padding: 0.5rem 1rem;
      border-spacing: 0.5rem;
    }
  }

  thead tr td,
  tbody tr .row-name {
    font-weight: 700;
  }

  thead tr td {
    font-size: 12px;

    &:first-child {
      text-align: left;
    }
  }

  tbody tr .row-name {
    color: $blue-2;
    text-align: left;
  }

  tr:nth-child(odd) {
    background-color: $grey-3;
  }
}
</style>
