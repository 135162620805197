<template>
  <Modal :open="open" @close="emit('close')">
    <template #title>Modifier mon organisation</template>
    <template #content>
      <div class="form-container">
        <text-with-bar title>Organisation</text-with-bar>
        <div class="form-row">
          <label for="name">Raison soc.<span class="required">*</span></label>
          <app-input
            placeholder="Raison sociale"
            model="name"
            :initialValue="user.organization.name"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
        <div class="form-row">
          <label for="type">Type<span class="required">*</span></label>
          <app-input
            type="select"
            placeholder="Choisissez un type d'organisation"
            model="type"
            :initialValue="typeInitialValue"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            :options="typeOptions"
          >
          </app-input>
        </div>
      </div>
      <div class="form-container">
        <text-with-bar title>Contact</text-with-bar>
        <div class="form-row">
          <label for="email">Mail<span class="required">*</span></label>
          <app-input
            type="email"
            placeholder="contact@mon-site.fr"
            model="email"
            :initialValue="user.organization.email"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
        <div class="form-row">
          <label for="website">Internet</label>
          <app-input
            placeholder="www.mon-site.fr"
            model="website"
            :initialValue="user.organization.website"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
          />
        </div>
        <div class="form-row">
          <label for="phoneNumber">Téléphone<span class="required">*</span></label>
          <app-input
            placeholder="Téléphone"
            model="phoneNumber"
            :initialValue="user.organization.phoneNumber"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
            required
          />
        </div>
      </div>
      <div class="form-container">
        <text-with-bar title>Adresse</text-with-bar>
        <div class="form-row">
          <app-input
            type="textarea"
            placeholder="Adresse"
            model="address"
            :initialValue="user.organization.address"
            :submitted="submitted"
            @change="updateFormValue"
            @error="updateFormError"
          />
        </div>
      </div>
    </template>
    <template #actions>
      <button
        class="btn primary"
        @click="submit"
        v-if="submissionInProgress === false && submissionDone === false"
      >
        Enregistrer les informations
      </button>
      <button
        class="btn primary"
        disabled
        v-else-if="submissionInProgress === true && submissionDone === false"
      >
        <i class="loader"></i>
        Enregistrement en cours...
      </button>
      <button v-else class="btn primary" disabled>
        <i class="icon icon-check"></i>
        Enregistré !
      </button>
      <div v-if="formErrors !== ''" class="error callout">
        <p>{{ formErrors }}</p>
      </div>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/commons/Modal.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { mapMutations, mapState } from '@/store/mappers';
import { updateOrganization } from '@/composables/organization';
import { cloneDeep, forEach } from 'lodash';
import {
  checkFormErrors,
  submitForm,
  updateFormFieldError,
  updateFormFieldValue
} from '@/helper/formHelper';
import TextWithBar from '@/components/commons/TextWithBar.vue';
import { extractErrorMessage } from '@/helper/formHelper';
import AppInput from '@/components/commons/AppInput.vue';
import { getTypes } from '@/api/organization';

const { setUserOrganization } = mapMutations('security');
const { user } = mapState('security');

const emit = defineEmits(['close']);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  }
});

const submitted = ref(false);
const submissionInProgress = ref(false);
const submissionDone = ref(false);
const formErrors = ref('');
const typeOptions = ref([]);
const schema = {
  name: null,
  type: null,
  email: null,
  website: null,
  phoneNumber: null,
  address: null
};
const form = reactive(schema);
const errors = reactive(cloneDeep(schema));

const typeInitialValue = computed(() => {
  return user.value.organization !== null && user.value.organization.type !== null
    ? user.value.organization.type.id.toString()
    : '';
});

const submit = () => {
  submitted.value = true;
  let errorMessage = checkFormErrors(errors);
  if ('' !== errorMessage) {
    submissionInProgress.value = false;
    formErrors.value = errorMessage;
  } else {
    if (form.type === '') {
      form.type = null;
    }
    submitForm(errors, formErrors, () => {
      submissionInProgress.value = true;
      updateOrganization(
        user.value.organization.id,
        form,
        (response) => {
          setUserOrganization(response.data);
          submissionInProgress.value = false;
          submissionDone.value = true;
          submitted.value = false;
          setTimeout(() => {
            submissionDone.value = false;
            emit('close');
          }, '1000');
        },
        (error) => {
          submissionInProgress.value = false;
          formErrors.value = extractErrorMessage(error.response);
        }
      );
    });
  }
};

onMounted(() => {
  getTypes()
    .then((response) => {
      let sortedOptions = [];
      forEach(response.data, (row) => {
        sortedOptions.push({ value: row.id, label: row.name });
      });
      typeOptions.value = sortedOptions;
    })
    .catch((error) => {
      console.log(extractErrorMessage(error.response));
    });
});

const updateFormValue = (property, value) => {
  updateFormFieldValue(form, property, value);
};
const updateFormError = (property, message) => {
  updateFormFieldError(errors, property, message);
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_form.scss';

.callout {
  margin-top: 1rem;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 100%;
  .icon-check::before {
    color: white;
  }
}
.form-container {
  margin-top: 2rem;
}
.loader {
  width: 14px;
  aspect-ratio: 1;
  border-radius: 50%;
  border: 2px solid $white;
  border-right-color: $purple-2;
  animation: l2 1s infinite ease-in-out;
  margin: 0;
}
@keyframes l2 {
  to {
    transform: rotate(1turn);
  }
}
</style>
