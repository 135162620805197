<template>
  <div class="login">
    <div class="logo" v-if="demoLoginIn === false">
      <img
        height="65"
        src="@/assets/img/layout/silogues-beta-logo.svg"
        width="175"
        alt="Silogues"
      />
    </div>
    <div class="loader" v-if="demoLoginIn === true">
      <spinner-loader color="#88BEDC"></spinner-loader>
      <span class="loader-text">Connexion au compte démo en cours...</span>
    </div>
    <div class="block" v-else>
      <h1 class="block-title">Connexion</h1>
      <form @submit.prevent>
        <div class="form-container">
          <label>Identifiant</label>
          <input v-model="credentials.username" placeholder="Login" type="text" />
        </div>
        <div class="form-container">
          <label>Mot de passe</label>
          <input v-model="credentials.password" placeholder="Password" type="password" />
        </div>
        <div v-if="loginError" class="error callout">
          <p>{{ loginError }}</p>
          <p class="error-details">{{ loginErrorDetails }}</p>
        </div>
        <a class="btn tertiary" style="float: left" href="?demo=1">Compte démo</a>
        <div class="btn-row">
          <button
            :class="{ disabled: loggingIn }"
            :disabled="loggingIn"
            class="btn green"
            type="submit"
            @click="login(credentials)"
          >
            Connexion
          </button>
        </div>
      </form>
    </div>
  </div>
</template>

<script setup>
import {
  apiLogin,
  handleLoginError,
  handleLoginSuccess,
  loginError,
  loginErrorDetails
} from '@/router/authentication';
import { useRouter } from 'vue-router';
import { onBeforeMount, ref } from 'vue';
import SpinnerLoader from 'vue-spinner/src/ScaleLoader.vue';

//const {t} = useI18n();

const credentials = {
  username: null,
  password: null
};
const loggingIn = ref(false);
const demoLoginIn = ref(false);

const router = useRouter();
const login = (credentials) => {
  loggingIn.value = true;
  apiLogin(credentials)
    .then((response) => {
      handleLoginSuccess(response, router);
    })
    .catch((error) => {
      handleLoginError(error);
      loggingIn.value = false;
    });
};

onBeforeMount(() => {
  const urlParams = new URLSearchParams(window.location.search);
  let demoLogin = urlParams.get('demo');
  if (null !== demoLogin && demoLogin === '1') {
    demoLoginIn.value = true;
    login({ username: 'SILOGUES', password: 'Demo@Silogues' });
  }
});
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.login {
  .logo img {
    margin: 10rem auto 3rem;
    display: block;
  }

  .block-title {
    text-align: center;
  }

  .loader {
    margin: 6rem auto;

    .loader-text {
      margin-top: 50px;
      display: block;
    }
  }

  .block {
    width: 100%;
    max-width: 25rem;
    margin: 3rem auto;

    .form-container {
      input {
        width: 100%;
        -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
        -moz-box-sizing: border-box; /* Firefox, other Gecko */
        box-sizing: border-box;
      }
    }

    .btn-row {
      display: flex;
      justify-content: flex-end;
    }
  }
}
</style>
