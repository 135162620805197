<template>
  <div :class="{ 'nav-is-active': sidenavActive, 'no-nav': isLogin }" class="layout">
    <sideNavigation v-if="ready && !isLogin" />
    <div :style="adaptForTopNav" class="content">
      <div v-if="ready && !isLogin && isLoading" class="full-loader">
        <spinner-loader color="#88BEDC"></spinner-loader>
      </div>
      <navigation v-if="ready && !isLogin && !isLoading" />
      <div class="main">
        <breadcrumb v-if="ready && !isLogin && !isLoading" />
        <router-view v-show="!isLoading" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import SideNavigation from './components/sideNavigation/SideNavigation';
import Navigation from './components/navigation/Navigation';
import Breadcrumb from './components/commons/Breadcrumb';
import { mapState } from './store/mappers';
import { routeIsUnder } from './router';
import { sidenavActive } from './composables/navigation';
import SpinnerLoader from 'vue-spinner/src/ScaleLoader.vue';

const route = useRoute();
const { project, isProjectLoading } = mapState('project');
const { simulation, isSimulationLoading } = mapState('simulation');

//Add padding if top-nav is open
const adaptForTopNav = computed(() => {
  if (isSimulation.value && !isSimulationLoading.value) {
    // const topTitleDOM = document.getElementsByClassName("top-title");
    // const topNavDOM = document.getElementsByClassName("top-nav");
    // console.log(topTitleDOM, topNavDOM);
    // const navHeight = topTitleDOM[0].clientHeight + topNavDOM[0].clientHeight;
    return {
      'padding-top': '8.125rem'
    };
  } else {
    return null;
  }
});
const isSimulation = computed(() => {
  return simulation && project && routeIsUnder('simulation', route);
});

//Needed to make sure navigation isn't loaded while not logged in, as route.name is not available right away
const ready = computed(() => route.name !== undefined);
const isLogin = computed(() => {
  return route.name === 'login' || route.name === 'activate';
});
const isLoading = computed(() => isProjectLoading.value || isSimulationLoading.value);
// const isNavigationDisplayed = computed(() => {
//   return route.name !== "login" && route.name !== "account";
// });
</script>

<style lang="scss">
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_layout.scss';

.layout {
  .main {
    padding: 2.5rem;
  }
}

.layout {
  display: flex;
  align-items: stretch;
  min-height: 100vh;
  justify-content: flex-end;

  .content {
    overflow: auto;
    width: calc(100% - 3.125rem);
    background-color: $grey-3;
    @include transition(width 0.6s ease-out);
    position: relative;
  }

  &.nav-is-active {
    .content {
      width: calc(100% - 15rem);
    }
  }

  &.no-nav .content {
    width: 100%;
  }

  .full-loader {
    position: absolute;
    width: 100%;
    height: 100%;

    .v-spinner {
      margin-top: 50vh;
      transform: translateY(-22px);
    }
  }
}
</style>
