<template>
  <div id="dashboardMap" class="block map"></div>
</template>

<script setup>
import L from 'leaflet';
import { onMounted, onUnmounted, ref, watch } from 'vue';
import { getColor } from '../../helper/colorHelper';
import { getShortName } from '../../composables/project';
import { has } from 'lodash';
import { getObjectIndexByObjectProperty } from '@/helper/objectHelper';

const mapInstance = ref(null);
const mapId = 'dashboardMap';

const props = defineProps({
  projects: {
    type: Array,
    default: null
  }
});

//Map lifecycle
const setMap = () => {
  const projects = props.projects;

  mapInstance.value = L.map(mapId, {
    scrollWheelZoom: true,
    fullscreenControlOptions: {
      position: 'topleft'
    }
  }).setView([46.449, 2.21], 6);
  //Create map background (Openstreetmap)
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapInstance.value);

  //Add projects to map
  let bounds = [];
  projects.forEach((project, index) => {
    //Inversed index to correspond to colors in sidebarNavigation
    const projectIndex = projects.length - index;

    let myIcon = L.divIcon({
      className: 'project-icon',
      html: getProjectIconHtml(project, projectIndex),
      iconSize: [60, 60]
    });

    //Add project markers (if their coordinates are defined)
    if (
      has(project, 'originLat') &&
      project.originLat !== null &&
      has(project, 'originLng') &&
      project.originLng !== null
    ) {
      L.marker([project.originLat, project.originLng], {
        icon: myIcon
      })
        .addTo(mapInstance.value)
        .on('click', () => {
          return goToProject(project);
        });
    }

    bounds.push([project.originLat, project.originLng]);
  });
  mapInstance.value.fitBounds(bounds, { maxZoom: 7 });
};
const removeMap = () => {
  if (mapInstance.value !== null) {
    mapInstance.value.remove();
  }
};
onUnmounted(removeMap);
onMounted(() => {
  if (props.projects !== null && props.projects.length > 0) {
    setMap();
  }
});
watch(
  () => props.projects,
  () => {
    removeMap();
    setMap();
  }
);

//Generate project icon on map
const getProjectIndexByIdentifier = (identifier) => {
  return getObjectIndexByObjectProperty(props.projects, identifier, 'identifier');
};

const getProjectIconHtml = (project) => {
  return `<i class="short-name-icon" style="background-color: ${getColor(
    getProjectIndexByIdentifier(project.identifier),
    1,
    0
  )}">${getShortName(project.name)}</i>`;
};

//Link to project
const goToProject = (project) => {
  document.location.href = '/project/' + project.identifier;
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';

:deep(i.short-name-icon) {
  color: $white;
  font-size: 1rem;
  line-height: 2rem;
  text-align: center;
  font-style: normal;
  width: 2rem;
  min-width: 2rem;
  height: 2rem;
  display: inline-block;
  border-radius: 50%;
  @include transition(all 0.2s ease-out);
  margin-left: 0.5rem;
}

.block.map {
  padding: 0;
  position: relative;
  height: 580px;
  overflow: hidden;
  z-index: 0;

  iframe {
    width: 100%;
  }
}
</style>
