<template>
  <title-with-button
    title="Mon organisation"
    @action="openOrganizationInfosForm"
    role="ROLE_REFERENT"
  />
  <div class="personal-infos-bloc">
    <organization-avatar :initials="initials" :organization="organization" />
    <div class="info-list">
      {{ organization.description }}
      <ul class="chevron-list">
        <li class="info-item">
          <p>Nom</p>
          <div class="flex">
            <p>{{ organization.name }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Type</p>
          <div class="flex">
            <p>{{ organization.type !== null ? organization.type?.name : 'N.C' }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Mail</p>
          <div class="flex">
            <p>{{ organization.email }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Adresse</p>
          <div class="flex">
            <p>{{ organization.address }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Tel</p>
          <div class="flex">
            <p>{{ organization.phoneNumber }}</p>
          </div>
        </li>
        <li class="info-item">
          <p>Web</p>
          <div class="flex">
            <p>{{ organization.website !== null ? organization.website : 'N.C' }}</p>
          </div>
        </li>
      </ul>
    </div>
  </div>
  <ModalEditOrganizationInfo
    :open="organizationInfoModalOpened"
    @close="organizationInfoModalOpened = false"
  />
</template>

<script setup>
import TitleWithButton from '@/components/commons/TitleWithButton.vue';
import ModalEditOrganizationInfo from '@/components/dashboard/organization/ModalEditOrganizationInfo.vue';
import { computed, ref } from 'vue';
import OrganizationAvatar from '@/components/dashboard/organization/OrganizationAvatar.vue';

const props = defineProps({
  organization: {
    type: Object,
    required: true
  }
});

const initials = computed(() => {
  return props.organization.name.substring(0, 2).toUpperCase();
});

const organizationInfoModalOpened = ref(false);
const openOrganizationInfosForm = () => {
  organizationInfoModalOpened.value = true;
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';

.personal-infos-bloc {
  display: flex;
  gap: 3rem;
}

.info-item {
  display: flex;

  p {
    margin-bottom: 0;
  }

  > p {
    font-weight: bold;
    width: 100px;
  }
}
</style>
