<template>
  <div class="new-project-step6">
    <p class="progress-title">
      <strong>Création de la simulation en cours...</strong>
      <span>{{ progress.label }}</span>
    </p>
    <div class="loader">
      <span :style="progressStyle"></span>
    </div>
    <p class="meta">{{ progress.description }}</p>
    <ul class="progress-history">
      <li
        v-for="(step, index) in progressHistory"
        :key="'step_' + index"
        :class="progressStepStatusClass(step)"
      >
        {{ step.label }}
      </li>
    </ul>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, reactive, ref, watch } from 'vue';
import { getProgress, getProgressES } from '@/api/simulation';
import { last } from 'lodash';
import forEach from 'lodash/forEach';

const props = defineProps({
  projectData: {
    type: Object,
    default: null
  },
  simulationData: {
    type: Object,
    default: null
  }
});

const progress = reactive({
  percent: 0,
  label: 'Chargement...',
  description: ''
});

//Progress update via EventSource / Mercure
const progressEs = reactive({ data: null });
const progressHistory = ref([]);

onMounted(() => {
  getProgress(props.simulationData.id).then((response) => {
    const progress = response.data;
    forEach(progress, addToProgressHistory);
    updateProgress(last(progress));
  });
  const { data } = getProgressES(props.simulationData.identifier);
  progressEs.data = data;
});
watch(
  () => progressEs.data,
  (latestDataString) => {
    const latestData = JSON.parse(latestDataString);
    updateProgress(latestData);
    addToProgressHistory(latestData);
  }
);
const updateProgress = (progressData) => {
  progress.percent = progressData.progress;
  progress.label = progressData.label;
  progress.description = progressData.description;
  progress.statusCode = progressData.statusCode;
};
const addToProgressHistory = (progressStep) => {
  const existingIndex = progressHistory.value.findIndex((existingStep) => {
    return existingStep.label === progressStep.label;
  });
  if (existingIndex !== -1) {
    progressHistory.value[existingIndex].progress = progressStep.progress;
    progressHistory.value[existingIndex].statusCode = progressStep.statusCode;
  } else {
    progressHistory.value.push(progressStep);
  }
};

const progressStyle = computed(() => {
  let widthPercent = progress.percent;
  if (widthPercent >= 99.2) {
    widthPercent = 99.2; //style limit, fix css to show a full bar
  }
  return {
    width: widthPercent + '%'
  };
});

const progressStepStatusClass = (step) => {
  switch (step.statusCode) {
    case 201:
      return 'waiting';
    case 202:
      return 'doing';
    case 200:
      return 'done';
    case 400:
    case 500:
      return 'error';
    default:
      return '';
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';

.loader {
  margin: 1rem 0;
  width: 100%;
  height: 20px;
  border: 2px solid $blue-1;
  border-radius: 20px;
  position: relative;

  span {
    display: block;
    height: 16px;
    border: 2px solid $white;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: $blue-1;
    position: absolute;
    @include transition(width 1s ease-in-out);
  }
}

.progress-title {
  span {
    margin-left: 10px;
  }
}

.progress-history {
  li {
    &.waiting {
      color: orange;
    }

    &.doing {
      color: blue;
    }

    &.done {
      color: green;
    }

    &.error {
      color: red;
    }
  }
}

.meta {
  color: $blue-1;
}
</style>
