<template>
  <div :class="{ 'in-simulation': isSimulation }" class="breadcrumb-bar">
    <ul>
      <li v-for="(element, index) in breadcrumb" :key="'breadcrumb' + index">
        <router-link
          v-if="element.route"
          :class="{ home: element.label === 'Dashboard' }"
          :to="element.route"
        >
          <i v-if="element.label === 'Dashboard'" class="icon-dashboard rounded"></i>
          {{ element.label }}
        </router-link>
        <span v-else>
          <i v-if="element.label === 'Dashboard'" class="icon-dashboard rounded"></i>
          {{ element.label }}
        </span>
        <i v-if="index < breadcrumb.length - 1" class="icon-carret"></i>
      </li>
    </ul>
    <router-link
      v-if="isSimulationReady && !isResult && isGranted('ROLE_REFERENT') && isGranted('ROLE_ADMIN')"
      :to="{
        name: 'newSimulation',
        params: { projectIdentifier: project.identifier }
      }"
      class="btn primary"
    >
      Créer une simulation à partir de cette simulation
    </router-link>
    <button
      v-if="isSimulationReady && isResult && isGranted('ROLE_ADMIN')"
      class="btn green with-icon"
      @click="$emit('export')"
    >
      Exporter en PDF
    </button>
  </div>
</template>

<script setup>
import { useRoute, useRouter } from 'vue-router';
import { computed, ref, watch } from 'vue';
import { mapState } from '@/store/mappers';
import { routeIsUnder } from '@/router';
import { isGranted } from '@/composables/security';

let route = useRoute();
let router = useRouter();

const breadcrumb = ref([]);
const { project } = mapState('project');
const { simulation, section, category, criteria } = mapState('simulation');

const isCreate = computed(() => {
  return route.name.includes('new');
});

const isNotifications = computed(() => {
  return route.name.includes('notifications');
});

const isResult = computed(() => {
  return (
    criteria.value &&
    category.value &&
    section.value &&
    simulation.value &&
    project.value &&
    routeIsUnder('result', route)
  );
});

const isResults = computed(() => {
  return section.value && simulation.value && project.value && routeIsUnder('results', route);
});

const isSimulation = computed(() => {
  return simulation.value && project.value && routeIsUnder('simulation', route);
});

const isSimulationReady = computed(() => {
  return isSimulation.value && simulation.value.status === 'complete';
});

const isProject = computed(() => {
  return project.value && routeIsUnder('project', route);
});

const buildBreadcrumb = () => {
  let result = [];

  if (router) {
    result.push({
      label: 'Dashboard',
      route: router.resolve({ name: 'dashboard' }).path
    });

    if (isProject.value) {
      result.push({
        label: project.value.name,
        route: isSimulation.value
          ? router.resolve({
              name: 'project',
              params: { projectIdentifier: project.value.identifier }
            }).path
          : null
      });

      if (isSimulation.value) {
        result.push({
          label: simulation.value.name,
          route: isResults.value
            ? router.resolve({
                name: 'simulation',
                params: {
                  simulationIdentifier: simulation.value.identifier,
                  projectIdentifier: project.value.identifier
                }
              }).path
            : null
        });

        if (isResults.value) {
          result.push({
            label: section.value.label,
            route: isResult.value
              ? router.resolve({
                  name: 'results',
                  params: {
                    simulationIdentifier: simulation.value.identifier,
                    projectIdentifier: project.value.identifier,
                    sectionId: section.value.id
                  }
                }).path
              : null
          });

          if (isResult.value) {
            result.push({
              label: criteria.value.label,
              route: null
            });
          }
        }
      } else if (isCreate.value) {
        result.push({
          label: "Création d'une simulation",
          route: null
        });
      }
    } else if (isCreate.value) {
      result.push({
        label: "Création d'un projet",
        route: null
      });

      if (project.value) {
        result.push({
          label: project.value.name,
          route: null
        });
      }
    } else if (isNotifications.value) {
      result.push({
        label: 'Notifications',
        route: null
      });
    } else {
      result.push({
        label: route.meta?.breadcrumbLabel,
        route: null
      });
    }
  }

  return result;
};

watch(
  [route, project, simulation, section],
  ([route]) => {
    breadcrumb.value = buildBreadcrumb(route);
  },
  {
    immediate: true,
    deep: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';

@include device(medium) {
  .breadcrumb-bar {
    &:not(.in-simulation) {
      margin-top: 60px;
    }

    padding: 0.625rem 0 2rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-direction: column;

    ul {
      list-style-type: none;
      margin: 0 0 1rem;
      padding: 0;
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;

      li {
        a {
          color: $blue-1;
          line-height: 1.375rem;
          text-decoration: none;
          font-weight: 700;
          margin: 0 0.625rem;

          i {
            margin-right: 0.3rem;
          }
        }

        span {
          margin: 0 0.625rem;
        }

        i.icon-carret:before {
          color: $blue-2;
        }

        &.active a {
          font-weight: 400;
        }
      }
    }
  }
}

@include device(large) {
  .breadcrumb-bar {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    ul {
      margin: 0;
    }
  }

  li {
    i.icon-carret:before {
      color: $blue-2;
    }

    &.active a {
      font-weight: 400;
    }
  }
}
</style>
