<template>
  <div :id="mapId" ref="map" class="map"></div>
</template>

<script setup>
import { computed, nextTick, ref, watchEffect } from 'vue';
import L from 'leaflet';
import { getColorGradient } from '@/helper/colorHelper';
import 'leaflet.fullscreen';
import 'leaflet.vectorgrid';
import 'leaflet.fullscreen/Control.FullScreen.css';
import { flatMap, forEach } from 'lodash';
import { unobserve } from '@/composables/utils';
import { createLeafletBoundsFromBoundaries } from '@/helper/mapHelper';

const geostats = require('geostats/lib/geostats');

const GRADIENT_STEPS = 5;
const GRADIENT_COLORS = ['#d0e8f5', '#88bedc', '#5BB1DF', '#2c7198', '#1B465E'];
const gradient = getColorGradient(GRADIENT_COLORS, GRADIENT_STEPS);

const props = defineProps({
  category: String,
  resultIndex: Number,
  zoneValues: {
    type: Object,
    default: null
  },
  mapOptions: {
    type: Object,
    default: null
  },
  zones: {
    type: Object,
    default: null
  },
  boundaries: {
    type: Object,
    default: undefined
  },
  originLat: {
    type: Number,
    default: 47.0
  },
  originLng: {
    type: Number,
    default: 2.0
  },
  originZoom: {
    type: Number,
    default: 8
  }
});

const emit = defineEmits(['legendDefined']);

let map = ref(null); //DOM reference
let mapInstance = ref(null);
let mapId = computed(() => {
  //Unique id for map (needed for leaflet)
  return 'map_' + props.category + props.resultIndex;
});
let geoJsonLayer = ref(null);

//Create leaflet instance
const setupMap = (indexedZoneValues, mapOptions, mapZones) => {
  mapInstance.value = L.map(mapId.value, {
    scrollWheelZoom: false,
    fullscreenControl: true,
    fullscreenControlOptions: {
      position: 'topleft'
    }
  });

  if (props.boundaries !== undefined) {
    const bounds = createLeafletBoundsFromBoundaries(props.boundaries);
    mapInstance.value.fitBounds(bounds);
  } else {
    //Fallback
    mapInstance.value.setView([props.originLat, props.originLng], props.originZoom);
  }

  //Create map background (Openstreetmap)
  L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
    attribution:
      '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
  }).addTo(mapInstance.value);

  //Calculate jenksClasses (to handle gradients) - only one time for optimization
  //See: https://en.wikipedia.org/wiki/Jenks_natural_breaks_optimization
  const flatZoneValues = flatMap(indexedZoneValues, (value) => parseFloat(value));
  const gs = new geostats(flatZoneValues);
  const jenksClasses = gs.getJenks(GRADIENT_STEPS);

  //console.log("TEST1");
  //console.log(mapZones);
  //Add zones layer
  geoJsonLayer.value = L.vectorGrid
    .slicer(unobserve(mapZones), {
      interactive: true,
      maxZoom: 18,
      tolerance: 15,
      vectorTileLayerStyles: {
        sliced: function (properties) {
          //console.log("TEST2");

          // console.log("properties");
          // console.log(properties);
          let value;
          //console.log(properties.carro200_id);
          if (properties.zone != null) {
            value = indexedZoneValues[properties.zone];
          } else if (properties.carro1000_id != null) {
            value = indexedZoneValues[properties.carro1000_id];
          } else if (properties.carro500_id != null) {
            value = indexedZoneValues[properties.carro500_id];
          } else {
            value = indexedZoneValues[properties.carro200_id];
          }

          let gradientColor = getValueGradientColor(value, jenksClasses);
          return {
            fillColor: gradientColor !== undefined ? gradientColor.toString() : null,
            fillOpacity: 0.7,
            color: '#888',
            weight: 1,
            fill: true
          };
        }
      }
    })
    //Handle popup
    .on('click touch', function (e) {
      const layer = e.layer;
      const properties = layer.properties;

      let value = indexedZoneValues[properties.zone];
      let units = zoneUnits.value.y !== undefined ? ' ' + zoneUnits.value.y : '';
      //Popup with data
      let content =
        '<strong>' +
        formatNumber(value) +
        units +
        '</strong>' +
        '<br/>Nom de la zone : ' +
        properties.nom +
        '<br/>Code de la zone : ' +
        properties.zone +
        '<br/>Population : ' +
        formatNumber(properties.population, 0);
      L.popup().setContent(content).setLatLng(e.latlng).openOn(mapInstance.value);
    })
    .addTo(mapInstance.value);

  //Emit legend
  if (null !== mapOptions) {
    emit('legendDefined', createLegend(jenksClasses, gradient, mapOptions.scales.y.units));
  }
};

const zoneUnits = computed(() => {
  let units = {};
  const scales = props.data?.options?.scales;
  if (scales !== undefined) {
    forEach(scales, (scale, identifier) => {
      if (scale.units !== undefined) {
        units[identifier] = scale.units;
      }
    });
  }
  return units;
});

const formatNumber = (number, decimals = 2) => {
  return new Intl.NumberFormat('fr-FR', {
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals
  }).format(number);
};

const getValueGradientColor = (value, jenksClasses) => {
  let step = jenksClasses.findIndex((classValue, classIndex, classTable) => {
    if (value <= classValue && value >= classTable[classIndex - 1]) {
      return true;
    } else {
      return false;
    }
  });
  step = step - 1;
  return gradient[step];
};

const createLegend = (jenksClasses, colors, units) => {
  let legend = [];

  for (let i = 0; i < jenksClasses.length - 1; i++) {
    const value = jenksClasses[i] + ' - ' + jenksClasses[i + 1] + ' ' + units;
    const color = colors[i];
    legend.push({ value, color });
  }

  return legend;
};

const getBoundariesParam = (param, boundaries) => {
  return boundaries.find((boundary) => boundary.param === param)?.values;
};

//Create map on data change
watchEffect(() => {
  //console.log("TEST")
  if (props.zoneValues !== null && props.mapOptions !== null && props.zones !== null) {
    nextTick(() => setupMap(props.zoneValues, props.mapOptions, props.zones));
  }
});
</script>

<style lang="scss" scoped>
.map {
  height: 400px;
  z-index: 1;
}
</style>
