<template>
  <div class="comment">
    <div class="comment-title">
      <img class="rounded" src="https://via.placeholder.com/32x32.png?" />
      <div>
        <div>
          <p class="block-title">Opération par véhicule</p>
          <p><span class="date">Le 01/01/2022</span>Célia Elidrissi a ajouté un commentaire</p>
        </div>
      </div>
    </div>
    <div class="comment-text">
      <p>
        {{ demoTexts[demo] }}
      </p>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';

defineProps({
  demo: {
    type: Number,
    default: 0
  }
});

const demoTexts = [
  "Voir l'impact de la ZFE sur le parc de véhicules, lancer un GT avec les opérateurs",
  "Planifier une remise à plat du schéma d'implantation des AL.",
  "Refaire une simulation à l'échelle de la communauté d'agglo ?"
];
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
// @import "@/assets/scss/commons/_icon-font.scss";
// @import "@/assets/scss/commons/_mixins.scss";
// @import "@/assets/scss/commons/_buttons.scss";
// @import "@/assets/scss/commons/_mediaqueries.scss";
.comment {
  padding: 1.5rem 0;
  border-bottom: 1px solid $grey-3;

  .comment-title {
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: stretch;

    img {
      margin-right: 0.5rem;
    }

    > div {
      width: 100%;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
    }

    .block-title {
      color: $blue-2;
      line-height: 1.1rem;
    }

    .block-title + p {
      color: $blue-1;

      span {
        margin-right: 0.5rem;
      }
    }
  }

  .comment-text {
    p {
      margin: 0;
    }
  }
}
</style>
