<template>
  <div v-if="ready" class="project">
    <project-title :project="project" />
    <project-map-block :project="project" />
    <!--    <project-description :project="project" />-->
    <project-consultations />
    <div class="block split" v-if="isGranted('ROLE_ADMIN')">
      <comments />
      <project-activity />
    </div>
  </div>
</template>

<script setup>
import Comments from '../components/commons/Comments';
import ProjectTitle from '../components/project/ProjectTitle';
import ProjectMapBlock from '../components/project/ProjectMapBlock';
import ProjectDescription from '../components/project/ProjectDescription';
import ProjectConsultations from '../components/project/ProjectConsultations';
import ProjectActivity from '../components/project/ProjectActivity';
import { onMounted, ref, watch } from 'vue';
import { mapActions, mapState } from '@/store/mappers';
import { isGranted } from '@/composables/security';

const props = defineProps({
  projectIdentifier: String
});
const ready = ref(false);
const { project } = mapState('project');
const { fetchProject } = mapActions('project');

//Lifecycle
const load = () => {
  fetchProject({ identifier: props.projectIdentifier }).then((response) => {
    ready.value = true;
  });
};
onMounted(() => {
  load();
});
watch(
  () => props.projectIdentifier,
  () => {
    load();
  },
  {
    immediate: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';

.btn-row {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 2rem;
}
</style>
