<template>
  <div v-if="category && criteria">
    <filters :available="availableFilters" :representation="resultRepresentation" />

    <!-- Two third layout and "perimètre" & "légende" blocks -->
    <div :class="legend ? 'split-two-third' : ''">
      <div class="block" id="jpeg-download-zone">
        <h2 class="block-title">
          {{ criteria.label }}
        </h2>
        <div class="graph">
          <result-loader
            v-if="isRequestReady"
            :category="category"
            :criteria="criteria"
            :project="project"
            :result-index="resultIndex"
            :section="section"
            :simulation="simulation"
            :zones="zones"
            @loaded="onLoaded"
            @map-legend-defined="setLegend"
            @customZonesLoaded="setZones"
          ></result-loader>
        </div>
      </div>
      <div v-if="legend">
        <!-- <div class="block">
          <h2 class="block-title">Périmètre d'affichage</h2>
        </div> -->
        <div class="block">
          <h2 class="block-title">Légende</h2>
          <ul class="legend-list">
            <li v-for="point in legend" :key="'legend_' + point.color">
              <div class="legend-point" :style="{ 'background-color': point.color }"></div>
              <a class="legend-link" href="javascript:void(0)" v-if="point.index != undefined">{{
                point.value
              }}</a>
              <span v-else>{{ point.value }}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <data-table v-if="tableData" :data="tableData" @export="exportChartData"></data-table>
  </div>
</template>

<script setup>
import { computed, defineProps, onMounted, ref, watch } from 'vue';
import { mapActions, mapGetters, mapMutations, mapState } from '../store/mappers';
import find from 'lodash/find';
import { transformObjectWithKeyAsProperty } from '../helper/objectHelper';
import { exportData, getVersionKey } from '../composables/result';
import ResultLoader from '../components/result/ResultLoader';
import DataTable from '../components/result/DataTable';
import Filters from '../components/filters/Filters';
import { every } from 'lodash';

const props = defineProps({
  projectIdentifier: String,
  simulationIdentifier: String,
  sectionId: String,
  categoryId: String,
  criteriaId: String
});

const { fetchProject } = mapActions('project');
const { fetchSimulation } = mapActions('simulation');
const { setCurrentSection, setCurrentCategory, setCurrentCriteria } = mapMutations('simulation');
const { project } = mapState('project');
const { sections } = mapState('navigation');
const { simulation, section, category, criteria } = mapState('simulation');
const { getCriteriaIndex } = mapGetters('navigation', 'function');
const { getZonesBySimulation } = mapGetters('simulation', 'function');
const { fetchZonesBySimulation } = mapActions('simulation');

const resultRepresentation = computed(() => {
  return {
    project: project.value ? project.value.identifier : null,
    simulation: simulation.value ? simulation.value.identifier : null,
    section: section.value ? section.value.id : null,
    category: category.value ? category.value.id : null,
    criteria: criteria.value ? criteria.value.id : null
  };
});

const resultIndex = computed(() => {
  return isRequestReady.value ? getCriteriaIndex(resultRepresentation.value) : null;
});

const projectAndSimulationLoaded = computed(() => {
  return (
    simulation.value !== undefined &&
    simulation.value !== null &&
    project.value !== undefined &&
    project.value !== null
  );
});

const loading = ref(false);
const tableData = ref(null);
const legend = ref(null);
const zones = ref(null);
const availableFilters = ref(null);
const resultRequestWithFilters = ref(null);

const onLoaded = ({ data, request }) => {
  setTableData(data);
  setAvailableFilters(data);
  setResultRequestWithFilters(request);
};

const setTableData = (data) => {
  tableData.value = data;
};
const setAvailableFilters = (data) => {
  availableFilters.value = data.filters;
};
const setResultRequestWithFilters = (request) => {
  resultRequestWithFilters.value = request;
};
const setLegend = (data) => {
  legend.value = data;
  window.dispatchEvent(new Event('resize')); //to recenter map
};

const setZones = (data) => {
  zones.value = data;
};

const exportChartData = (request) => {
  resultRequestWithFilters.value.version = getVersionKey(project.value, simulation.value);
  exportData(resultRequestWithFilters.value);
};

const isRequestReady = computed(() => {
  let ready = every(resultRepresentation.value, (value, key) => {
    if (value === null || value === undefined) {
      return false;
    }
    const propValue =
      props[key + 'Identifier'] !== undefined ? props[key + 'Identifier'] : props[key + 'Id'];
    return value === propValue;
  });
  return ready && !loading.value;
});

onMounted(() => {
  load();
});

//General load (project, simulation, section, category)
const load = () => {
  loading.value = true;
  fetchProject({ identifier: props.projectIdentifier });
  fetchSimulation({ identifier: props.simulationIdentifier });

  if (props.sectionId) {
    let currentSection = find(sections.value, (section, sectionId) => {
      return sectionId === props.sectionId;
    });
    if (currentSection) {
      setCurrentSection(
        transformObjectWithKeyAsProperty(currentSection, 'id', props.sectionId, true)
      );
      let currentCategory = find(currentSection.categories, (category, categoryId) => {
        return categoryId === props.categoryId;
      });
      if (currentCategory) {
        setCurrentCategory(currentCategory);
        let currentCriteria = find(currentCategory.criterias, (criteria, criteriaId) => {
          return criteriaId === props.criteriaId;
        });
        if (currentCriteria) {
          setCurrentCriteria(currentCriteria);
        }
      }
    }
  }
  loading.value = false;
};

//Load zones only once for a given simulation
const loadZones = () => {
  const zoneDefinition = {
    project: project.value,
    simulation: simulation.value
  };
  const existingZones = getZonesBySimulation(zoneDefinition);
  if (null === existingZones) {
    fetchZonesBySimulation({
      project: project.value,
      simulation: simulation.value
    }).then(() => {
      zones.value = getZonesBySimulation(zoneDefinition).data;
    });
  } else {
    zones.value = existingZones.data;
  }
};

watch(
  () => props.simulationIdentifier,
  () => {
    load();
  }
);
watch(
  () => props.projectIdentifier,
  () => {
    load();
  }
);
watch(
  () => props.sectionId,
  () => {
    load();
  }
);
watch(
  () => props.categoryId,
  () => {
    load();
  }
);
watch(
  () => props.criteriaId,
  () => {
    load();
  }
);
watch(sections, load);

//Load zones as soon as project and simulation are both loaded
watch(
  projectAndSimulationLoaded,
  (isLoaded) => {
    if (isLoaded) {
      loadZones();
    }
  },
  { immediate: true }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_result.scss';

ul.legend-list {
  list-style-type: none;
  padding: 0;

  .legend-point {
    width: 10px;
    height: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .legend-link {
    color: #1b465e;
    text-decoration: none;
    font-size: 14px;
  }
}
</style>
