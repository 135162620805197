import { format } from 'date-fns';

export const formatDate = (date, dateFormat = "dd/MM/yyyy'") => {
  return format(new Date(date), dateFormat);
};

export const formatNumber = (number, locale = undefined) => {
  return number.toLocaleString(
    locale // leave undefined to use the visitor's browser
    // locale or a string like 'en-US' to override it.
    // { minimumFractionDigits: 2 }
  );
};
