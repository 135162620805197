import organizationApi from '@/api/organization';

export const updateOrganization = (organizationId, data, callback, errorCallback) => {
  organizationApi.updateOrganization(organizationId, data).then(callback).catch(errorCallback);
};

export const uploadAvatar = (organizationId, data, callback, errorCallback) => {
  organizationApi.uploadAvatar(organizationId, data).then(callback).catch(errorCallback);
};

export const removeAvatar = (organizationId, callback, errorCallback) => {
  organizationApi.removeAvatar(organizationId).then(callback).catch(errorCallback);
};

export const createOrganizationUser = (organizationId, data, callback, errorCallback) => {
  organizationApi.createOrganizationUser(organizationId, data).then(callback).catch(errorCallback);
};

export const updateOrganizationUser = (organizationId, userId, data, callback, errorCallback) => {
  organizationApi
    .updateOrganizationUser(organizationId, userId, data)
    .then(callback)
    .catch(errorCallback);
};

export const toggleOrganizationUserActivation = (
  organizationId,
  userId,
  callback,
  errorCallback
) => {
  organizationApi
    .toggleOrganizationUserActivation(organizationId, userId)
    .then(callback)
    .catch(errorCallback);
};

export const inviteOrganizationUser = (organizationId, userId, callback, errorCallback) => {
  organizationApi
    .inviteOrganizationUser(organizationId, userId)
    .then(callback)
    .catch(errorCallback);
};

export const deleteOrganizationUser = (organizationId, userId, callback, errorCallback) => {
  organizationApi
    .deleteOrganizationUser(organizationId, userId)
    .then(callback)
    .catch(errorCallback);
};
