import { authenticatedApi as api, mercureURL } from './api';
import { useEventSource } from '@vueuse/core';

export const putNotification = (id, data) => {
  return api().put('/notifications/' + id, data);
};

export const getNotifications = () => {
  return api().get('/notifications');
};

export const getNotificationsES = (userId) => {
  return useEventSource(mercureURL + '?topic=/notifications/' + userId);
};

export default {
  getNotifications
};
