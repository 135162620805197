<template>
  <router-link
    :to="{
      name: 'notifications'
    }"
    class="nav-item"
  >
    <i
      :class="{
        'icon-notification': true,
        rounded: true,
        'has-notification': hasUnreadNotifications
      }"
    ></i>
    <span class="remove-on-collapse">
      Notifications
      <span v-if="hasUnreadNotifications">({{ unreadNotificationsCount }})</span></span
    >
  </router-link>
</template>

<script setup>
import { onMounted, reactive, watch } from 'vue';
import { mapState, mapGetters, mapMutations } from '@/store/mappers';
import { refreshNotificationsCount } from '@/composables/navigation';
import { getNotificationsES } from '@/api/notification';

const { unreadNotificationsCount } = mapState('notifications');
const { hasUnreadNotifications } = mapGetters('notifications');
const { addNotifications } = mapMutations('notifications');

// Notifications update via EventSource / Mercure
const notificationsEs = reactive({ data: null });
const props = defineProps({
  user: Object
});

onMounted(() => {
  refreshNotificationsCount();
});

watch(
  () => notificationsEs.data,
  (latestNotificationString) => {
    const latestNotification = JSON.parse(latestNotificationString);
    addNotifications(latestNotification);
  }
);

watch(
  () => props.user,
  (newUser) => {
    const { data } = getNotificationsES(newUser.id);
    notificationsEs.data = data;
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
.has-notification {
  position: relative;

  &:after {
    content: '';
    width: 0.625rem;
    height: 0.625rem;
    display: inline-block;
    border-radius: 50%;
    background-color: $alert;
    position: absolute;
    bottom: -3px;
    right: -4px;
  }
}
</style>
