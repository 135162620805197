<template>
  <div class="new-project-step1 grey-block">
    <div class="form-container-grey-bg">
      <label class="label" for="project-name">Indiquer un nom pour votre projet</label>
      <input id="project-name" placeholder="Nom" v-model="projectName" />
    </div>
    <div class="form-container-grey-bg">
      <label class="label" for="project-description"
        >Description de votre projet (facultatif)</label
      >
      <textarea id="project-description" placeholder="Description" v-model="projectDescription">
      </textarea>
    </div>
  </div>
  <create-project-actions
    @next="submit"
    has-next
    :is-valid="isValid"
    :submitting="submitting"
    :error-message="errorMessage"
  >
  </create-project-actions>
</template>

<script setup>
import { computed, defineEmits, defineProps, onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';
import { errorMessage, submitting } from '../../../composables/createProject';
import CreateProjectActions from './CreateProjectActions.vue';
import { postProject, putProject } from '../../../api/project';
import { mapMutations } from '../../../store/mappers';
import { refreshNav } from '../../../composables/navigation';

//Dependencies
const router = useRouter();

//Props
const props = defineProps({
  projectData: {
    type: Object,
    default: null
  }
});

//Form values
const projectName = ref('');
const projectDescription = ref('');

//Edit mode
let editing = false;
onMounted(() => {
  if (props.projectData !== null) {
    projectName.value = props.projectData.name;
    projectDescription.value = props.projectData.description;
    editing = true;
  }
});

//Submission
const isValid = computed(() => {
  return projectName.value !== '';
});
const emit = defineEmits(['next', 'previous', 'substep', 'go-to-step']);
const { setCurrentProject } = mapMutations('project');
const submit = () => {
  submitting.value = true;
  const data = {
    name: projectName.value,
    description: projectDescription.value
  };

  if (editing) {
    putProject(props.projectData.id, data)
      .then(handleSuccess)
      .catch((error) => {
        errorMessage.value = error.message;
      })
      .finally(() => {
        submitting.value = false;
      });
  } else {
    postProject(data)
      .then(handleSuccess)
      .catch((error) => {
        errorMessage.value = error.message;
      })
      .finally(() => {
        submitting.value = false;
      });
  }
};
const handleSuccess = (response) => {
  if ([200, 201].includes(response.status)) {
    const newProjectData = response.data;
    setCurrentProject(newProjectData);
    refreshNav();

    if (response.status === 201) {
      router.push({
        name: 'newProject',
        params: { projectIdentifier: newProjectData.identifier }
      });
    }

    emit('next', newProjectData);
  } else {
    errorMessage.value = 'Erreur inconnue : statut ' + response.status;
  }
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';
</style>
