<template>
  <div class="user-list-row">
    <div
      class="user-avatar"
      :style="{ backgroundImage: 'url(' + avatarSrc + ')' }"
      @click="showEditUserModal"
    >
      <span v-if="!avatarSrc">{{ initials }}</span>
      <span class="badge" v-if="isGranted('ROLE_REFERENT', user)"
        ><i class="icon icon-user-admin"></i
      ></span>
    </div>
    <div>
      <h3 class="user-name" @click="showEditUserModal">
        {{ user.personalInfos.firstName }} {{ user.personalInfos.lastName }}
      </h3>
      <small v-if="isGranted('ROLE_REFERENT', user)">Administrateur</small>
    </div>
    <div class="projects-list">
      <div v-for="project in sortedProjects" :key="'user' + user.id + '_project' + project.id">
        <tooltip theme="light">
          <button
            class="rounded-icon"
            :class="{ shadow: project.role === 'manager' }"
            :style="{
              'background-color': getColor(getProjectIndexByIdentifier(project.project.identifier))
            }"
          >
            {{ getShortName(project.project.name) }}
          </button>
          <template #tooltip>
            <div class="project-tooltip">
              <i
                class="rounded-icon"
                :class="{ shadow: project.role === 'manager' }"
                :style="{
                  'background-color': getColor(
                    getProjectIndexByIdentifier(project.project.identifier)
                  )
                }"
              >
                {{ getShortName(project.project.name) }}
              </i>
              <h1>{{ project.project.name }}</h1>
              <div>
                <i class="icon" :class="'icon-user-' + project.role"></i>
                <span v-if="project.role === 'manager'">Modélisateur</span>
                <span v-else-if="project.role === 'collaborator'">Collaborateur</span>
                <span v-else>Utilisateur</span>
              </div>
            </div>
          </template>
        </tooltip>
      </div>
      <s-button icon="icon-plus" color="white" size="sm" @click="showEditProjectsModal"></s-button>
    </div>
    <div class="row-actions">
      <s-button-toggle
        @click="toggleEnable"
        :enabled="!user.locked"
        v-if="loggedUser.id !== user.id && user.enabled === true"
      >
        <template v-if="!user.locked">Activé</template>
        <template v-else>Désactivé</template>
      </s-button-toggle>

      <tooltip theme="light" v-if="loggedUser.id !== user.id && user.enabled === false">
        <s-button v-if="sendingMail" icon="icon-spinner spin" size="sm">Envoi en cours</s-button>
        <s-button v-else size="sm" icon="icon-forward-to-inbox" @click="sendMail"></s-button>
        <template #tooltip>
          <span v-if="user.registrationNotifiedAt === null"
            >Cet utilisateur n'a pas encore reçu de mail d'activation.</span
          >
          <span v-else
            >Dernier mail envoyé le {{ formatDate(user.registrationNotifiedAt) }} à
            {{ formatDate(user.registrationNotifiedAt, 'H:m') }}</span
          >
        </template>
      </tooltip>

      <s-button icon="icon-edit-page" size="sm" @click="showEditUserModal"></s-button>

      <s-button-confirm
        icon="icon-bin"
        confirm-label="Confirmer la suppression"
        @confirm="deleteUser"
        v-if="loggedUser.id !== user.id"
      ></s-button-confirm>
    </div>
  </div>
</template>

<script setup>
import { getColor } from '@/helper/colorHelper';
import { formatDate } from '@/helper/formatHelper';
import { getShortName } from '@/composables/project';
import { mapGetters, mapMutations, mapState } from '@/store/mappers';
import { computed, ref } from 'vue';
import { apiURL } from '@/api/api';
import { findMediaByIdentifier } from '@/helper/mediaHelper';
import Tooltip from '@/components/commons/Tooltip.vue';
import SButton from '@/components/commons/SButton.vue';
import SButtonToggle from '@/components/commons/SButtonToggle.vue';
import SButtonConfirm from '@/components/commons/SButtonConfirm.vue';
import { useToast } from 'vue-toastification';
import { extractErrorMessage } from '@/helper/formHelper';
import { isGranted } from '@/composables/security';
import {
  inviteOrganizationUser,
  toggleOrganizationUserActivation,
  deleteOrganizationUser
} from '@/composables/organization';
import { orderBy } from 'lodash/collection';

const { getProjectIndexByIdentifier } = mapGetters('project');
const { removeUser, updateUser } = mapMutations('organization');
const { user: loggedUser } = mapState('security');
const emit = defineEmits(['edit-user', 'edit-projects']);
const props = defineProps({
  user: Object
});
const toast = useToast();

// Project management
const sortedProjects = computed(() => {
  return orderBy(props.user.projects, ['role', 'project.name'], ['desc', 'asc']);
});

// Avatar management
const initials = computed(() => {
  return props.user.personalInfos.lastName.substring(0, 1).toUpperCase();
});
const avatar = computed(() => {
  return findMediaByIdentifier(props.user.medias, 'avatar');
});
const avatarSrc = computed(() => {
  if (apiURL && avatar.value) {
    return (
      apiURL +
      '/medias/users/' +
      avatar.value.resourceIdentifier +
      '/avatar/' +
      avatar.value.originalName
    );
  }
  return null;
});

// Invitation mail management
const sendingMail = ref(false);
const sendMail = () => {
  sendingMail.value = true;
  inviteOrganizationUser(
    loggedUser.value.organization.id,
    props.user.id,
    (response) => {
      updateUser(response.data);
      toast.success("un email d'activation a été envoyé à l'utilisateur", {
        timeout: 3000,
        position: 'bottom-center'
      });
      sendingMail.value = false;
    },
    (error) => {
      toast.error(extractErrorMessage(error.response), {
        timeout: 5000,
        position: 'bottom-center'
      });
    }
  );
};

// Enable management
const toggleEnable = () => {
  toggleOrganizationUserActivation(
    loggedUser.value.organization.id,
    props.user.id,
    (response) => {
      updateUser(response.data);
    },
    (error) => {
      toast.error(extractErrorMessage(error.response), {
        timeout: 5000,
        position: 'bottom-center'
      });
    }
  );
};

// User deletion management
const deleteUser = () => {
  deleteOrganizationUser(
    loggedUser.value.organization.id,
    props.user.id,
    (response) => {
      removeUser(props.user);
      toast.success("L'utilisateur a été supprimé.", {
        timeout: 2000,
        position: 'bottom-center'
      });
    },
    (error) => {
      toast.error(extractErrorMessage(error.response), {
        timeout: 5000,
        position: 'bottom-center'
      });
    }
  );
};

// Edition management
const showEditUserModal = () => {
  emit('edit-user', props.user);
};
const showEditProjectsModal = () => {
  emit('edit-projects', props.user);
};
</script>

<style scoped lang="scss">
@import '@/assets/scss/commons/_colors.scss';

.rounded-icon {
  color: $white;
  font-size: 0.688rem;
  font-weight: normal;
  font-style: normal;
  width: 2rem;
  height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: 0;

  &.shadow {
    box-shadow: 0 0 0 5px rgba(52, 161, 175, 0.4);
  }
}
.user-list-row {
  display: grid;
  grid-template-columns: 2rem 200px 1fr auto;
  gap: 1rem;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 0;
  border-top: 1px solid $grey-3;

  .user-avatar {
    background-color: $turquoise;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 2rem;
    height: 2rem;
    border-radius: 100%;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $white;
    position: relative;
    .badge {
      position: absolute;
      background-color: $grey-3;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 16px;
      height: 16px;
      border-radius: 100%;
      font-size: 8px;
      bottom: 0;
      right: -8px;
    }
  }
  .user-avatar,
  .user-name {
    cursor: pointer;
  }

  .user-name {
    margin: 0;
  }

  .projects-list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    :deep(.s-button) {
      margin: 0 1rem;
    }
  }

  .row-actions {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: flex-end;
  }
}
.project-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  .rounded-icon {
    width: 4.75rem;
    height: 4.75rem;
    font-size: 2.625rem;
  }
  > h1 {
    font-size: 1.25rem;
    text-transform: uppercase;
  }
  > div {
    font-size: 0.875rem;
    font-weight: bold;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    justify-content: center;
    .icon {
      font-size: 2rem;
    }
  }
}
</style>
