//FROM https://medium.com/geekculture/mapgetters-with-vue3-vuex4-and-script-setup-5827f83930b4
import { computed, reactive } from 'vue';
import store from '../store/index';

const forModule = (section, module = null) => {
  return module ? section[module] : section;
};

const stripModulePrefix = (name, module = null) => {
  return name.replace(module + '/', '');
};

const mapState = (module = null, type = 'computed') => {
  const state = forModule(store.state, module);
  return Object.fromEntries(
    Object.keys(state).map((key) => {
      if (type === 'reactive') {
        return [key, reactive(state[key])];
      } else {
        return [key, computed(() => state[key])];
      }
    })
  );
};

const mapGetters = (module = null, type = 'computed') => {
  return Object.fromEntries(
    Object.keys(store.getters).map((getter) => [
      stripModulePrefix(getter, module),
      type === 'function'
        ? (args) => store.getters[getter](args)
        : computed(() => store.getters[getter])
    ])
  );
};

const mapMutations = (module = null) => {
  return Object.fromEntries(
    Object.keys(store._mutations).map((mutation) => [
      stripModulePrefix(mutation, module),
      (value) => store.commit(mutation, value)
    ])
  );
};

const mapActions = (module = null) => {
  return Object.fromEntries(
    Object.keys(store._actions).map((action) => [
      stripModulePrefix(action, module),
      (value) => store.dispatch(action, value)
    ])
  );
};

export { mapState, mapGetters, mapMutations, mapActions };
