<template>
  <div>
    <div v-if="isGranted('ROLE_DEMO')">Inaccessible en mode démo.</div>
    <div v-else>
      <div v-if="user" :class="headerClass">
        <template v-if="user && isGranted('ROLE_REFERENT')">
          <div class="block">
            <user-name-and-group :user="user" />
          </div>
          <!--        <licence-info v-if="currentSubscription" :subscription="currentSubscription" />-->
        </template>
        <user-name-and-group v-else :user="user" />
      </div>

      <Tabs :value="activeTab" @update="setActiveTab" :items="tabsItem" />

      <TabAccount v-if="activeTab === 'account'" :user="user" />
      <TabProjects v-else-if="activeTab === 'projects'" />
      <TabUsers v-else-if="activeTab === 'users'" />
    </div>
  </div>
</template>

<script setup>
import { mapState } from '@/store/mappers';
import { computed, ref } from 'vue';
import { isGranted } from '@/composables/security';
import UserNameAndGroup from '@/components/dashboard/user/UserNameAndGroup.vue';
import LicenceInfo from '@/components/dashboard/LicenceInfo.vue';
import Tabs from '@/components/commons/Tabs.vue';
import TabAccount from '@/components/dashboard/TabAccount.vue';
import TabUsers from '@/components/dashboard/TabUsers.vue';
import TabProjects from '@/components/dashboard/TabProjects.vue';
import { useRoute } from 'vue-router';

const { user } = mapState('security');
let route = useRoute();

const headerClass = computed(() => {
  // return user && isGranted('ROLE_REFERENT') ? 'split' : 'block';
  return user && isGranted('ROLE_REFERENT') ? '' : 'block';
});

const currentSubscription = computed(() => {
  if (user && user.value.organization) {
    return user.value.organization.licences[0];
  }
  return null;
});

// Tabs
const activeTab = computed(() => {
  return route.name;
});
const tabsItem = computed(() => {
  let items = [
    {
      label: 'Moi',
      value: 'account'
    }
    // {
    //   label: 'Mes projets',
    //   value: 'projects'
    // }
  ];
  if (user && isGranted('ROLE_REFERENT')) {
    items.push({
      label: "Utilisateurs de l'organisation",
      value: 'users'
    });
  }
  return items;
});
const setActiveTab = (value) => {
  activeTab.value = value;
};
</script>

<style lang="scss" scoped></style>
