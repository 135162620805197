<template>
  <li class="simulation">
    <router-link
      :class="{ active: isCurrentSimulation }"
      :to="{
        name: 'simulation',
        params: {
          simulationIdentifier: simulation.identifier,
          projectIdentifier: project.identifier
        }
      }"
    >
      <i v-if="simulation.status !== 'complete'" class="icon-time"></i>
      {{ simulation.name }}
    </router-link>
  </li>
</template>

<script setup>
import { defineProps, ref, watch } from 'vue';
import { mapState } from '../../store/mappers';

import { useRoute } from 'vue-router';

const route = useRoute();

const { simulation: currentSimulation } = mapState('simulation');

const props = defineProps({
  simulation: Object,
  project: Object
});

const isCurrentSimulation = ref(null);

watch(
  [currentSimulation, route],
  ([currentSimulation, currentRoute]) => {
    if (currentSimulation && props.simulation && currentRoute) {
      isCurrentSimulation.value =
        props.simulation.identifier === currentSimulation.identifier &&
        currentRoute.params.simulationIdentifier === currentSimulation.identifier;
    } else {
      isCurrentSimulation.value = false;
    }
  },
  {
    immediate: true,
    deep: true
  }
);
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_accordion.scss';

.simulation {
  padding: 0.75rem 0;
  border-bottom: 1px solid $grey-3;
  position: relative;

  a {
    color: $blue-1;
    font-size: 0.875rem;
    line-height: 1.18rem;
    text-align: left;
    text-decoration: none;
    padding-left: 1.125rem;
    display: block;

    &:before {
      content: '';
      display: block;
      width: 0.5rem;
      height: 0.375rem;
      background-image: url('~@/assets/img/layout/return-arrow.svg');
      background-size: 100%;
      background-position: center;
      position: absolute;
      left: 0;
      top: 1rem;
    }

    &.router-link-exact-active,
    &.active {
      font-weight: 600;
    }
  }

  &:last-child {
    border: none;
  }
}
</style>
