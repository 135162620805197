import { findIndex } from 'lodash';

const BREAKPOINT_DESKTOP_MOBILE = 800;

const state = {
  sidenavOpenDesktop: true,
  sidenavOpenMobile: false,
  sections: []
};

const getters = {
  sidenavOpen:
    (state) =>
    (windowWidth = BREAKPOINT_DESKTOP_MOBILE) => {
      return windowWidth >= BREAKPOINT_DESKTOP_MOBILE
        ? state.sidenavOpenDesktop
        : state.sidenavOpenMobile;
    },
  getCriteriaIndex: (state) => (args) => {
    const { section, category, criteria } = args;
    const criterias = state.sections[section]?.categories[category]?.criterias;
    if (criterias === undefined) {
      return null;
    }
    return findIndex(Object.keys(criterias), (lookup) => {
      return lookup === criteria;
    });
  }
};

const mutations = {
  setSidenavOpen(state, { open = null, windowWidth = BREAKPOINT_DESKTOP_MOBILE }) {
    if (windowWidth >= BREAKPOINT_DESKTOP_MOBILE) {
      state.sidenavOpenDesktop = open ? open : !state.sidenavOpenDesktop;
    } else {
      state.sidenavOpenMobile = open ? open : !state.sidenavOpenMobile;
    }
  },
  setSections(state, payload) {
    state.sections = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations
};
