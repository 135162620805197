import { createI18n } from 'vue-i18n';
import { getTranslations } from './api/translations';
import axios from 'axios';

const loadedTranslations = [];

const DEFAULT_LOCALE = 'fr';

export const i18n = createI18n({
  legacy: false, // you must set `false`, to use Composition API
  locale: DEFAULT_LOCALE, // set locale
  fallbackLocale: DEFAULT_LOCALE // set fallback locale
});

export const setI18nLanguage = (lang) => {
  i18n.locale = lang;
  axios.defaults.headers.common['Accept-Language'] = lang;
  document.querySelector('html').setAttribute('lang', lang);
  return lang;
};

export const loadLanguageAsync = (lang, slug) => {
  if (typeof lang === 'undefined') {
    lang = DEFAULT_LOCALE; //TODO: something better, maybe using navigator language after verifying available languages?
    //lang = window.navigator.userLanguage || window.navigator.language;
    //lang = lang.slice(0,2);
  }
  const translations = lang + '.' + slug;
  if (loadedTranslations.includes(translations)) {
    if (i18n.locale !== lang) setI18nLanguage(lang);
    return Promise.resolve();
  }
  return getTranslations(slug, lang).then((response) => {
    loadedTranslations.push(translations);
    i18n.global.setLocaleMessage(lang, response.data);
    return setI18nLanguage(lang);
  });
};

export const handleI18n = (to, from, next) => {
  if (to.meta?.translations !== undefined) {
    loadLanguageAsync(i18n.locale, to.meta.translations).then(() => next());
  } else {
    next();
  }
};
