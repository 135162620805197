import tinycolor from 'tinycolor2';
import tinygradient from 'tinygradient';

export const colors = [
  '#C766A4',
  '#8E3D71',
  '#B63C34',
  '#EE7926',
  '#EEAA26',
  '#FDBF6F',
  '#B2847A',
  '#246C94',
  '#88BEDC',
  '#6A969F',
  '#8BB532',
  '#297924'
];

export const getColor = (index, alpha = 1, offset = 0) => {
  let offsetIndex = index + offset;
  let availableColors = colors.length;
  let colorIndex = offsetIndex % availableColors;
  let level = Math.floor(offsetIndex / availableColors);

  let tinyColor = tinycolor(colors[colorIndex]);
  tinyColor = tinyColor.setAlpha(alpha);
  if (level === 0) {
    return tinyColor.toString();
  } else if (level % 2 === 0) {
    return tinyColor.brighten((20 * level) / 2).toString();
  } else if (level % 2 === 1) {
    return tinyColor.darken((20 * (level + 1)) / 2).toString();
  }
};

export const getNColors = (n = null) => {
  if (n === null || n >= colors.length) {
    return colors;
  } else {
    return colors.slice(0, n);
  }
};

export const getColorGradient = (colors, steps) => {
  const gradient = tinygradient(colors);
  return gradient.rgb(steps);
};
