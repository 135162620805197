import { createRouter, createWebHistory } from 'vue-router';
import { handleAuthentication, handleRoles } from './authentication';
import { DEFAULT_TITLE, handleMetadata } from './metadata';

import Dashboard from '../views/Dashboard';
import Login from '../views/Login';
import Project from '../views/Project';
import NewProject from '../views/NewProject';
import Simulation from '../views/Simulation';
import NewSimulation from '../views/NewSimulation';
import Results from '../views/Results';
import Result from '../views/Result';
import Notifications from '../views/Notifications';
import AccountDashboard from '../views/Account/AccountDashboard';
import ActivateAccount from '../views/Security/ActivateAccount';
import { handleI18n } from '@/i18n';
import ErrorPage from '@/views/ErrorPage.vue';

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      requiresAuth: false,
      title: 'Connectez-vous à ' + DEFAULT_TITLE
    }
  },
  {
    path: '/activate/:token',
    name: 'activate',
    component: ActivateAccount,
    meta: {
      requiresAuth: false,
      title: 'Activez votre compte ' + DEFAULT_TITLE
    },
    props: true
  },
  {
    path: '/error/:code',
    name: 'error',
    component: ErrorPage,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Erreur | ' + DEFAULT_TITLE,
      translations: 'dashboard',
      breadcrumbLabel: 'Erreur'
    },
    props: true
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Accueil | ' + DEFAULT_TITLE,
      translations: 'dashboard'
    }
  },

  {
    path: '/notifications',
    name: 'notifications',
    component: Notifications,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Notifications | ' + DEFAULT_TITLE,
      breadcrumbLabel: 'Mes notifications'
    }
  },
  {
    path: '/account',
    name: 'account',
    component: AccountDashboard,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Mon compte | ' + DEFAULT_TITLE,
      breadcrumbLabel: 'Mon compte'
    }
  },
  {
    path: '/account/users',
    name: 'users',
    component: AccountDashboard,
    meta: {
      requiresAuth: true,
      role: 'ROLE_REFERENT',
      title: 'Mon compte - Utilisateurs | ' + DEFAULT_TITLE,
      breadcrumbLabel: 'Utilisateurs'
    }
  },
  {
    path: '/project/new/:projectIdentifier?',
    name: 'newProject',
    component: NewProject,
    meta: {
      requiresAuth: true,
      role: 'ROLE_REFERENT',
      title: 'Nouveau Projet | ' + DEFAULT_TITLE
    },
    props: true
  },
  {
    path: '/project/:projectIdentifier',
    name: 'project',
    component: Project,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Projet | ' + DEFAULT_TITLE
    },
    props: true
  },
  {
    path: '/project/:projectIdentifier/simulation/new',
    name: 'newSimulation',
    component: NewSimulation,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Nouvelle simulation | ' + DEFAULT_TITLE
    },
    props: true
  },
  {
    path: '/project/:projectIdentifier/simulation/:simulationIdentifier',
    name: 'simulation',
    component: Simulation,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Simulation | ' + DEFAULT_TITLE
    },
    props: true
  },
  {
    path: '/project/:projectIdentifier/simulation/:simulationIdentifier/results/:sectionId',
    name: 'results',
    component: Results,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Results | ' + DEFAULT_TITLE
    },
    props: true
  },
  {
    path: '/project/:projectIdentifier/simulation/:simulationIdentifier/results/:sectionId/chart/:categoryId/:criteriaId',
    name: 'result',
    component: Result,
    meta: {
      requiresAuth: true,
      role: 'ROLE_USER',
      title: 'Result | ' + DEFAULT_TITLE
    },
    props: true
  }
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

export const routeIsUnder = (targetRouteName, checkRoute) => {
  const targetIndex = routes.findIndex((route) => {
    return route.name === targetRouteName;
  });

  const checkRouteIndex = routes.findIndex((route) => {
    return route.name === checkRoute.name;
  });

  return targetIndex <= checkRouteIndex;
};

//Handle Authentication
router.beforeEach(handleAuthentication);

//Handle roles access
//router.beforeEach(handleRoles);

//Handle internationalization (translations)
router.beforeEach(handleI18n);

//Handle Metadata
router.beforeEach(handleMetadata);

export default router;
