<template>
  <div v-if="title" class="title-block uppercase">
    <div class="title">
      <slot name="icon"></slot>
      <div>
        <p v-show="!isEditing" class="h1">
          {{ newTitle ?? title }}
        </p>
        <input
          v-show="isEditing"
          ref="editInput"
          :disabled="savingTitle"
          :value="newTitle ?? title"
          type="text"
          @input="update"
          @keyup.enter="onEnter"
        />
        <slot name="subtitle"></slot>
      </div>
    </div>
    <edit-button
      v-if="isGranted(editRole)"
      ref="submitButton"
      @edit="edit"
      @save="save"
    ></edit-button>
  </div>
</template>

<script setup>
import EditButton from './EditButton';
import { defineProps, nextTick, ref } from 'vue';
import { isGranted } from '@/composables/security';

const newTitle = ref(null);
const isEditing = ref(false);
const savingTitle = ref(false);
const editInput = ref(null);
const submitButton = ref(null);

const props = defineProps({
  title: String,
  editRole: {
    type: String,
    default: 'ROLE_USER'
  }
});
const emit = defineEmits(['save']);

const edit = () => {
  isEditing.value = true;
  newTitle.value = props.title;
  nextTick(() => {
    editInput.value.focus();
  });
};

const update = (event) => {
  newTitle.value = event.target.value;
};

const save = () => {
  savingTitle.value = true;
  emit('save', newTitle.value, () => {
    isEditing.value = false;
    savingTitle.value = false;
  });
};

const onEnter = () => {
  submitButton.value.editAndSave();
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_layout.scss';
@import '@/assets/scss/commons/_buttons.scss';

@include device(medium) {
  .title-block {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.with-border {
      border-bottom: 1px solid $grey-3;
      padding-bottom: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .title {
      width: 80%;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input {
        border: 0;
      }

      i {
        color: $white;
        font-size: 1.8rem;
        line-height: 4rem;
        text-align: center;
        font-style: normal;
        width: 4rem;
        min-width: 4rem;
        height: 4rem;
        margin-right: 1rem;
        display: inline-block;
        border-radius: 50%;
        @include transition(all 0.2s ease-out);
      }

      div {
        width: 100%;

        .h1,
        input {
          color: $blue-1;
          text-transform: uppercase;
          font-size: 1.125rem;
          font-weight: 700;
          letter-spacing: 1px;
          margin-bottom: 0.5rem;
          padding: 0.5rem 0.5rem 0.5rem 0;
        }

        input {
          background-color: $grey-3;
          border: 0;
          border-radius: 0;
          width: 100%;
          min-width: 250px;
        }

        p {
          color: $blue-1;
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

@include device(large) {
  .title-block .title {
    width: 65%;
    max-width: 600px;
  }
}
</style>
