<template>
  <Modal :open="open" @close="emit('close')" medium>
    <template #title>
      <span v-if="type === 'projects'">Projets de l'utilisateur</span>
      <span v-else-if="user !== null">Modifier un utilisateur</span>
      <span v-else>Ajouter un utilisateur</span>
    </template>
    <template #content>
      <user-form
        :users="users"
        :projects="projects"
        :user="user"
        :type="type"
        :key="user !== null ? 'form-' + user.id : 'new-' + users.length"
        @complete="emit('close', true)"
      ></user-form>
    </template>
  </Modal>
</template>
<script setup>
import Modal from '@/components/commons/Modal.vue';
import { mapState } from '@/store/mappers';
import UserForm from '@/components/dashboard/users/UserForm.vue';
const { projects } = mapState('organization');

const emit = defineEmits(['close']);
const props = defineProps({
  open: {
    type: Boolean,
    default: false
  },
  type: {
    type: String,
    default: 'full'
  },
  users: {
    type: Array,
    default: null
  },
  user: {
    type: Object,
    default: null
  }
});
</script>

<style lang="scss" scoped></style>
