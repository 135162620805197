<template>
  <div class="flex">
    <div>
      <h2 class="block-title">Supprimer mon compte</h2>
      <p>
        La suppression de votre compte est une action irréversible et définitive qui entrainera la
        suppression de toutes vos données personnelles.
      </p>
    </div>
    <button class="btn turquoise" @click="openDeleteUserModal">Supprimer mon compte</button>
  </div>
  <ModalDeleteUser
    :user="user"
    :open="deleteUserModalOpened"
    @close="deleteUserModalOpened = false"
  />
</template>

<script setup>
import { ref } from 'vue';
import ModalDeleteUser from '@/components/dashboard/user/ModalDeleteUser.vue';

const props = defineProps({
  user: {
    type: Object,
    required: true
  }
});

const deleteUserModalOpened = ref(false);
const openDeleteUserModal = () => {
  deleteUserModalOpened.value = true;
};
</script>

<style scoped lang="scss">
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > .btn {
    flex: 0 0 auto;
  }
}
</style>
