<template>
  <div>
    <div class="form-container" v-if="type === 'full'">
      <div class="form-row">
        <label for="username">Username<span class="required">*</span></label>
        <app-input
          placeholder="Username"
          model="username"
          @change="updateFormValue"
          @error="updateFormError"
          :initialValue="user !== null ? user.username : null"
          :submitted="submitted"
          :required="true"
        />
      </div>
    </div>
    <div class="form-container" v-if="type === 'full'">
      <div class="form-row">
        <label for="firstName">Prénom<span class="required">*</span></label>
        <app-input
          placeholder="Prénom"
          model="personalInfos.firstName"
          @change="updateFormValue"
          @error="updateFormError"
          :initialValue="user !== null ? user.personalInfos.firstName : null"
          :submitted="submitted"
          :required="true"
        />
      </div>
    </div>
    <div class="form-container" v-if="type === 'full'">
      <div class="form-row">
        <label for="lastName">Nom<span class="required">*</span></label>
        <app-input
          placeholder="Nom"
          model="personalInfos.lastName"
          @change="updateFormValue"
          @error="updateFormError"
          :initialValue="user !== null ? user.personalInfos.lastName : null"
          :submitted="submitted"
          :required="true"
        />
      </div>
    </div>
    <div class="form-container" v-if="type === 'full'">
      <div class="form-row">
        <label for="email">Email<span class="required">*</span></label>
        <app-input
          placeholder="Adresse e-mail"
          model="email"
          @change="updateFormValue"
          @error="updateFormError"
          :initialValue="user !== null ? user.email : null"
          :submitted="submitted"
          :required="true"
        />
      </div>
    </div>

    <div
      class="form-container grey-bg"
      v-if="
        type === 'full' &&
        !(user !== null && isGranted('ROLE_REFERENT', user) && user.id === loggedUser.id)
      "
    >
      <label class="admin-choice">
        <input type="checkbox" v-model="form.isReferent" />
        <i class="icon icon-user-admin"></i> Administrateur de mon organisation
      </label>
    </div>

    <div v-if="formErrors !== ''" class="error callout">
      <p>{{ formErrors }}</p>
    </div>

    <div class="button-group" v-if="type === 'full'">
      <s-button
        color="turquoise"
        @click="submit"
        v-if="submissionInProgress === false && submissionDone === false"
      >
        <span v-if="type === 'projects'">Associer les projets</span>
        <span v-else-if="user === null">Ajouter un utilisateur</span>
        <span v-else>Modifier l'utilisateur</span>
      </s-button>

      <s-button
        color="turquoise"
        @click="submit"
        disabled
        icon="icon-spinner spin"
        v-else-if="submissionInProgress === true && submissionDone === false"
      >
        Enregistrement en cours
      </s-button>

      <s-button color="turquoise" icon="icon-check" @click="submit" disabled v-else>
        Enregistré !
      </s-button>
    </div>

    <div class="form-project-choice">
      <h3 v-if="type === 'full'">Lier au(x) projet(s)</h3>
      <div class="project-list">
        <div
          class="form-container"
          v-for="userProject in userProjectsForm"
          :key="userProject.project.identifier"
        >
          <div class="form-col">
            <label>
              <input type="checkbox" v-model="userProject.selected" />
              <i
                class="rounded-circle"
                :style="{
                  'background-color': getColor(
                    getProjectIndexByIdentifier(userProject.project.identifier)
                  )
                }"
                >{{ getShortName(userProject.project.name) }}</i
              >
              <span>{{ userProject.project.name }}</span>
            </label>
          </div>
          <div class="form-col">
            <select v-if="form.isReferent === true" disabled>
              <option value="administrator">Administrateur</option>
            </select>
            <select v-model="userProject.role" v-else>
              <option value="collaborator">Collaborateur</option>
              <option value="manager">Modélisateur</option>
            </select>
          </div>
        </div>
      </div>
    </div>

    <div v-if="formErrors !== ''" class="error callout">
      <p>{{ formErrors }}</p>
    </div>

    <div class="button-group">
      <s-button
        color="turquoise"
        @click="submit"
        v-if="submissionInProgress === false && submissionDone === false"
      >
        <span v-if="type === 'projects'">Associer les projets</span>
        <span v-else-if="user === null">Ajouter un utilisateur</span>
        <span v-else>Modifier l'utilisateur</span>
      </s-button>

      <s-button
        color="turquoise"
        @click="submit"
        disabled
        icon="icon-spinner spin"
        v-else-if="submissionInProgress === true && submissionDone === false"
      >
        Enregistrement en cours
      </s-button>

      <s-button color="turquoise" icon="icon-check" @click="submit" disabled v-else>
        Enregistré !
      </s-button>
    </div>
  </div>
</template>
<script setup>
import { mapGetters, mapMutations, mapState } from '@/store/mappers';
import AppInput from '@/components/commons/AppInput.vue';
import { computed, onMounted, reactive, ref } from 'vue';
import { cloneDeep, forEach } from 'lodash';
import {
  checkFormErrors,
  extractErrorMessage,
  submitForm,
  updateFormFieldError,
  updateFormFieldValue
} from '@/helper/formHelper';
import { createOrganizationUser, updateOrganizationUser } from '@/composables/organization';
import SButton from '@/components/commons/SButton.vue';
import { getColor } from '@/helper/colorHelper';
import { getShortName } from '@/composables/project';
import { useToast } from 'vue-toastification';
import { orderBy } from 'lodash/collection';
import { isGranted } from '@/composables/security';
import { refreshNav } from '@/composables/navigation';

const { getProjectIndexByIdentifier } = mapGetters('project');
const { user: loggedUser } = mapState('security');
const { addUser, updateUser } = mapMutations('organization');

const emit = defineEmits(['complete']);
const props = defineProps({
  projects: {
    type: Array,
    default: null
  },
  type: {
    type: String,
    default: 'full'
  },
  user: {
    type: Object,
    default: null
  },
  users: {
    type: Array,
    default: null
  }
});
const toast = useToast();
const submitted = ref(false);
const submissionInProgress = ref(false);
const submissionDone = ref(false);
const formErrors = ref('');
const schema = {
  email: null,
  username: null,
  isReferent: null,
  personalInfos: {
    firstName: null,
    lastName: null
  }
};
const form = reactive(schema);
const errors = reactive(cloneDeep(schema));

onMounted(() => {
  if (props.user !== null) {
    if (hasGroup(props.user, 'referent-organisation')) {
      form.isReferent = true;
    }
  }
});

const userProjectsForm = computed(() => {
  let items = [];
  if (props.projects) {
    forEach(props.projects, (project) => {
      let selected = false;
      let role = 'collaborator';
      if (props.user !== null) {
        forEach(props.user.projects, (userProject) => {
          if (userProject.project.id === project.id) {
            selected = true;
            role = userProject.role;
          }
        });
      }
      items.push({
        selected: selected,
        project: project,
        role: role
      });
    });
    return orderBy(items, ['project.name'], ['asc']);
  }
  return null;
});

const submit = () => {
  submitted.value = true;
  let errorMessage = checkFormErrors(errors);
  if ('' !== errorMessage) {
    submissionInProgress.value = false;
    formErrors.value = errorMessage;
  } else {
    submitForm(errors, formErrors, () => {
      submissionInProgress.value = true;
      let data = props.type === 'full' ? form : {};
      data.userProjects = userProjectsForm.value;
      if (props.user !== null) {
        editUser(data);
      } else {
        createUser(data);
      }
    });
  }
};

const editUser = (data) => {
  updateOrganizationUser(
    loggedUser.value.organization.id,
    props.user.id,
    data,
    (response) => {
      updateUser(response.data);
      refreshNav();
      toast.success('Utilisateur mis à jour', {
        timeout: 3000,
        position: 'bottom-center'
      });
      completeSubmission();
    },
    (error) => {
      submissionInProgress.value = false;
      formErrors.value = extractErrorMessage(error.response);
    }
  );
};

const createUser = (data) => {
  createOrganizationUser(
    loggedUser.value.organization.id,
    data,
    (response) => {
      addUser(response.data);
      toast.success('Utilisateur ajouté', {
        timeout: 3000,
        position: 'bottom-center'
      });
      completeSubmission();
    },
    (error) => {
      submissionInProgress.value = false;
      formErrors.value = extractErrorMessage(error.response);
    }
  );
};

const completeSubmission = () => {
  submissionInProgress.value = false;
  submissionDone.value = true;
  submitted.value = false;
  setTimeout(() => {
    submissionDone.value = false;
  }, '2000');
  emit('complete', true);
};

const hasGroup = (user, groupSlug) => {
  let found = false;
  forEach(user.groups, (group) => {
    if (group.slug === groupSlug) {
      found = true;
    }
  });
  return found;
};

const updateFormValue = (property, value) => {
  updateFormFieldValue(form, property, value);
};
const updateFormError = (property, message) => {
  updateFormFieldError(errors, property, message);
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_form.scss';

.form-container {
  .form-row {
    label {
      width: 100px;
    }
    input {
      flex-grow: 1;
    }
  }
  .admin-choice {
    font-weight: bold;
    color: $text;
    margin: 0;
    cursor: pointer;
    input {
      margin-right: 0.5rem;
    }
  }
}

.form-project-choice {
  margin-top: 3rem;
  .form-container {
    display: flex;
    align-items: center;
    gap: 1rem;

    .form-col {
      flex-basis: 100%;
      label {
        display: flex;
        align-items: center;
        gap: 1rem;
        margin: 0;
      }
      select {
        margin: 0;
      }
      .rounded-circle {
        width: 24px;
        height: 24px;
        font-size: 12px;
        font-weight: normal;
        font-style: normal;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $white;
        border-radius: 100%;
        background-color: $turquoise;
      }
    }
  }
}
.button-group {
  display: flex;
  justify-content: flex-end;
  margin-top: 3rem;
}
.error.callout {
  margin-top: 3rem;
}
</style>
