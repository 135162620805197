<template>
  <div v-for="(filter, index) in filters" :key="filter" class="row">
    <result-filter
      :available="available"
      :filter="filter"
      :forced-operators="forcedOperators"
      :index="index"
      :others="filters"
      :used-values="usedValues(filter.identifier)"
      @remove="remove"
      @update="update"
    ></result-filter>
  </div>
  <s-button color="green" icon="icon-plus" icon-position="left" @click="add"
    >Ajouter un filtre</s-button
  >
</template>

<script setup>
import { computed, defineEmits, defineProps, ref } from 'vue';
import ResultFilter from './ResultFilter';
import { mapGetters, mapMutations } from '../../store/mappers';
import { getResultRepresentationHash } from '../../composables/result';
import SButton from '@/components/commons/SButton.vue';

const { getFiltersForResult, getActiveFiltersForResult } = mapGetters('filters', 'function');
const { addNewFilter, removeFilter, updateFilter, updateAllFiltersForIdentifier } =
  mapMutations('filters');

const props = defineProps({
  available: {
    type: Object,
    default: null
  },
  representation: {
    type: Object,
    default: null
  }
});

const forcedOperators = ref({});

const emit = defineEmits(['updateAccordion']);

const filters = computed(() => {
  return getFiltersForResult(representationHash.value);
});
const usedValues = (identifier) => {
  return getActiveFiltersForResult(representationHash.value)
    .filter((filter) => filter.identifier === identifier)
    .map((filter) => filter.value);
};

const representationHash = computed(() => {
  return getResultRepresentationHash(props.representation);
});

const add = () => {
  addNewFilter(representationHash.value);
  emit('updateAccordion');
};

const remove = (index) => {
  removeFilter({ representation: representationHash.value, index });
  emit('updateAccordion');
};

const update = ({ value, index, isFirstIdentifier }) => {
  if (isFirstIdentifier) {
    if (
      forcedOperators.value[value.identifier] !== undefined &&
      forcedOperators.value[value.identifier] !== value.exclude
    ) {
      updateAllFiltersForIdentifier({
        representation: representationHash.value,
        identifier: value.identifier,
        property: 'exclude',
        value: value.exclude
      });
    }
    forcedOperators.value[value.identifier] = value.exclude;
  } else if (!isFirstIdentifier && forcedOperators.value[value.identifier] !== undefined) {
    value.exclude = forcedOperators.value[value.identifier];
  }
  updateFilter({ representation: representationHash.value, index, value });
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';

.btn.purple-2 {
  margin: 1rem 0 1rem;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
}
</style>
