import merge from 'lodash/merge';
import { getObjectIndexByObjectProperty } from '@/helper/objectHelper';
import projectApi from '@/api/project';

const state = {
  isProjectLoading: false,
  project: null,
  projects: null
};

const getters = {
  getProjectIndexByIdentifier: (state) => (identifier) => {
    return getObjectIndexByObjectProperty(state.projects, identifier, 'identifier');
  },
  getProjects: (state) => {
    return state.projects;
  }
};

const actions = {
  fetchProject({ state, commit }, payload) {
    if (state.project?.identifier !== payload.identifier) {
      commit('setIsProjectLoading', true);
      return projectApi.getProject(payload.identifier).then((response) => {
        commit('setCurrentProject', response.data);
        commit('setIsProjectLoading', false);
      });
    } else {
      return Promise.resolve();
    }
  }
};

const mutations = {
  setIsProjectLoading(state, status) {
    state.isProjectLoading = status;
  },
  setCurrentProject(state, payload) {
    state.project = payload;
  },
  updateCurrentProject(state, payload) {
    let updatedProject = merge(state.project, payload);
    let projectIndex = getObjectIndexByObjectProperty(
      state.projects,
      updatedProject.identifier,
      'identifier'
    );

    state.project = updatedProject;
    state.projects[projectIndex] = updatedProject;
  },
  updateProjectSimulation(state, { projectIdentifier, simulation }) {
    let projectIndex = getObjectIndexByObjectProperty(
      state.projects,
      projectIdentifier,
      'identifier'
    );
    let updatedProject = state.projects[projectIndex];
    let simulationIndex = getObjectIndexByObjectProperty(
      updatedProject.simulations,
      simulation.identifier,
      'identifier'
    );

    updatedProject.simulations[simulationIndex] = simulation;
    state.projects[projectIndex] = updatedProject;
  },
  setProjects(state, payload) {
    state.projects = payload;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
