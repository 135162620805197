<template>
  <div class="new-project-step3 selection-row">
    <div class="grey-block selection defined" :class="{ active: isSelected('defined') }">
      <i class="calendar"></i>
      <div class="text-content" @click="selectStrategy('defined')">
        <span></span>
        <p class="h4 text-blue">Choisir l'année du fichier SIRENE de référence</p>
      </div>
      <div class="block-content form-container-grey-bg">
        <select @change="selectSiren">
          <option
            v-for="siren in sirens"
            :key="siren"
            :value="siren"
            :selected="selectedSiren === siren"
          >
            {{ siren }}
          </option>
        </select>
      </div>
    </div>
    <div class="grey-block selection file" :class="{ active: isSelected('file') }">
      <i class="export"></i>
      <div class="text-content" @click="selectStrategy('file')">
        <span></span>
        <p class="h4 text-blue">Importer votre propre fichier SIRENE</p>
      </div>
      <div class="block-content form-container-grey-bg">
        <input
          type="number"
          placeholder="Renseigner l'année du fichier SIRENE importé"
          v-model.number="customFileYear"
          :class="{ error: !isCustomFileYearValid }"
        />
        <!--        <select>-->
        <!--          <option>Renseigner l'année du fichier SIRENE importé</option>-->
        <!--        </select>-->
        <uploadFile
          :disabled="!isSelected('file')"
          :label="'Importer votre fichier Sirene'"
          :status="uploadStatus"
          :accept="'csv,db,sqlite'"
          :max-size="50"
          :server-error="uploadError"
          :existing-file-name="props.projectData?.siren?.customFile"
          :existing-file-original-name="props.projectData?.siren?.customFileOriginalName"
          :existing-file-size="props.projectData?.siren?.customFileSize"
          @upload-file-selected="onFileSelection"
          @upload-file-changed="submitUploadedFile"
          @remove-uploaded-file="removeUploadedFile"
        />
      </div>
    </div>
  </div>
  <create-project-actions
    @next="submit"
    @previous="previous"
    has-previous
    has-next
    :is-valid="isValid"
    :submitting="submitting"
    :error-message="errorMessage"
  >
  </create-project-actions>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { errorMessage, submitting } from '@/composables/createProject';
import CreateProjectActions from './CreateProjectActions.vue';
import {
  getSirens,
  putProject,
  removeUploadedProjectSirenFile,
  uploadProjectSirenFile,
  verifyProjectSirenFile
} from '@/api/project';
import UploadFile from '@/components/commons/UploadFile.vue';
import { mapMutations } from '@/store/mappers';

//Props
const props = defineProps({
  projectData: {
    type: Object,
    default: null
  }
});

// General strategy selection
// -----------------------------------------------------------------------------------------------------------------
const selectedStrategy = ref('defined');
const selectStrategy = (optionName) => {
  selectedStrategy.value = optionName;
  if (optionName === 'defined' && selectedSiren.value === null && sirens.value !== null) {
    selectedSiren.value = sirens.value[0];
  }
};
const isSelected = (strategyName) => {
  return strategyName === selectedStrategy.value;
};

// Defined Siren strategy
// -----------------------------------------------------------------------------------------------------------------
const sirens = ref(null);
const selectedSiren = ref(null);
const selectSiren = (event) => {
  selectedSiren.value = event.target.value;
};
const loadSirens = () => {
  getSirens().then((response) => {
    sirens.value = response.data;
    selectedSiren.value = sirens.value[0];
  });
};
onMounted(loadSirens);
onMounted(() => {
  if (props.projectData?.siren?.customFile) {
    selectStrategy('file');
    customFileYear.value = props.projectData?.siren?.year;
  } else if (props.projectData?.siren?.year) {
    selectStrategy('defined');
    selectedSiren.value = props.projectData?.siren?.year;
  }
});

// Siren file upload strategy
// -----------------------------------------------------------------------------------------------------------------
const customFileYear = ref(null);
const uploadStatus = ref('idle');
const uploadError = ref(null);
const { updateCurrentProject } = mapMutations('project');
onMounted(() => {
  if (
    props.projectData?.siren?.customFile !== null &&
    props.projectData?.siren?.customFile !== undefined
  ) {
    uploadStatus.value = 'complete';
  }
});
const onFileSelection = () => {
  uploadError.value = null;
};
const isCustomFileYearValid = computed(() => {
  return (
    customFileYear.value === '' ||
    customFileYear.value === null ||
    /^\d{4}$/.test(customFileYear.value)
  );
});
const submitUploadedFile = (formData) => {
  uploadStatus.value = 'progress';
  formData.append('year', customFileYear);
  uploadProjectSirenFile(props.projectData.id, formData)
    .then((response) => {
      uploadStatus.value = 'verifying';
      verifyProjectSirenFile(props.projectData.id).then((responseVerify) => {
        console.log(responseVerify.data);
        if (responseVerify.data === 'ok') {
          uploadStatus.value = 'complete';
          updateCurrentProject(response.data);
        } else {
          uploadError.value = responseVerify.data;
          uploadStatus.value = 'idle';
        }
      });
    })
    .catch((error) => {
      if (error !== undefined) {
        uploadError.value = error.response.data.message;
      } else {
        uploadError.value = 'Une erreur est survenue durant le téléchargement.';
      }
      uploadStatus.value = 'idle';
    });
};

const removeUploadedFile = () => {
  uploadStatus.value = 'removing';
  removeUploadedProjectSirenFile(props.projectData.id, props.projectData?.siren?.customFile).then(
    (response) => {
      uploadStatus.value = 'idle';
      updateCurrentProject(response.data);
    }
  );
};

// Submission
// -----------------------------------------------------------------------------------------------------------------
const isValid = computed(() => {
  switch (selectedStrategy.value) {
    case 'defined':
      return selectedSiren.value !== null;
    case 'file':
      return (
        props.projectData?.siren?.customFile !== null &&
        props.projectData?.siren?.customFile !== undefined &&
        customFileYear.value !== null &&
        customFileYear.value !== '' &&
        isCustomFileYearValid.value
      );
    default:
      return false;
  }
});
const emit = defineEmits(['next', 'previous', 'substep', 'go-to-step']);
const submit = () => {
  submitting.value = true;

  switch (selectedStrategy.value) {
    case 'defined':
      putProject(props.projectData.id, {
        siren: { year: parseInt(selectedSiren.value) }
      }).then((response) => {
        emit('next', response.data);
        submitting.value = false;
      });
      break;
    case 'file':
      putProject(props.projectData.id, {
        siren: {
          customFile: props.projectData?.siren?.customFile,
          year: parseInt(customFileYear.value)
        }
      }).then((response) => {
        emit('next', response.data);
        submitting.value = false;
      });
      break;
    default:
      console.error('Selected strategy "' + selectedStrategy.value + '" not implemented yet');
      break;
  }
  // submitting.value = true;
  // putProject(props.projectData.id, {
  //   siren: { year: parseInt(selectedSiren.value) },
  // }).then((response) => {
  //   emit("next", response.data);
  //   submitting.value = false;
  // });
};
const previous = () => {
  emit('previous');
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_form.scss';
@import '@/assets/scss/pages/_selection.scss';

.new-project-step3 {
  .block-content.form-container-grey-bg {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

@include device(large) {
  .new-project-step3.selection-row {
    .selection {
      width: 50%;
    }
  }
}
</style>
