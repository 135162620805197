import forEach from 'lodash/forEach';
import isObject from 'lodash/isObject';
import isArray from 'lodash/isArray';

export const transformObjectWithKeyAsProperty = (
  object,
  property,
  rootPropertyValue = null,
  recursive = false,
  asArray = false,
  excludeNodes = true
) => {
  let result = asArray ? [] : {};

  //Set property on root node
  if (rootPropertyValue) {
    if (asArray) {
      result.push(rootPropertyValue);
    } else {
      result[property] = rootPropertyValue;
    }
  }

  forEach(object, (value, key) => {
    if (isObject(value)) {
      //Only set property if not a node (no leaves) unless excludeNodes is false
      if (!excludeNodes || hasLeaves(value)) {
        value[property] = key;
      }

      if (recursive) {
        value = transformObjectWithKeyAsProperty(
          value,
          property,
          null,
          recursive,
          asArray,
          excludeNodes
        );
      }
    }
    if (asArray) {
      result.push(value);
    } else {
      result[key] = value;
    }
  });

  return result;
};

export const hasLeaves = (object) => {
  let hasAtLeastOneLeaf = false;
  forEach(object, (node) => {
    if (!isObject(node) && !isArray(node)) {
      hasAtLeastOneLeaf = true;
    }
  });
  return hasAtLeastOneLeaf;
};

export const getObjectIndexByObjectProperty = (objects, value, property) => {
  return objects?.findIndex((object) => object[property] === value);
};
