import axios from 'axios';
import store from '../store/index';
import { clone } from 'lodash';

export const apiURL = process.env.VUE_APP_API_URL;
export const mercureURL = process.env.VUE_APP_MERCURE_URL;
const getJwtTokenInLocalStorage = () => {
  let persistedStore = JSON.parse(localStorage.getItem('vuex'));
  return persistedStore.security.jwtToken ?? null;
};

const authenticateAxiosRequestConfig = (config) => {
  let token = getJwtTokenInLocalStorage();

  if (token) {
    config = Object.assign(config, {
      headers: {
        Authorization: 'Bearer ' + token
      }
    });
  }

  return config;
};

export const authenticatedApi = () => {
  //Base API using axios
  let api = axios.create({
    baseURL: apiURL
  });

  //Authentication on all requests
  api.interceptors.request.use(authenticateAxiosRequestConfig, function (error) {
    return Promise.reject(error);
  });

  // Refresh JWT if response http code is 401
  api.interceptors.response.use(
    (response) => {
      // Return a successful response back to the calling service
      return response;
    },
    (error) => {
      let errorUrl = error.config.url ?? '/';

      // Return any error which is not due to authentication (or on login page) back to the calling service
      if (error.response.status !== 401 || window.location.href.indexOf('/login') !== -1) {
        return new Promise((resolve, reject) => {
          reject(error);
        });
      }

      // Logout user if token refresh didn't work or user is disabled
      if (errorUrl === '/authentication_token/refresh') {
        return new Promise((resolve, reject) => {
          store.dispatch('security/resetJwtToken').then(() => {
            window.location.reload();
            reject(error);
          });
        });
      }

      // Refresh JWT then recall initial request
      return store.dispatch('security/refreshLogin').then(() => {
        return new Promise((resolve, reject) => {
          axios
            .request(authenticateAxiosRequestConfig(error.config))
            .then((response) => {
              //Call initial request again
              resolve(response);
            })
            .catch((error) => {
              reject(error);
            });
        });
      });
    }
  );

  return api;
};

export const unauthenticatedApi = () => {
  return axios.create({
    baseURL: apiURL
  });
};

export const buildRoute = (schema, originalParams) => {
  let params = clone(originalParams); //To not mutate them
  let args = schema.match(/:\w*/gm);
  let url = schema;
  args.forEach((arg) => {
    let param = arg.replace(':', '');
    if (params[param] !== undefined) {
      url = url.replace(arg, params[param]);
      delete params[param]; //Remove params that were used inside path from query params
    }
  });

  return { url, params };
};

export default {
  authenticatedApi
};
