<template>
  <div class="top-navigation-block">
    <div class="top-title">
      <h1 v-if="isSimulation">{{ simulation.name }}</h1>
      <h1 v-else-if="isProject">{{ project.name }}</h1>
      <!-- display subtitle only on simulation page -->
      <p v-if="isSimulation" class="subtitle">{{ project.name }}</p>
    </div>
    <!-- display topnav only on simulation page -->
    <div v-if="isSimulationReady" class="top-nav">
      <ul>
        <navigation-item />
      </ul>
      <a class="btn green-1 deactivated" href="" v-if="isGranted('ROLE_ADMIN')"
        >Voir toutes les cartes</a
      >
    </div>
    <navigation-sublevel />
  </div>
</template>

<script setup>
import NavigationItem from './NavigationItem';
import NavigationSublevel from './NavigationSublevel';
import { mapState } from '@/store/mappers';
import { routeIsUnder } from '@/router';
import { useRoute } from 'vue-router';
import { computed } from 'vue';
import { isGranted } from '@/composables/security';

const route = useRoute();

const isSimulation = computed(() => {
  return simulation.value && project.value && routeIsUnder('simulation', route);
});

const isSimulationReady = computed(() => {
  return isSimulation.value && simulation.value.status === 'complete';
});

const isProject = computed(() => {
  return project.value && routeIsUnder('project', route);
});

const { project } = mapState('project');
const { simulation } = mapState('simulation');
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';

.top-navigation-block {
  width: calc(100% - 3.125rem);
  //position: relative;
  position: fixed;
  top: 0;
  z-index: 4;
  @include transition(width 0.6s ease-out);
}

.top-title {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  height: 4.76rem;
  background-color: $blue-2;
  padding: 0 1.5rem;

  h1,
  .subtitle {
    margin: 0;
  }

  h1 {
    color: $white;
    font-size: 1.125rem;
    font-weight: 700;
    line-height: 1.5rem;
    text-transform: uppercase;
  }

  .subtitle {
    color: $white;
    font-size: 0.875rem;
    line-height: 1.188rem;
    font-weight: 400;
  }
}

.top-nav {
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $blue-4;

  ul {
    display: flex;
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
}

.btn.green-1 {
  min-width: 163px;
  margin-left: 0.5rem;
}
</style>
