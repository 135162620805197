<template>
  <div v-if="simulation">
    <div class="split">
      <div class="block">
        <div class="recap">
          <title-with-edit-button
            title="Récapitulatif de la simulation"
            @edit="editDescription"
            @save="saveDescription"
          />
          <p v-show="!isEditingDescription">
            {{ simulation.description }}
          </p>
          <textarea
            v-show="isEditingDescription"
            ref="editDescriptionInput"
            :disabled="isSavingDescription"
            :value="newDescription ?? simulation.description"
            class="editable"
            @input="updateDescription"
          />
          <div class="info-list">
            <ul class="carret-list">
              <li class="info-item">
                <p>Dernière modification</p>
                <div class="flex">
                  <p>{{ formatDate(simulation.updatedAt) }}</p>
                </div>
              </li>
              <li class="info-item">
                <p>Créé le</p>
                <div class="flex">
                  <p>{{ formatDate(simulation.createdAt) }}</p>
                </div>
              </li>
              <li class="info-item">
                <p>Par</p>
                <div class="flex">
                  <p>
                    {{ simulation.author?.personalInfos?.firstName }}
                    {{ simulation.author?.personalInfos?.lastName }}
                  </p>
                </div>
              </li>
              <li class="info-item" v-if="isGranted('ROLE_ADMIN')">
                <p>Distribution des flux</p>
                <div class="flex">
                  <p>Oui/Non</p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div class="block">
        <h2 class="block-title">Données d'entrée</h2>
        <div class="info-list">
          <ul class="carret-list">
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Etablissements"
              subtitle="Fichier SIRENE + rapport d'erreurs"
              filename="outputSiren.zip"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Population"
              subtitle="Fichier des ménages"
              filename="inputMenages.csv"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Périmètre"
              subtitle="Fichier de zonage"
              filename="outputZonage.zip"
              altFilename="zones.geojson"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Distancier"
              subtitle="Fichier de distancier"
              filename="inputDist.csv"
            ></DownloadButtonWithLabel>
          </ul>
        </div>
      </div>
    </div>
    <div class="line-title">
      <p class="title">
        <span>Simulation réalisée</span>
      </p>
    </div>

    <div class="block">
      <h2 class="block-title">Indicateurs synthétiques</h2>
      <div class="recap-numbers">
        <recapNumber
          v-for="indicator in simulation.indicators"
          v-bind:key="indicator"
          :indicator="indicator"
        />
      </div>
    </div>

    <div class="split-two-third">
      <div class="block">
        <h2 class="block-title">Téléchargements associés à la simulation</h2>
        <div class="info-list">
          <ul class="carret-list">
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Opérations générées par les établissements"
              subtitle=""
              filename="outputOpe.zip"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Livraisons générées par les ménages"
              subtitle=""
              filename="outputMenope.csv"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Aires de livraisons (carte AL + table associée)"
              subtitle=""
              filename="outputAl.zip"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Distribution des flux (matrices de sorties)"
              subtitle=""
              filename="outputDistri.zip"
            ></DownloadButtonWithLabel>
            <DownloadButtonWithLabel
              :simulation="simulation"
              title="Émissions de polluants liés aux flux B2B"
              subtitle=""
              filename="outputPolluants.csv"
            ></DownloadButtonWithLabel>
          </ul>
        </div>
      </div>
      <div class="block">
        <comments v-if="isGranted('ROLE_ADMIN')" />
      </div>
    </div>
  </div>
</template>

<script setup>
import RecapNumber from '../infos/RecapNumber';
import { mapMutations, mapState } from '@/store/mappers';
import TitleWithEditButton from '../commons/TitleWithEditButton';
import { updateSimulation } from '@/composables/simulation';
import { nextTick, ref } from 'vue';
import { isGranted } from '@/composables/security';
import Comments from '@/components/commons/Comments.vue';
import { format } from 'date-fns';
import DownloadButtonWithLabel from '@/components/commons/DownloadButtonWithLabel.vue';

const { project } = mapState('project');
const { simulation } = mapState('simulation');
const { updateCurrentSimulation } = mapMutations('simulation');

const isEditingDescription = ref(false);
const isSavingDescription = ref(false);
const newDescription = ref(null);
const editDescriptionInput = ref(null);

const formatDate = (date, dateFormat = "dd/MM/yyyy'") => {
  return format(new Date(date), dateFormat);
};

const editDescription = () => {
  isEditingDescription.value = true;
  newDescription.value = simulation.value.description;
  nextTick(() => {
    editDescriptionInput.value.focus();
  });
};

const updateDescription = (event) => {
  newDescription.value = event.target.value;
};

const saveDescription = () => {
  isSavingDescription.value = true;
  updateSimulation(
    simulation.value.identifier,
    {
      description: newDescription.value
    },
    (response) => {
      updateCurrentSimulation(response.data);
      isEditingDescription.value = false;
      isSavingDescription.value = false;
    }
  );
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/commons/_colors.scss';
@import '@/assets/scss/commons/_icon-font.scss';
@import '@/assets/scss/commons/_mixins.scss';
@import '@/assets/scss/commons/_buttons.scss';
@import '@/assets/scss/commons/_mediaqueries.scss';
@import '@/assets/scss/commons/_layout.scss';
@import '@/assets/scss/commons/_form.scss';

.info-list {
  ul {
    li {
      font-size: 0.875rem;
      display: flex;
      justify-content: space-between;
      padding: 1rem 0 1rem 1.5rem;
      border-bottom: 1px solid $grey-3;

      p {
        color: $blue-1;
        font-weight: 600;
        margin: 0;
      }

      .flex {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 50%;
      }
    }
  }
}

@include device(small) {
  .recap-icon-list {
    padding: 0.5rem 1rem;
    border: 1px solid $grey-3;
    border-radius: 5rem;
    display: flex;
    width: max-content;
    max-width: 100%;
    box-sizing: border-box;
    flex-wrap: wrap;
    flex-direction: row;

    :deep(i) {
      display: inline-block;
      font-size: 2rem;
      margin: 0.25rem 0.5rem;
    }
  }
  .recap-numbers {
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
  }
}

@include device(xlarge) {
  .recap-icon-list {
    i {
      font-size: 3rem;
    }
  }
}
</style>
