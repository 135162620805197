<template>
  <div v-if="loading">
    <div class="loader">
      <spinner-loader color="#88BEDC"></spinner-loader>
    </div>
  </div>
  <div v-else-if="loadingError">
    <h3>{{ loadingError }}</h3>
  </div>
  <data-chart
    v-else-if="isChart"
    :category="category.id"
    :data="chartData"
    :multiple="multiple"
    :result-index="resultIndex"
    @legend-defined="onMapLegendDefined"
  ></data-chart>
  <data-map
    v-else-if="isMap"
    :category="category.id"
    :map-options="mapData.options"
    :zones="zones"
    :zone-values="indexedZoneValues"
    :result-index="resultIndex"
    :boundaries="simulation.boundaries || undefined"
    :origin-lat="project.originLat || undefined"
    :origin-lng="project.originLng || undefined"
    :origin-zoom="project.originZoom || undefined"
    @legend-defined="onMapLegendDefined"
  ></data-map>
</template>

<script setup>
import { computed, onBeforeUnmount, nextTick, ref, watch, watchEffect } from 'vue';
import resultApi, { downloadChartData } from '../../api/result';
import { getCustomZonesJson } from '../../api/simulation';
import DataChart from './DataChart';
import DataMap from './DataMap';
import { keyBy, mapValues, omit } from 'lodash';
import { mapGetters, mapMutations, mapActions } from '@/store/mappers';
import { getResultRepresentationHash } from '@/composables/result';
import { getSimulationVersion } from '@/composables/simulation';
import SpinnerLoader from 'vue-spinner/src/ScaleLoader.vue';
import { mapState } from 'vuex';
const { getActiveFiltersForResult } = mapGetters('filters', 'function');
const { createFiltersIfNew } = mapMutations('filters');

const props = defineProps({
  project: Object,
  simulation: Object,
  section: Object,
  category: Object,
  criteria: Object,
  zones: Object,
  resultIndex: Number,
  multiple: {
    type: Boolean,
    default: false
  }
});

const emit = defineEmits(['loaded', 'mapLegendDefined', 'customZonesLoaded']);

const resultRepresentation = computed(() => {
  return props
    ? {
        project: props.project.identifier,
        simulation: props.simulation.identifier,
        section: props.section.id,
        category: props.category.id,
        criteria: props.criteria.id
      }
    : null;
});

const resultRepresentationHash = computed(() => {
  return getResultRepresentationHash(resultRepresentation.value);
});

const resultFilters = computed(() => {
  return getActiveFiltersForResult(resultRepresentationHash.value);
});

const resultRequest = computed(() => {
  let request = omit(resultRepresentation.value, ['project']);
  request.version = getSimulationVersion(props.project, props.simulation);

  if (!props.multiple) {
    return addFiltersToRequest(request, resultFilters.value);
  } else {
    return request;
  }
});

const addFiltersToRequest = (request, filters) => {
  filters?.forEach((filter) => {
    if (request[filter.identifier] === undefined) {
      request[filter.identifier] = [];
    }
    request[filter.identifier].push((filter.exclude ? '!' : '') + filter.value);
  });
  return request;
};

const chartData = ref(null);
const isChart = computed(() => {
  return chartData.value !== null && !loading.value && !loadingError.value;
});
const mapData = ref(null);
const isMap = computed(() => {
  return mapData.value !== null && !loading.value && !loadingError.value;
});
const indexedZoneValues = computed(() => {
  if (mapData.value) {
    //console.log(mapData);
    const mapDatasets = mapData.value.datasets;
    const zoneDatas = mapDatasets.find((dataset) => dataset.main === true).data;
    return mapValues(keyBy(zoneDatas, 'zone'), 'y');
  } else {
    return null;
  }
});

const loadCustomLoaded = ref(false);
loadCustomLoaded.value = false;

let zones = computed(() => {
  if (mapData.value) {
    if (mapData.value.grid !== '0' && loadCustomLoaded.value === false) {
      onCustomZonesLoaded(null);
      fetchDataZones(mapData.value.grid);
      return null;
    } else {
      return props.zones;
    }
  } else {
    return props.zones;
  }
});

function fetchDataZones(customFile) {
  try {
    getCustomZonesJson(props.project, props.simulation, 'grid' + customFile + '.geojson').then(
      (data) => {
        const customZonesData = data.data;
        zones.value = 1;
        zones = customZonesData;
        onCustomZonesLoaded(customZonesData);
        //console.log(customZonesData);
        loadCustomLoaded.value = true;
        load();
      }
    );
  } catch (error) {
    console.error('Error fetching custom zones:', error);
    // Handle the error or set customZonesData.value to an appropriate value
  }
}

const loading = ref(false);
const loadingError = ref(null);

//Load Results (see watch(s) for when this is triggered)
const load = () => {
  if (!loading.value) {
    loading.value = true;
    resultApi
      .getChart(resultRequest.value)
      .then((response) => {
        loading.value = false;
        loadingError.value = null;
        if (response.data.chartType !== 'map') {
          chartData.value = response.data;
          emit('loaded', {
            representation: resultRepresentation,
            request: resultRequest.value,
            data: response.data
          });
        } else {
          mapData.value = response.data;
        }
        emit('loaded', {
          representation: resultRepresentation,
          request: resultRequest.value,
          data: response.data
        });
        createFiltersIfNew(resultRepresentationHash.value);
      })
      .catch((error) => {
        loading.value = false;
        loadingError.value = error.message;
      });
  }
};

const onMapLegendDefined = (legend) => {
  emit('mapLegendDefined', legend);
};

const onCustomZonesLoaded = (newZones) => {
  emit('customZonesLoaded', newZones);
};

watch(
  resultRepresentation,
  () => {
    if (chartData.value === null && mapData.value === null) {
      load();
    }
  },
  {
    deep: true,
    immediate: true
  }
);

// watch(
//   customZonesReady,
//   () =>{
//     console.log("ICI",customZonesReady.value)
//     if(customZonesReady.value===true){
//       //load();
//       customZonesReady.value = false;
//     }
//   }
// )

watch(
  resultFilters,
  (newResultFilters, oldResultFilters) => {
    if (
      newResultFilters !== undefined &&
      (newResultFilters.length > 0 ||
        (oldResultFilters !== undefined && oldResultFilters.length > 0))
    ) {
      load();
    }
  },
  {
    deep: true
  }
);

onBeforeUnmount(() => {
  chartData.value = null;
  mapData.value = null;
});
</script>

<style scoped></style>
